/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useState, useEffect, useRef } from "react";
import DialogContentText from '@material-ui/core/DialogContentText';
import {
  DialogContent
} from '../../../../SharedModules/Dialog/DialogUtilities';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import PayeeTab from "./PayeeTab";
import OriginalTab from "./OriginalTab";
import DispositionTab from "./DispositionTab";
import RefundRecoveryTab from "./RefundRecoveryTab";
import OtherBillingReceivableTab from "./OtherBillingRecDisposition";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";
import TabPanel from "../../../../SharedModules/TabPanel/TabPanel";
import ErrorMessages from "../../../../SharedModules/Errors/ErrorMessages";
import SuccessMessages from "../../../../SharedModules/Errors/SuccessMessage";

import * as FinancialReceiptConstants from "../FinancialReceiptConstants";
import {
  memberIDValidate,
  validatePayeeDetails,
  validateOriginalDetails,
  validateOtherTabDetails,
  mapCodeDescription,
  buildMasterSaveObject
} from "../AddValidationUtility";
import {
  getReplacementDataAction,
  getDropdownDataAction,
  getPayeeIdTypeCodeAction,
  getAdjustmentDataAction,
  addFinancialReceiptReverseAction,
  resetFinancialReceiptSearch,
  validatePayeeAction,
  generateFCNForAdd,
  addFinancialReceiptAction,
  updateFinancialReceiptAction,
  financialReceiptSearchAction,
  validateMemberAPI,
  getAuditLogDetailsAction,
  getAuditLogRowDetailsAction
} from "../../Store/Actions/FinancialReceipt/financialReceiptActions";
import {
  getDateInMMDDYYYYFormat,
  generateUUID,
  getUTCTimeStamp,
  getDateInMMDDYYYYFormatWithApendZero
} from "../../../../SharedModules/DateUtilities/DateUtilities";
import HistoryTab from "./HistoryTab";
import dropdownCriteria from "./FinancialReceiptAdd.json";
import numeral from "numeral";
import moment from "moment";
import Notes from "../../../../SharedModules/Notes/Notes";
import {
  getSystemParameterSpecificValue
} from "../../../../SharedModules/store/Actions/SharedAction";
import NoSaveMessage from "../../../../SharedModules/Errors/NoSaveMessage";
import Footer from "../../../../SharedModules/Layout/footer";
import { Prompt } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Attachments from "../../../../SharedModules/Attachments/Attachments";
import NavHOC from "../../../../SharedModules/Navigation/NavHOC";
import SystemPayeeTable from "../../../../SharedModules/SystemIDTable/SystemPayeeIDTable";
import AuditTableComponent from "../../../../SharedModules/AuditLog/AuditTableComponent";
import {
  checkingDecimal,
  getLongDescription,
  removeLeadingTrailingSpaces
} from "../../../../SharedModules/CommonUtilities/commonUtilities";

import { GET_APP_DROPDOWNS, GET_SYSTEM_LIST_DROPDOWNS } from "../../../../SharedModules/Dropdowns/actions";
import * as Dropdowns from "../../../../SharedModules/Dropdowns/dropdowns";
import { DEFAULT_LOB_VALUE } from "../../../../SharedModules/AppConstants";

const useStyles = makeStyles({
  tab100: {
    minWidth: 0
  }
});
let payeeTabPayeeTypeChngFlag = false;
// Edit and add page Flag
let editFlag = false;
export default NavHOC({
  Wrapped: FRProviderCheckAdd,
  action: financialReceiptSearchAction,
  pathTo: "/FinancialReceiptUpdate",
  selector: "financialReceiptState.financialReceiptSearch",
  actionKey: "Receipt"
});
function FRProviderCheckAdd (props) {
  const DEFAULT_DD_VALUE = process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE;
  const classes = useStyles();
  const userInquiry = !global.globalIsReadOnly();
  const [replacementData, setReplacementData] = React.useState([]);
  const [referenceDis, setReferenceDis] = React.useState([]);
  const [referencerefund, setreferencerefund] = React.useState([]);
  const [receiptVersionNo, setReceiptVersionNo] = React.useState(0);
  const [allowNavigation, setAllowNavigation] = React.useState(false);
  const [updateData, setUpdateData] = React.useState({});
  const dispatch = useDispatch();

  const validValueDropdowns = (values) => dispatch(GET_APP_DROPDOWNS(values));
  const addDropdowns = useSelector(state => state.appDropDowns.appdropdowns);
  const validValRsnCodes = addDropdowns && addDropdowns['F1#F_RSN_CD']?.sort((a,b) => a.code - b.code);
  const systemListDropDowns = useSelector(state => state.appDropDowns.FS_REASON_CODES);
  const [rowFinReceiptData, setRowFinReceiptData] = React.useState([]);
  const [isOriginalATR, setOriginalATR] = React.useState(false);

  const toPrintRef = useRef();

  const [voidData, setVoidData] = React.useState(false);
  const [userInquiryPrivileges, setUserInquiryPrivileges] = React.useState(
    true
  );
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [focusCheck, setFocusCheck] = React.useState(false);
  const [initalFocusCheck, setInitalFocusCheck] = React.useState(false);
  const loginUserID = useSelector(state => state.sharedState.logInUserID);
  // Attachments variables
  const [attachmentsTableData, setAttachmentsTableData] = React.useState([]);
  const [attachmentFile, setAttachmentFile] = React.useState([]);
  const [auditButtonToggle, setAuditButtonToggle] = React.useState(false);
  // Audit Log Implementation starts
  const [showLogTable, setShowLogTable] = React.useState(false);
  const onClickAuditLog = () => {
    const auditReq = [
      {
        tableName: "f_hdr_tb",
        keyValue: {
          f_fcn_num: props.history.location
        ? props.history.location.state
          ? props.location.state.payloadViewData
            ? props.location.state.payloadViewData[0].fcn
            : null
          : null
        : null
        }
      },
      {
        tableName: "f_recpt_tb",
        keyValue: {
          f_fcn_num: props.history.location
        ? props.history.location.state
          ? props.location.state.payloadViewData
            ? props.location.state.payloadViewData[0].fcn
            : null
          : null
        : null
        },
      columnNames: "f_dpst_num,f_lock_box_num,f_dpst_dt,f_invc_num"
      }
    ];
    dispatch(getAuditLogDetailsAction(auditReq, "auditLogData"));
  };
  const tempArray = [];
  useEffect(() => {
    if (showLogTable && document.getElementById("audit-table")) {
 document.getElementById("audit-table").scrollIntoView({
        behavior: "smooth"
      });
}
  }, [showLogTable]);
  const auditLogData = useSelector(
    state => state.financialReceiptState.auditDetails
  );
  const [auditRowData, setAuditRowData] = React.useState([]);
  const filterAuditTable = rowData => {
    return rowData.afterValue || rowData.beforeValue;
  };
  useEffect(() => {
    if (auditLogData) {
      setAuditRowData(auditLogData.filter(filterAuditTable));
    }
  }, [auditLogData]);

  const [showLogDialogTable, setShowLogDialogTable] = React.useState(false);
  const auditLogTableData = useSelector(
    state => state.financialReceiptState.auditRowDetails
  );
  const [auditTableData, setAuditTableData] = React.useState([]);
  const [close, setClose] = React.useState(true);
  const [auditLogRow, setAuditLogRow] = React.useState(null);

  const onClickAuditDialogLog = row => {
    const tableName = "f_recpt_tb";
    const keyValue = {
      f_fcn_num: row.receivableFCN
    };
    dispatch(
      getAuditLogRowDetailsAction(tableName, keyValue, "auditLogTableData")
    );
    setShowLogDialogTable(true);
  };
  useEffect(() => {
    if (auditLogTableData) {
      setAuditTableData(auditLogTableData.filter(filterAuditTable));
    }
  }, [auditLogTableData]);

  useEffect(() => {
    if (showLogDialogTable && document.getElementById("audit-dialog-table")) { document.getElementById("audit-dialog-table").scrollIntoView(); }
  }, [showLogDialogTable]);

  const [
    showLogDialogRefundTable,
    setShowLogDialogRefundTable
  ] = React.useState(false);
  const auditLogRefundTableData = useSelector(
    state => state.appConfigState.AppConfigCommonState.auditLogTableData
  );
  const [auditRefundTableData, setAuditRefundTableData] = React.useState([]);

  const onClickAuditDialogRefundLog = row => {
    const tableName = "R_VV_TB";
    const keyValue = {
      R_CNSTNT_TEXT: row.constantText
    };
    dispatch(
      getAuditLogDetailsAction(tableName, keyValue, "auditLogTableData")
    );
    setShowLogDialogRefundTable(true);
  };
  useEffect(() => {
    if (auditLogRefundTableData) {
      setAuditRefundTableData(auditLogRefundTableData.filter(filterAuditTable));
    }
  }, [auditLogRefundTableData]);

  useEffect(() => {
    if (
      showLogDialogRefundTable &&
      document.getElementById("audit-refund-table")
    ) {
      document.getElementById("audit-refund-table").scrollIntoView();
    }
  }, [showLogDialogRefundTable]);

  // Audit Log Implementation ends

  useEffect(() => {
    if (!userInquiry) {
      setUserInquiryPrivileges(false);
    }
    if (voidData) {
      setUserInquiryPrivileges(false);
    }
  }, [userInquiry, voidData]);
  let dataFromPrev;
  let payloadViewData = useSelector(
    state => state.financialReceiptState.financialReceiptSearch
  );

  if (props.location.state) {
    dataFromPrev = JSON.parse(
      JSON.stringify(props.location.state.payloadViewData[0])
    );
  }

  useEffect(() => {
    if (props.location.state) {
      if (props.location.state.payloadViewData) {
        setReplacementData(props.location.state.payloadViewData);
      }
    }
  }, [props.location.state]);
  const getDateFromFcn = (fcn) => {
    const dateFromFcn = fcn.slice(4, 6) + '/' + fcn.slice(6, 8) + '/' + fcn.slice(0, 4);
    return dateFromFcn;
  };
  useEffect(() => {
    if (props.location.state) {
      const updatePayload = JSON.parse(
        JSON.stringify(props.location.state.payloadViewData[0])
      );
      editFlag = props.location.state.editFlag;
      if (updatePayload) {
        setReceiptVersionNo(updatePayload.receiptVersionNo);


 updatePayload.fetchHistoryVOList.map(var1 => {
   if (var1.tcn !== null) {
     tempArray.push(var1);
   }
 });

var updatedHistoryList = tempArray.map(historyList => {
   return {
...historyList,
id: generateUUID(),
enableCheckbox: !historyList?.tcnstatCdO ||
    false,
dispCanBeReversed: false,
tcnstatCdO: historyList?.tcnstatCdO ||
    false,
xrefTCN: historyList?.xrefTCN || null
};
 });

 setHistoryData(updatedHistoryList);
 updatePayload.fetchHistoryVOList = updatedHistoryList;

        setUpdateData(JSON.parse(JSON.stringify(updatePayload)));
        // For edit page setting payeeTab details
        setPayeeTab({
          entityType:
            updatePayload && updatePayload.entityType
              ? updatePayload.entityType.split("-")[0]
              : DEFAULT_DD_VALUE, // Payee Type
          entityIDTypeCode: updatePayload.entityIDTypeCode, // Payee ID Type Code
          entityID:
            updatePayload && updatePayload.entityID
              ? updatePayload.entityID
              : "", // payeeID
          entityName:
            updatePayload && updatePayload.entityName
              ? updatePayload.entityName
              : "", // Payee Name
          sysID:
            updatePayload && updatePayload.payeePayerID
              ? updatePayload.payeePayerID
              : "", // System PayeeID
          memberIDType:
            updatePayload && updatePayload.memberIDType
              ? updatePayload.memberIDType
              : DEFAULT_DD_VALUE, // memeber Id Type
          memberID:
            updatePayload && updatePayload.memberID
              ? updatePayload.memberID
              : "", // member id
          memberName:
            updatePayload && updatePayload.memberName
              ? updatePayload.memberName
              : "", // member name
          provBillSysId:
            updatePayload && updatePayload.provBillSysId
              ? updatePayload.provBillSysId
              : ""
        });
        // For edit page setting originalTab details
        setOriginalTab({
          fcn: updatePayload && updatePayload.fcn ? updatePayload.fcn : "",
          activityTypeCode:
            updatePayload && updatePayload.activityTypeCode
              ? updatePayload.activityTypeCode
              : DEFAULT_DD_VALUE, // activityTypeCode
          financialReason:
            updatePayload && updatePayload.financialReason
              ? updatePayload.financialReason
              : DEFAULT_DD_VALUE, // financialReasonCode
          establishedDate:
            updatePayload && updatePayload.establishedDate
              ? getDateFromFcn(updatePayload.fcn)
              : getDateInMMDDYYYYFormat(
                new Date(
                  new Date().toLocaleString("en-US", {
                    timeZone: process.env.REACT_APP_TIMEZONE
                  })
                )
              ), // establishedDate
          fundCode:
            updatePayload && updatePayload.fundCode
              ? updatePayload.fundCode
              : DEFAULT_DD_VALUE, // collocationCode
          atrNumber: updatePayload && updatePayload.atrNumber || '', // ATR Number
          depositDate:
            updatePayload && updatePayload.depositDate
              ? updatePayload.depositDate
              : null, // depositDate
          depositNumber:
            updatePayload && updatePayload.depositNumber
              ? updatePayload.depositNumber
              : "", // depositNumber
          invoice:
            updatePayload && updatePayload.invoice ? updatePayload.invoice : "", // invoice
          originalAmount:
            updatePayload && updatePayload.originalAmount
              ? numeral(updatePayload.originalAmount).format("0,0.00")
              : "0.00", // originalAmt
          lockBoxNumber:
            updatePayload && updatePayload.lockBoxNumber
              ? updatePayload.lockBoxNumber
              : "", // lockboxNumber
          itemNumber:
            updatePayload && updatePayload.itemNumber
              ? updatePayload.itemNumber
              : "", // itemNumber
          balanceAmount:
            updatePayload && updatePayload.balanceAmount
              ? numeral(updatePayload.balanceAmount).format("0,0.00")
              : "0.00", // balanceAmt
          batchNumber:
            updatePayload && updatePayload.batchNumber
              ? updatePayload.batchNumber
              : "", // batchNumber
          relatedFTTCN:
            updatePayload && updatePayload.relatedFTTCN
              ? updatePayload.relatedFTTCN
              : "", // relatedFTTcn
          lastAppliedDate:
            updatePayload && updatePayload.lastAppliedDate
              ? updatePayload.lastAppliedDate
              : "", // lastappliedDate
          pendingReason:
            updatePayload && updatePayload.pendingReason
              ? updatePayload.pendingReason
              : DEFAULT_DD_VALUE, // pendingReason
          pendDate:
            updatePayload && updatePayload.pendDate
              ? updatePayload.pendDate
              : "", // pendDate
          checkNumber:
            updatePayload && updatePayload.checkNumber
              ? updatePayload.checkNumber
              : "", // checkNumber
          intTrackNum:
            updatePayload && updatePayload.intTrackNum
              ? updatePayload.intTrackNum
              : "", // internalTrackingNumber
          voidIndicator:
            updatePayload && updatePayload.voidIndicator
              ? updatePayload.voidIndicator
              : false, // voidIndicator
          voidDate:
            updatePayload && updatePayload.voidDate
              ? updatePayload.voidDate
              : null // voidDate
        });

        if (
          updatePayload.depositDate &&
          updatePayload.depositDate !== "Inavlid Date"
        ) {
          setSelectedDepositDate(new Date(updatePayload.depositDate));
        } else {
          setSelectedDepositDate(null);
        }

        // For edit page set disposition tab
        setDispositionTabTableData(
          JSON.parse(JSON.stringify(updatePayload.dispositionVOList))
        );

        // For edit page set add refund recovery tab
        updatePayload.refundRecoveryVOList.map(value => {
          value._reasonCodeDescr = mapCodeDescription(
            value.reasonCode,
            refundDispositionRsnCodeDD
          );
        });
        var data = JSON.parse(JSON.stringify(updatePayload.refundRecoveryVOList));
        setAddRefundTabTableData(
          data.map(data => { return { ...data, atrNumber: data?.atrNumber?.toUpperCase() }; })
        );

        setreferencerefund(
          JSON.parse(
            JSON.stringify(
              props.location.state.payloadViewData[0].refundRecoveryVOList
            )
          )
        );

        // For edit page set otherbilling tab
        setOtherBillingTab({
          billingRsnCode:
            updatePayload && updatePayload.billingRsnCode
              ? updatePayload.billingRsnCode
              : DEFAULT_DD_VALUE, // dispositionResonCode
          otherBillingRecvDispfundCode:
            updatePayload && updatePayload.otherBillingRecvDispfundCode
              ? updatePayload.otherBillingRecvDispfundCode
              : DEFAULT_DD_VALUE, // fundCode
          otherBillingCosCode:
            updatePayload && updatePayload.otherBillingCosCode
              ? updatePayload.otherBillingCosCode
              : DEFAULT_DD_VALUE, // cos
          billingAmount:
            updatePayload && updatePayload.billingAmount
              ? updatePayload.billingAmount
              : "", // paymentAmount
          atrNumber: updatePayload?.atrNumber?.toUpperCase() || ''
        });

        if (updatePayload.voidIndicator === true) {
          setVoidData(true);
        }
        if (updatePayload.approvalReqIndicator === true) {
          setVoidData(true);
        }

        const tempDropdown = JSON.parse(
          JSON.stringify(otherDispositionRsnCodeDD)
        );
        setotherDispositionRsnCodeDD(tempDropdown);

        // For Global History tab

        setGlobalHistory({
          reasonCode:
            updatePayload && updatePayload.globalHistoryVO
              ? updatePayload.globalHistoryVO.reasonCode
              : "",
          rcLongDesc: getLongDescription(
            financialReasCodeDD,
            updatePayload && updatePayload.globalHistoryVO
              ? updatePayload.globalHistoryVO.reasonCode
              : null
          ),
          establishedDate:
            updatePayload && updatePayload.globalHistoryVO
              ? updatePayload.globalHistoryVO.establishmentDate
              : "",
          originalAmount:
            updatePayload && updatePayload.globalHistoryVO
              ? updatePayload.globalHistoryVO.originalAmount
              : "",
          appliedAmount:
            updatePayload && updatePayload.globalHistoryVO
              ? updatePayload.globalHistoryVO.appliedAmount
              : "",
          balanceAmount:
            updatePayload && updatePayload.globalHistoryVO
              ? updatePayload.globalHistoryVO.appliedAmount
              : ""
        });

        // For over payments list
        setOverPaymentsData(updatePayload.overPaymentVOList);

        if (updatePayload.noteSetVO) {
          const noteSetVO = updatePayload.noteSetVO;
          const notesTable = updatePayload.noteSetVO.notesList;
          setNotesInput({
            auditUserID: loginUserID,
            auditTimeStamp: getUTCTimeStamp(),
            addedAuditUserID: noteSetVO.addedAuditUserID
              ? noteSetVO.addedAuditUserID
              : loginUserID,
            addedAuditTimeStamp: noteSetVO.addedAuditTimeStamp
              ? noteSetVO.addedAuditTimeStamp
              : getUTCTimeStamp(),
            versionNo: noteSetVO.versionNo,
            dbRecord: noteSetVO.dbRecord,
            sortColumn: noteSetVO.sortColumn,
            tableName: noteSetVO.tableName,
            noteSetSK: noteSetVO.noteSetSK,
            noteSourceName: noteSetVO.noteSourceName,
            notesList: notesTable,
            globalNotesList: [],
            checkAll: noteSetVO.checkAll,
            addNewLinkRender: noteSetVO.addNewLinkRender,
            filterLinkRender: noteSetVO.filterLinkRender,
            printLinkRender: noteSetVO.printLinkRender,
            completeNotesList: []
          });

          setNotesTableData(notesTable);
        }
        if (
          updatePayload &&
          updatePayload.attachmentsList &&
          updatePayload.attachmentsList.length > 0
        ) {
          setAttachmentsTableData(updatePayload.attachmentsList);
        } else {
          setAttachmentsTableData([]);
        }
        setAttachmentFile([]);
        setUpdatePayee(true);
      }
    }
  }, [props.location.state]);

  const [color, setColor] = React.useState({
    orginalTabClr: "",
    dispositionTabClr: "",
    refundTabClr: "",
    otherTabClr: ""
  });
  // '0.00' format functions
  const originalTabFormat = () => {
    if (originalTab.originalAmount) {
      setOriginalTab({
        ...originalTab,
        originalAmount: numeral(originalTab.originalAmount).format("0,0.00"),
        balanceAmount: numeral(originalTab.balanceAmount).format("0,0.00")
      });
    }
  };
  const removeOriginalTabFormat = () => {
    if (originalTab.originalAmount) {
      setOriginalTab({
        ...originalTab,
        originalAmount: numeral(originalTab.originalAmount).format("0.00"),
        balanceAmount: numeral(originalTab.balanceAmount).format("0.00")
      });
    }
  };

  const otherBillingFormat = () => {
    if (otherBillingTab.billingAmount) {
      setOtherBillingTab({
        ...otherBillingTab,
        billingAmount: numeral(otherBillingTab.billingAmount).format("0,0.00")
      });
    }
  };
  const removeOtherBillingFormat = () => {
    if (otherBillingTab.billingAmount) {
      setOtherBillingTab({
        ...otherBillingTab,
        billingAmount: numeral(otherBillingTab.billingAmount).format("0.00")
      });
    }
  };

  const [errorMessages, seterrorMessages] = React.useState([]);
  const [deleteDetailsState, setDeleteDetailsState] = React.useState({});
  const [finReceiptDeleteData, setFinReceiptDeleteData] = React.useState([]);
  const getAdjustData = values => dispatch(getAdjustmentDataAction(values));
  const getReverseAdd = values =>
    dispatch(addFinancialReceiptReverseAction(values));
  const AddReverseResp = useSelector(
    state => state.financialReceiptState.addReceiptReverseResponse
  );
  const adjustData = useSelector(
    state => state.financialReceiptState.adjustmentData
  );
  // Payee Tab Dropdowns
  const [payeeTypeDD, setPayeeTypeDD] = React.useState([]);
  const [payeeIDTypeDD, setPayeeIDTypeDD] = React.useState([]);
  const [memberIDTypeDD, setMemberIDTypeDD] = React.useState([]);
  // Original tab DropDowns
  const [activityTypeCodeDD, setActivityTypeCodeDD] = React.useState([]);
  const [financialReasCodeDD, setFinancialReasCodeDD] = React.useState([]);
  const [financialReasCodeDDAdd, setFinancialReasCodeDDAdd] = React.useState(
    []
  );
  const [stopPaymentRsn, setStopPaymentRsn] = React.useState([]);
  const [disableReverseRsnCodes, setDisableReverseRsnCodes] = React.useState([]);
  const [pendingReasonDD, setPendingReasonDD] = React.useState([]);
  const [collationCodeDD, setCollationCodeDD] = React.useState([]);
  const [collationCode213DD, setCollationCode213DD] = React.useState([]);
  const [dispositionRsnCodeDD, setdispositionRsnCodeDD] = React.useState([]);
  const [
    initialDispositionRsnCodeDD,
    setInitialDispositionRsnCodeDD
  ] = React.useState([]);
  const [
    checkReplacementRsnCodes,
    setCheckReplacementRsnCodes
  ] = React.useState([]);
  // Refud/General Recovery Tab
  const [
    refundDispositionRsnCodeDD,
    setRefundDispositionRsnCodeDD
  ] = React.useState([]);
  const [drugRebateReasonCode, setDrugRebateReasonCode] = React.useState([]);
  const [cosDD, setCosDD] = React.useState([]);
  const [refundPayeeIDTypeDD, setRefundPayeeIDTypeDD] = React.useState([]);
  // Other Billing Tab
  const [
    otherDispositionRsnCodeDD,
    setotherDispositionRsnCodeDD
  ] = React.useState([]);
  // Success Message array
  const [successMessages, setSuccessMessages] = React.useState([]);
  // History Table Data
  const [reasCodeDD, setReasCodeDD] = React.useState([]);
  const [historyData, setHistoryData] = React.useState([]);
  const [updatePayee, setUpdatePayee] = React.useState(false);
  useEffect(() => {
    if (updatePayee) {
      let validatePayeeObj = {
        lob: DEFAULT_LOB_VALUE,
        entityType: payeeTab.entityType,
        entityIDTypeCode: payeeTab.entityIDTypeCode,
        entityID: payeeTab.entityID,
        fromReceiptAddPage: true
      };
	  if (callValidatePayeeDetails([]) && editFlag) {
        setValidatePayeeOnBlur(false);
        validatePayeeAPICall(validatePayeeObj);
      }
      validatePayeeObj = {
        entityType: "",
        entityIDTypeCode: "",
        entityID: "",
        fromReceiptAddPage: true,
        memberID: payeeTab.memberID,
        memberIDType: payeeTab.memberIDType
      };
      if (
        callValidateMemberDetails([]) &&
        validatePayeeObj.memberID !== "" &&
        validatePayeeObj.memberIDType !== DEFAULT_DD_VALUE
      ) {
        validateMemberAPICall(validatePayeeObj);
      }
    }
  }, [updatePayee]);
  // Global History Data
  const [globalHistory, setGlobalHistory] = React.useState({
    reasonCode: "",
    establishedDate: "",
    originalAmount: "",
    appliedAmount: "",
    balanceAmount: ""
  });
  // OverPayments list
  const [overPaymentsData, setOverPaymentsData] = React.useState([]);
  // Payload View data
  // Reset REDUX State Call
  const onReset = () => dispatch(resetFinancialReceiptSearch());
  // Generate FNC Number Action  Call
  const generateFCN = () => dispatch(generateFCNForAdd());
  // Read Generated FCN Number from REDUX State
  const newFCNNum = useSelector(
    state => state.financialReceiptState.generatedFCNNUM
  );

  const getCheckReplacementData = values =>
    dispatch(getReplacementDataAction(values));
  const checkReplacementData = useSelector(
    state => state.financialReceiptState.checkReplacementData
  );

  // DropDown Action Call
  const onLobDropdown = () => {
    if (!editFlag) {
      generateFCN();
    }
  };
  // Read DropDown Values from REDUX State
  const dropdown = useSelector(
    state => state.financialReceiptState.dropdownData
  );
  // On Payee Type Change Action Call
  const onChangePayeeType = payeeType => {
    dispatch(getPayeeIdTypeCodeAction(payeeType));
  };
  // Read Payee TypeID from REDUX State
  const payeeTypeIDDropdown = useSelector(
    state => state.financialReceiptState.payeeIDTypeCodeDetails
  );
  // Validate Payee Action Call
  const validatePayeeAPICall = validatePayeeobj => {
    if (editFlag) {
      dispatch(validatePayeeAction(validatePayeeobj));
    } else {
      dispatch(validatePayeeAction(validatePayeeobj));
    }
  };
  // Validate Member Action Call
  const validateMemberAPICall = validatePayeeobj => {
    dispatch(validateMemberAPI(validatePayeeobj));
  };
  // Read Validate Payee Result from REDUX State
  let validatePayeeResult = useSelector(
    state => state.financialReceiptState.validatePayeeResult
  );
  let validateMemberResult = useSelector(
    state => state.financialReceiptState.memberDetails
  );
  // TabPanel variable
  const [value, setValue] = React.useState(0);
  const initialPayeeTab = {
    entityType: DEFAULT_DD_VALUE, // Payee Type
    entityIDTypeCode: DEFAULT_DD_VALUE, // Payee ID Type Code
    entityID: "", // payeeID
    entityName: "", // Payee Name
    sysID: "", // System PayeeID
    memberIDType: DEFAULT_DD_VALUE,
    memberID: "",
    memberName: "",
    provBillSysId: ""
  };
  // Payee Details
  const [payeeTab, setPayeeTab] = React.useState(initialPayeeTab);
  // Original tab Details
  const [selectedDepositDate, setSelectedDepositDate] = React.useState(null);
  const [originalTab, setOriginalTab] = React.useState({
    fcn: "",
    activityTypeCode: DEFAULT_DD_VALUE, // activityTypeCode
    financialReason: DEFAULT_DD_VALUE, // financialReasonCode
    establishedDate: getDateInMMDDYYYYFormat(
      new Date(
        new Date().toLocaleString("en-US", {
          timeZone: process.env.REACT_APP_TIMEZONE
        })
      )
    ), // establishedDate
    fundCode: DEFAULT_DD_VALUE, // collocationCode
    depositDate: null, // depositDate
    depositNumber: "", // depositNumber
    invoice: "", // invoice
    originalAmount: "0.00", // originalAmt
    lockBoxNumber: "", // lockboxNumber
    itemNumber: "", // itemNumber
    balanceAmount: "0.00", // balanceAmt
    batchNumber: "", // batchNumber
    relatedFTTCN: "", // relatedFTTcn
    lastAppliedDate: "", // lastappliedDate
    pendingReason: DEFAULT_DD_VALUE, // pendingReason
    pendDate: "", // pendDate
    checkNumber: "", // checkNumber
    intTrackNum: "", // internalTrackingNumber,
    atrNumber: ""// ATR number,
  });
  // Disposition Table Data
  const [dispositoinTabTableData, setDispositionTabTableData] = React.useState(
    []
  );
  const handleDepositDateChange = date => {
    setFocusCheck(true);
    setAllowNavigation(true);
    setSelectedDepositDate(date);
    if (date !== null && date.toString() !== "Invalid Date") {
      setOriginalTab({
        ...originalTab,
        depositDate: getDateInMMDDYYYYFormat(date)
      });
    } else {
      setOriginalTab({ ...originalTab, depositDate: null });
    }
  };
  const zeroPad = (num, places) => String(num).padStart(places, "0");

  const checkNumberPad = () => {
    if (originalTab.checkNumber.length < 11 && originalTab.checkNumber !== "") {
      const chkNum = zeroPad(originalTab.checkNumber, 11);
      setOriginalTab({ ...originalTab, checkNumber: chkNum });
    }
  };
  const originalTabHandleChanges = name => event => {
    setFocusCheck(true);
    setAllowNavigation(true);
    if (name === 'atrNumber') {
      setOriginalATR(true);
    } else { setOriginalATR(false); }
    if (name === "originalAmount") {
      setOriginalTab({
        ...originalTab,
        [name]: event.target.value,
        balanceAmount: event.target.value
      });
    } else {
      setOriginalTab({ ...originalTab, [name]: event.target.value });
    }
    if (name === "void") {
      setOriginalTab({
        ...originalTab,
        voidIndicator: !originalTab.voidIndicator
      });
    }
    if (name === "pendingReason") {
      if (event.target.value !== DEFAULT_DD_VALUE) {
        setOriginalTab({
          ...originalTab,
          [name]: event.target.value,
          pendDate: moment(
            new Date(
              new Date().toLocaleString("en-US", {
                timeZone: process.env.REACT_APP_TIMEZONE
              })
            )
          ).format("MM/DD/YYYY")
        });
      } else {
        setOriginalTab({
          ...originalTab,
          [name]: event.target.value,
          pendDate: ""
        });
      }
    }
  };
  // Add Refund Tab  New FCN NUmber
  const [addRefundNewFCN, setAddRefundNewFCN] = React.useState(0);
  const [addRefundTabTableData, setAddRefundTabTableData] = React.useState([]);
  // Other Billing Receivable Disposition Tab
  const [otherBillingTab, setOtherBillingTab] = React.useState({
    billingRsnCode: DEFAULT_DD_VALUE, // dispositionResonCode
    otherBillingRecvDispfundCode: DEFAULT_DD_VALUE, // fundCode
    otherBillingCosCode: DEFAULT_DD_VALUE, // cos
    billingAmount: "",
    atrNumber: ""
  });
  // Payee error messages and corresponding flags
  const [showPayeeError, setShowPayeeError] = React.useState({
    showPTypeErr: false,
    showPIDTypeErr: false,
    showPIDErr: false,
    showMIDTypeErr: false,
    showMIDErr: false
  });
  const [payeeErrorMsg, setPayeeErrMsg] = React.useState({
    pTypeErrMsg: "",
    pIDTypeErrMsg: "",
    pIDErrMsg: "",
    mIDTypeErrMsg: "",
    mIDErrMsg: ""
  });
  // Original error messages and corresponding flags
  const [showOriginalError, setShowOriginalError] = React.useState({
    showATCodeErr: false,
    showFRCodeErr: false,
    showFCodeErr: false,
    showOAmtErr: false,
    showCheckNumErr: false,
    showIntTrackNumErr: false,
    showItemNumErr: false,
    showDipositDateErr: false,
    showATRErr: false
  });
  const [originalErrorMsg, setOriginalErrMsg] = React.useState({
    aTCodeErrMsg: "",
    atrErrMsg: "",
    fRCodeErrMsg: "",
    fCodeErrMsg: "",
    oAmtErrMsg: "",
    checkNumErrMsg: "",
    intTrackNumErrMSg: "",
    itemNumErrMsg: "",
    depositDateErrMsg: ""
  });
  // Other Tab error messages and corresponding flags
  const [showOtherTabError, setShowOtherTabError] = React.useState({
    showDispositionRCodeErr: false,
    shoeFundCodeErr: false,
    showCosErr: false,
    showPaymentAmtErr: false,
    showATRErr: false
  });
  const [otherTabErrMsg, setOtherTabErrMsg] = React.useState({
    dispositionRCodeErrMsg: "",
    fundCodeErrMsg: "",
    cosErrMsg: "",
    paymentAmtErrMsg: "",
    atrErrMsg: ""
  });
  const callValidatePayeeDetails = errorMessages => {
    return validatePayeeDetails(
      errorMessages,
      payeeTab,
      setShowPayeeError,
      setPayeeErrMsg,
      seterrorMessages
    );
  };

  const callValidateMemberDetails = errorMessages => {
    return memberIDValidate(
      errorMessages,
      payeeTab,
      setShowPayeeError,
      setPayeeErrMsg,
      seterrorMessages
    );
  };
  const callValidateOriginalDetails = errorMessagesArray => {
    const payeeTabRef = {
      payeeTab: payeeTab,
      showPayeeError: showPayeeError,
      setShowPayeeError: setShowPayeeError,
      payeeErrorMsg: payeeErrorMsg,
      setPayeeErrMsg: setPayeeErrMsg
    };
    return validateOriginalDetails(
      errorMessagesArray,
      originalTab,
      selectedDepositDate,
      setShowOriginalError,
      setOriginalErrMsg,
      payeeTabRef,
      seterrorMessages
    );
  };
  const callValidateAddRefundDetails = () => {
    if (addRefundTabTableData && addRefundTabTableData.length >= 0) {
      return true;
    } else {
      return false;
    }
  };
  const callValidateOtherBillingDetails = () => {
    return validateOtherTabDetails(
      otherBillingTab,
      originalTab,
      setShowOtherTabError,
      setOtherTabErrMsg,
      seterrorMessages
    );
  };

  const fieldDescription = (dropdownFilter, Code) => {
    const filteredValue = dropdownFilter.filter(
      (rCode, index) => rCode.code === Code
    );
    if (filteredValue && filteredValue.length > 0) {
      return filteredValue[0].description;
    }
    return Code;
  };
  React.useEffect(() => {
    if (payeeTypeDD && payeeTab.entityType !== DEFAULT_DD_VALUE) {
      const payeeTypeDesc = fieldDescription(payeeTypeDD, payeeTab.entityType);
      setPayeeTypeDesc(payeeTypeDesc);
    }
  }, [payeeTypeDD, payeeTab]);
  // Payee Tab Input change Event
  const payeeTabHandleChanges = name => event => {
    setFocusCheck(true);
    setInitalFocusCheck(true);
    setAllowNavigation(true);
    setPayeeTab({ ...payeeTab, [name]: event.target.value });
    if (
      name === "entityType" &&
      event.target.value !== DEFAULT_DD_VALUE &&
      event.target.value !== ""
    ) {
      const payeeTypeDesc = fieldDescription(payeeTypeDD, event.target.value);
      setPayeeTypeDesc(payeeTypeDesc);
    }
    if (
      name === "entityIDTypeCode" &&
      event.target.value !== DEFAULT_DD_VALUE &&
      event.target.value !== ""
    ) {
      const payeeIdTypeDesc = fieldDescription(payeeIDTypeDD, event.target.value);
      setPayeeTypeIdCodeDesc(payeeIdTypeDesc);
    }
  };
  // Other Billing Tab Input Change Event
  const otherBillingTabHandleChanges = name => event => {
      setOriginalATR(false);
    if (name === 'billingAmount') {
      if (event.target.value && event.target.value.includes('.')) {
        if (checkingDecimal(event.target.value)) {
          setOtherBillingTab({ ...otherBillingTab, [name]: event.target.value });
        }
      } else {
        setOtherBillingTab({ ...otherBillingTab, [name]: event.target.value });
      }
    } else {
      setOtherBillingTab({ ...otherBillingTab, [name]: event.target.value });
    }
    setFocusCheck(true);
    setAllowNavigation(true);
  };
  // TabPanle Change Event
  const handleChangeTabs = (event, newValue, oldValue) => {
    if (!TabErrorChange()) {
      setColor({ ...color, orginalTabClr: "green" });
    } else {
      setColor({ ...color, orginalTabClr: "red" });
    }
    setValue(newValue);
  };
  const [payeeTypeDesc, setPayeeTypeDesc] = React.useState("");
  const [payeeTypeIdCodeDesc, setPayeeTypeIdCodeDesc] = React.useState("");
  // Validate Payee Button Event
  const [validatePayeeOnBlur, setValidatePayeeOnBlur] = React.useState(false);
  const validatePayeeBtnEvent = event => {
    setValidatePayeeOnBlur(true);
    const validatePayeeObj = {
      lob: DEFAULT_LOB_VALUE,
      entityType: payeeTab.entityType,
      entityIDTypeCode: payeeTab.entityIDTypeCode,
      entityID: payeeTab.entityID,
      fromReceiptAddPage: true
    };
    // To clear original Tab's field level error messages
    setOriginalErrMsg([]);
    setShowOriginalError([]);
    if (!regularPayee && callValidatePayeeDetails([])) {
      validatePayeeAPICall(removeLeadingTrailingSpaces(validatePayeeObj));
    }
  };
  // Validate Member ID
  const validateMember = event => {
    const validatePayeeObj = {
      lob: "",
      entityType: "",
      entityIDTypeCode: "",
      entityID: "",
      fromReceiptAddPage: true,
      memberID: payeeTab.memberID,
      memberIDType: payeeTab.memberIDType
    };
    // To clear original Tab's field level error messages
    setOriginalErrMsg([]);
    setShowOriginalError([]);
    if (
      callValidateMemberDetails([]) &&
      validatePayeeObj.memberID !== "" &&
      validatePayeeObj.memberIDType !== DEFAULT_DD_VALUE
    ) {
      validateMemberAPICall(removeLeadingTrailingSpaces(validatePayeeObj));
    }
  };
  // Receipt ADD Master Save Action
  const addFinancialReceipt = value => {
    const attachmentVOList = [];
    if (attachmentsTableData && attachmentsTableData.length > 0) {
      attachmentsTableData.map(value => {
        if (value.id) {
          const attachmentVO = {
            auditUserID: loginUserID,
            auditTimeStamp: getUTCTimeStamp(),
            addedAuditUserID: loginUserID,
            addedAuditTimeStamp: getUTCTimeStamp(),
            versionNo: 0,
            dbRecord: true,
            sortColumn: null,
            attachmentSK: null,
            attachmentPageID: null,
            finalPath: null,
            detachInd: false,
            historyIndicator: false,
            cascadeKey: null,
            attachmentIndicator: null,
            dateAdded: getDateInMMDDYYYYFormatWithApendZero(
              new Date(
                new Date().toLocaleString("en-US", {
                  timeZone: process.env.REACT_APP_TIMEZONE
                })
              )
            ),
            fileUrl: null,
            showDeleteForSearched: false,
            rowIndex: 0,
            addedBy: loginUserID,
            description: value ? value.description : null,
            edmsStoredDate: null,
            anEDMSPageId: null,
            fileName: attachmentsTableData && value ? value.fileName : null,
            file1: null,
            edmsWrkUnitLevel: null,
            edmsDocType: null
          };
          attachmentVOList.push(attachmentVO);
        }
      });
    }
    value.attachmentVO = null;
    value.attachmentsList = attachmentVOList;
         dispatch(
      addFinancialReceiptAction(
        removeLeadingTrailingSpaces(value),
        attachmentsTableData.length > 0 ? attachmentFile : null
      )
    );
  };
  // Receipt Edit Master Save Action
  const editFinancialReceipt = value => {
    const index = attachmentsTableData.length - 1;
    value.voidNoteInd = !userInquiryPrivileges ? !userInquiryPrivileges : false;
    value.voidDate = getDateInMMDDYYYYFormat(value.voidDate);
    const attachmentVOList = [];
    if (attachmentsTableData && attachmentsTableData.length > 0) {
      attachmentsTableData.map(value => {
        if (value.id) {
          const attachmentVO = {
            auditUserID: loginUserID,
            auditTimeStamp: getUTCTimeStamp(),
            addedAuditUserID: loginUserID,
            addedAuditTimeStamp: getUTCTimeStamp(),
            versionNo: 0,
            dbRecord: true,
            sortColumn: null,
            attachmentSK: null,
            attachmentPageID: null,
            finalPath: null,
            detachInd: false,
            historyIndicator: false,
            cascadeKey: null,
            attachmentIndicator: null,
            dateAdded: getDateInMMDDYYYYFormatWithApendZero(
              new Date(
                new Date().toLocaleString("en-US", {
                  timeZone: process.env.REACT_APP_TIMEZONE
                })
              )
            ),
            fileUrl: null,
            showDeleteForSearched: false,
            rowIndex: 0,
            addedBy: loginUserID,
            description: value ? value.description : null,
            edmsStoredDate: null,
            anEDMSPageId: null,
            fileName: attachmentsTableData && value ? value.fileName : null,
            file1: null,
            edmsWrkUnitLevel: null,
            edmsDocType: null
          };
          attachmentVOList.push(attachmentVO);
        }
      });
    }
    value.attachmentVO = null;
    value.attachmentsList = attachmentVOList;
    dispatch(
      updateFinancialReceiptAction(
        removeLeadingTrailingSpaces(value),
        attachmentsTableData &&
          index >= 0 &&
          attachmentsTableData[index] &&
          attachmentsTableData[index].id
          ? attachmentFile
          : null
      )
    );
  };
  // TabChange Error ALL Errors
  const TabErrorChange = () => {
    const errorMessagesArray = [];
    let _validateOTab = false;
    _validateOTab = callValidateOriginalDetails(errorMessagesArray);
    if (
      callValidatePayeeDetails(_validateOTab) &&
      _validateOTab.length === 0 &&
      callValidateAddRefundDetails() &&
      callValidateOtherBillingDetails()
    ) {
      return true;
    } else {
      return false;
    }
  };
  // Initial Component Load useEffect
  useEffect(() => {
    if (!props.location.state) {
      editFlag = false;
    }
    if (props.location.state) {
      if (
        props.location.state.successMessages &&
        !props.location.state.fromSearch
      ) {
        const tempVar = JSON.parse(
          JSON.stringify(props.location.state.successMessages)
        );
        setSuccessMessages(tempVar);
      } else {
        setSuccessMessages([]);
      }
    }
    seterrorMessages([]);

    onReset();
    onLobDropdown();
    dispatch(getDropdownDataAction(dropdownCriteria));
    payloadViewData = null;
    validatePayeeResult = null;
    validateMemberResult = null;
    addFinancialReceiptResult = null;
    updateFinancialReceiptResult = null;
  }, []);

  useEffect(() => {
    if (auditButtonToggle) {
      onClickAuditLog();
      if (auditLogRow) {
        onClickAuditDialogLog(auditLogRow);
      }
    } else {
      setClose(false);
      setShowLogDialogTable(false);
      setShowLogTable(false);
    }
  }, [auditButtonToggle]);

  useEffect(() => {
    if (auditLogRow && auditButtonToggle) {
      setAuditTableData([]);
      onClickAuditDialogLog(auditLogRow);
    } else {
      setAuditTableData([]);
    }
  }, [auditLogRow]);
  // Add master save results
  let addFinancialReceiptResult = useSelector(
    state => state.financialReceiptState.addFinanceReceiptResult
  );
  useEffect(() => {
    if (addFinancialReceiptResult && addFinancialReceiptResult.systemFailure) {
      const errorMessagesArray = [];
      errorMessagesArray.push(FinancialReceiptConstants.GENERIC_SYSTEM_ERROR);
      seterrorMessages(errorMessagesArray);
    }
    if (addFinancialReceiptResult && !addFinancialReceiptResult.systemFailure) {
      if (addFinancialReceiptResult.respcode === "01") {
        setAllowNavigation(false);

        const errorMessagesArray = [];
        seterrorMessages(errorMessagesArray);
        const successMessagesArray = [];
        successMessagesArray.push(addFinancialReceiptResult.respdesc);
        setSuccessMessages(successMessagesArray);
        setFocusCheck(false);
        onSearchView({
          fcn: originalTab.fcn,
          fcnSearch: true
        });
      } else {
        if (addFinancialReceiptResult.errorMessages !== null) {
          seterrorMessages(addFinancialReceiptResult.errorMessages);
        } else {
          const errorMessagesArray = [];
          errorMessagesArray.push(addFinancialReceiptResult.respdesc);
          seterrorMessages(errorMessagesArray);
        }
      }
    }
  }, [addFinancialReceiptResult]);
  // Receipt Update Results
  let updateFinancialReceiptResult = useSelector(
    state => state.financialReceiptState.updateFinancialReceiptResult
  );
  useEffect(() => {
    if (
      updateFinancialReceiptResult &&
      updateFinancialReceiptResult.systemFailure &&
      editFlag
    ) {
      const errorMessagesArray = [];
      errorMessagesArray.push(FinancialReceiptConstants.GENERIC_SYSTEM_ERROR);
      seterrorMessages(errorMessagesArray);
    }
    if (
      updateFinancialReceiptResult &&
      !updateFinancialReceiptResult.systemFailufe &&
      updateFinancialReceiptResult.respcode &&
      editFlag
    ) {
      if (updateFinancialReceiptResult.respcode === "01") {
        setUpdatePayee(false);
        setAllowNavigation(false);
        const errorMessagesArray = [];
        seterrorMessages(errorMessagesArray);
        const successMessagesArray = [];
        successMessagesArray.push(updateFinancialReceiptResult.respdesc);
        setSuccessMessages(successMessagesArray);
        setFocusCheck(false);
        onSearchView({
          fcn: originalTab.fcn,
          fcnSearch: true
        });
      } else {
        if (updateFinancialReceiptResult.errorMessages !== null) {
          seterrorMessages(updateFinancialReceiptResult.errorMessages);
        } else {
          const errorMessagesArray = [];
          errorMessagesArray.push(updateFinancialReceiptResult.respdesc);
          seterrorMessages(errorMessagesArray);
        }
      }
    }
  }, [updateFinancialReceiptResult]);
  // Receipt ADD Master Save
  const receiptAddMasterSave = () => {
    const errorMessagesArray = [];
    let _validateOTab = false;
    _validateOTab = callValidateOriginalDetails(errorMessagesArray);
    if (
      callValidatePayeeDetails(_validateOTab) &&
      _validateOTab.length === 0 &&
      callValidateAddRefundDetails() &&
      callValidateOtherBillingDetails()
    ) {
      if (!editFlag) {
        addFinancialReceipt(
          buildMasterSaveObject(
            loginUserID,
            null,
            payeeTab,
            originalTab,
            [],
			[],
			[],
            notesInput,
            isOriginalATR
          )
        );
      } else {
        if (!allowNavigation) {
          NoSaveMessage();
        } else {
          setSuccessMessages([]);
          const requestDis = [];
          const requestRefund = [];
          referenceDis.map(var1 => {
            dispositoinTabTableData.map(var2 => {
              if (var1.receivableFCN === var2.receivableFCN) {
                if (
                  var1.recvRsnCd !== var2.recvRsnCd ||
                  var1.amountToApply !== var2.amountToApply
                ) {
                  var2.editDispositioinFlag = true;
                  var2.amountToApply = numeral(var2.amountToApply)
                    .format("0,0.00")
                    .replace(/,/g, "");
                  var2.amountToApplyStr = numeral(var2.amountToApplyStr)
                    .format("0,0.00")
                    .replace(/,/g, "");
                  requestDis.push(var2);
                }
              }
            });
          });

          addRefundTabTableData.map(var1 => {
            if (var1.newRow) {
              requestRefund.push(var1);
              updateData.refundRecoveryDispositionMinorSaveFlag = true;
            }
            referencerefund.map(var2 => {
              if (var1.fcn === var2.fcn) {
                if (
                  var1.reasonCode !== var2.reasonCode ||
                  var1.fundCode !== var2.fundCode ||
                  var1.cos !== var2.cos ||
                  var1.payerAmount !== var2.payerAmount ||
                  var1.entityType !== var2.entityType ||
                  var1.entityIDTypeCode !== var2.entityIDTypeCode ||
                  var1.entityID !== var2.entityID ||
                  var1.fiscalYear !== var2.fiscalYear ||
                  var1.ffpCalculateDate !== var2.ffpCalculateDate
                ) {
                  var1.orgAuditTimeStamp = null;
                  var1.updatedAuditTime = null;
                  requestRefund.push(var1);
                  updateData.refundRecoveryDispositionMinorSaveFlag = true;
                }
              }
            });
          });
          editFinancialReceipt(
            buildMasterSaveObject(
              loginUserID,
              updateData,
              payeeTab,
              originalTab,
              requestRefund,
              requestDis,
              otherBillingTab,
              notesInput,
              isOriginalATR
            )
          );
        }
      }
    }
  };
  const getPayeeIdFromSystemParameter = systemParameter =>
    dispatch(getSystemParameterSpecificValue(systemParameter));
  const systemParameterResult = useSelector(
    state => state.sharedState.SystemParameterData
  );
  // update payload action after add success
  const onSearchView = searchviewvalues => {
    dispatch(financialReceiptSearchAction(searchviewvalues));
  };
  // redirect to edit page
  useEffect(() => {
    if (
      payloadViewData &&
      !payloadViewData.systemFailure &&
      payloadViewData.respcode === "01" &&
      payloadViewData.enterpriseSearchresultVO &&
      payloadViewData.enterpriseSearchresultVO.searchResults &&
      payloadViewData.enterpriseSearchresultVO.searchResults.length === 1
    ) {
      const payloadToEdit = JSON.parse(
        JSON.stringify(payloadViewData.enterpriseSearchresultVO.searchResults)
      );

      setUpdatePayee(false);
      getPayeeIdFromSystemParameter({
        paramNumber: 9015,
        functionalArea: "F1",
        lobCode: DEFAULT_LOB_VALUE,
        asOfDate: moment(
          new Date(
            new Date().toLocaleString("en-US", {
              timeZone: process.env.REACT_APP_TIMEZONE
            })
          )
        ).format("YYYY-DD-MM")
      });
      props.history.push({
        pathname: "/FinancialReceiptUpdate",
        state: {
          payloadViewData: payloadToEdit,
          dropdownData: dropdown,
          editFlag: true,
          successMessages: successMessages,
          values: ValuesFromFinReceipt,
payeeTypeIDDropdown: PayeeTypeIDDropdown,
isFromAdd: true
        }
      });
    }
    if (payloadViewData && payloadViewData.systemFailure) {
      seterrorMessages([FinancialReceiptConstants.GENERIC_SYSTEM_ERROR]);

      setSuccessMessages([]);
    }
  }, [payloadViewData]);

  useEffect(() => {
    if (allowNavigation) {
      setSuccessMessages([]);
    }
  }, [allowNavigation]);

  // Setting DropDown Values useEffect
  useEffect(() => {
    if (dropdown && dropdown.listObj) {
      if (!editFlag) {
        setOriginalTab({ ...originalTab, activityTypeCode: "RC" });
      }
      if (dropdown.listObj["General#6002"]) {
        setPayeeTypeDD(dropdown.listObj["General#6002"]);
      }
      if (dropdown.listObj["Financial#6008"]) {
        setDrugRebateReasonCode(dropdown.listObj["Financial#6008"]);
      }
      if (dropdown.listObj["Financial#2"]) {
        setMemberIDTypeDD(dropdown.listObj["Financial#2"]);
      }
      if (dropdown.listObj["Financial#3012"]) {
        setActivityTypeCodeDD(dropdown.listObj["Financial#3012"]);
      }
      if (dropdown.listObj["Financial#3007"]) {
        setFinancialReasCodeDD(dropdown.listObj["Financial#3007"]);
      }
      if (dropdown.listObj["Financial#3013"]) {
        setReasCodeDD(dropdown.listObj["Financial#3013"]);
      }
      if (dropdown.listObj["Claims#1020"]) {
        setCosDD(dropdown.listObj["Claims#1020"]);
        if (addRefundTabTableData) {
          addRefundTabTableData.map(var1 => {
            var1._cosDescr = mapCodeDescription(
              var1.cos,
              dropdown.listObj["Claims#1020"]
            );
            var1.cosLongDesc = getLongDescription(
              dropdown.listObj["Claims#1020"],
              var1.cos
            );
          });
        }
      }
      if (dropdown.listObj["Financial#3200"]) {
        setCollationCodeDD(dropdown.listObj["Financial#3200"]);
      }
      if (dropdown.listObj["Financial#3021"]) {
        setCollationCode213DD(dropdown.listObj["Financial#3021"]);
      }
      if (dropdown.listObj["Financial#1003"]) {
        setPendingReasonDD(dropdown.listObj["Financial#1003"]);
      }
      if (dropdown.listObj["Financial#1001"]) {
        setdispositionRsnCodeDD(dropdown.listObj["Financial#1001"]);
      }
      if (dropdown.listObj["Financial#16"]) {
        setRefundDispositionRsnCodeDD(dropdown.listObj["Financial#16"]);
        if (addRefundTabTableData) {
          addRefundTabTableData.map(var1 => {
            var1._reasonCodeDescr = mapCodeDescription(
              var1.reasonCode,
              dropdown.listObj["Financial#16"]
            );
          });
        }
      }
      if (dropdown.listObj["Financial#3009"]) {
        setotherDispositionRsnCodeDD(dropdown.listObj["Financial#3009"]);
      }
      if (dropdown.listObj["General#1012"]) {
        setUsageTypeCodeData(dropdown.listObj["General#1012"]);
      }
      if (dropdown.listObj["Financial#6001"]) {
        setInitialDispositionRsnCodeDD(dropdown.listObj["Financial#6001"]);
      }
      if (dropdown.listObj["Financial#6009"]) {
        setCheckReplacementRsnCodes(dropdown.listObj["Financial#6009"]);
      }
      if (dropdown.listObj["Financial#6010"]) {
        setFinancialReasCodeDDAdd(dropdown.listObj["Financial#6010"]);
      }
      if (dropdown.listObj["Financial#3497"]) {
        setStopPaymentRsn(dropdown.listObj["Financial#3497"]);
        setDisableReverseRsnCodes(rsnCodes => [...rsnCodes, ...dropdown.listObj["Financial#3497"]]);
      }
      if (dropdown.listObj["Financial#29"]) {
        setDisableReverseRsnCodes(rsnCodes => [...rsnCodes, ...dropdown.listObj["Financial#29"]]);
      }
    }
  }, [dropdown]);

  useEffect(() => {
    if (
      refundDispositionRsnCodeDD.length > 0 &&
      financialReasCodeDD.length > 0 &&
      initialDispositionRsnCodeDD.length > 0 &&
      otherDispositionRsnCodeDD.length > 0
    ) {
      let historyTemp = JSON.parse(JSON.stringify(historyData));
      for (var historyValue in historyTemp) {
        if (historyTemp[historyValue].tcn) {
          if (historyTemp[historyValue].tcn.length === 14) {
            refundDispositionRsnCodeDD.map(value => {
              if (historyTemp[historyValue].reasonCode === value.description || historyTemp[historyValue].reasonCode === value.code) {
                historyTemp[historyValue].activityType = "Payout";
              }
            });
            financialReasCodeDD.map(value => {
              if (historyTemp[historyValue].reasonCode === value.description || historyTemp[historyValue].reasonCode === value.code) {
                historyTemp[historyValue].activityType = "Receipt";
              }
            });
            dispositionRsnCodeDD.map(value => {
              if (historyTemp[historyValue].reasonCode === value.code) {
                historyTemp[historyValue].activityType = "AccountR";
              }
            });
            initialDispositionRsnCodeDD.map(value => {
              if (historyTemp[historyValue].reasonCode === value.description || historyTemp[historyValue].reasonCode === value.code) {
                historyTemp[historyValue].activityType = "AccountR";
              }
            });
            otherDispositionRsnCodeDD.map(value => {
              if (historyTemp[historyValue].reasonCode === value.description || historyTemp[historyValue].reasonCode === value.code) {
                if (historyTemp[historyValue].tcn === originalTab.fcn) {
                  historyTemp[historyValue].activityType = "Receipt";
                } else {
                  historyTemp[historyValue].activityType = "AccountR";
                }
              }
            });
          } else {
            historyTemp[historyValue].activityType = "FTTCN";
          }
         setHistoryData(historyTemp);
        }
      }
      historyTemp = JSON.parse(JSON.stringify(addRefundTabTableData));
      for (const historyValue in historyTemp) {
        if (historyTemp[historyValue].fcn) {
          if (historyTemp[historyValue].fcn.length === 14) {
            refundDispositionRsnCodeDD.map(value => {
              if (historyTemp[historyValue].reasonCode === value.code) {
                historyTemp[historyValue].activityType = "Payout";
              }
            });
            financialReasCodeDD.map(value => {
              if (historyTemp[historyValue].reasonCode === value.code) {
                historyTemp[historyValue].activityType = "Receipt";
              }
            });
            initialDispositionRsnCodeDD.map(value => {
              if (historyTemp[historyValue].reasonCode === value.code) {
                historyTemp[historyValue].activityType = "AccountR";
              }
            });
            otherDispositionRsnCodeDD.map(value => {
              if (historyTemp[historyValue].reasonCode === value.code) {
                if (historyTemp[historyValue].fcn === originalTab.fcn) {
                  historyTemp[historyValue].activityType = "Receipt";
                } else {
                  historyTemp[historyValue].activityType = "AccountR";
                }
              }
            });
          } else {
            historyTemp[historyValue].activityType = "FTTCN";
          }
          setAddRefundTabTableData(historyTemp);
        }
      }
    }
  }, [
    refundDispositionRsnCodeDD,
    financialReasCodeDD,
    initialDispositionRsnCodeDD,
    dispositionRsnCodeDD,
    otherDispositionRsnCodeDD
  ]);

  useEffect(() => {
    const errorMessagesArray = [];
    if (newFCNNum && newFCNNum.systemFailure && !editFlag) {
      errorMessagesArray.push(FinancialReceiptConstants.GENERIC_SYSTEM_ERROR);
      seterrorMessages(errorMessagesArray);
    }
    if (newFCNNum && !newFCNNum.systemFailure) {
      // For Original Tab Value is zero (0)
      if (value === 0 && !editFlag) {
        setOriginalTab({ ...originalTab, fcn: newFCNNum });
      }
      // For Refund/General Recovery Disposition Tab Value is Two (2)
      if (value === 2) {
        setAddRefundNewFCN(newFCNNum);
      }
    }
  }, [newFCNNum]);

  // On change Payee Type dropdown to load Payee Id Type Code dropdown values
  useEffect(() => {
    if (!editFlag) {
      setPayeeTab({ ...payeeTab, entityIDTypeCode: DEFAULT_DD_VALUE });
    }
    if (payeeTab.entityType !== DEFAULT_DD_VALUE) {
      payeeTabPayeeTypeChngFlag = true;
      setPayeeIDTypeDD([]);
      onChangePayeeType(payeeTab.entityType);
    }
  }, [payeeTab.entityType]);
  useEffect(() => {
    if (
      payeeTabPayeeTypeChngFlag &&
      payeeTypeIDDropdown &&
      payeeTypeIDDropdown.length > 0 &&
      !payeeTypeIDDropdown.systemFailure
    ) {
      payeeTabPayeeTypeChngFlag = false;
      setPayeeIDTypeDD(payeeTypeIDDropdown);
    }
    if (
      value === 2 &&
      !payeeTabPayeeTypeChngFlag &&
      payeeTypeIDDropdown &&
      payeeTypeIDDropdown.length > 0 &&
      !payeeTypeIDDropdown.systemFailure
    ) {
      // seterrorMessages([]);
      setRefundPayeeIDTypeDD(payeeTypeIDDropdown);
    }
  }, [payeeTypeIDDropdown]);
  // setting Validate Member ID
  useEffect(() => {
    const errorMessagesArray = [];

    if (validateMemberResult && validateMemberResult.systemFailure) {
      errorMessagesArray.push(FinancialReceiptConstants.GENERIC_SYSTEM_ERROR);
      setPayeeTab({
        ...payeeTab,
        memberName: ""
      });
      seterrorMessages(errorMessagesArray);
    }
    if (
      validateMemberResult &&
      !validateMemberResult.systemFailure &&
      validateMemberResult.respcode !== "01"
    ) {
      if (validateMemberResult.respdesc) {
        errorMessagesArray.push(validateMemberResult.respdesc);
      } else {
        errorMessagesArray.push(FinancialReceiptConstants.GENERIC_SYSTEM_ERROR);
      }
      setPayeeTab({
        ...payeeTab,
        memberName: ""
      });
      seterrorMessages(errorMessagesArray);
    } else {
      if (
        validateMemberResult &&
        !validateMemberResult.systemFailure &&
        validateMemberResult.respcode === "01"
      ) {
        setPayeeTab({
          ...payeeTab,
          memberName:
            validateMemberResult.enterpriseBaseVO.memberName === null
              ? ""
              : validateMemberResult.enterpriseBaseVO.memberName
        });
      }
    }
  }, [validateMemberResult]);

  const [sysParameterPayeeExist, setSystemparameterPayeeExist] = useState(
    false
  );
  const [regularPayee, setRegularPayee] = useState(false);
  useEffect(() => {
    if (systemParameterResult) {
      const systemParameterData = systemParameterResult.valueNum;
      const systemparameterPayeeId = systemParameterData?.toString();
      // let payeeEntityId = payeeTab.entityID.toString()
      if (
        validatePayeeResult &&
        !validatePayeeResult.systemFailure &&
        PayeeTab.entityID === systemparameterPayeeId
      ) {
        setSystemparameterPayeeExist(true);
        setRegularPayee(false);
      } else if (
        payeeTab.entityID !== "" &&
        payeeTab.entityID === systemparameterPayeeId
      ) {
        setSystemparameterPayeeExist(true);
        setRegularPayee(false);
      } else if (
        !regularPayee &&
        payeeTab.entityID !== "" &&
        payeeTab.entityID !== systemparameterPayeeId &&
        editFlag &&
        props.location.state.payloadViewData !== null &&
        props.location.state.payloadViewData &&
        !props.location.state.payloadViewData.systemFailure &&
        props.location.state.payloadViewData[0].entityID !==
        systemparameterPayeeId
      ) {
        setSystemparameterPayeeExist(false);
        setRegularPayee(true);
      }
    }
  }, [systemParameterResult]);

  // Setting Validate Payee Result useEffect
  useEffect(() => {
    const errorMessagesArray = [];

    if (validatePayeeResult && validatePayeeResult.systemFailure) {
      errorMessagesArray.push(FinancialReceiptConstants.GENERIC_SYSTEM_ERROR);
      setPayeeTab({
        ...payeeTab,
        sysID: "",
        entityName: ""
      });
      setDispositionTabTableData([]);
      seterrorMessages(errorMessagesArray);
    }
    if (
      validatePayeeResult &&
      !validatePayeeResult.systemFailure &&
      validatePayeeResult.payeePayerID === null
    ) {
      errorMessagesArray.push(FinancialReceiptConstants.PAYEE_NOTFOUND);
      seterrorMessages(errorMessagesArray);
      setPayeeTab({
        ...payeeTab,
        sysID: "",
        entityName: ""
      });
      setDispositionTabTableData([]);
      setSystemparameterPayeeExist(false);
    } else if (
      validatePayeeResult &&
      !validatePayeeResult.systemFailure &&
      validatePayeeResult.payeePayerID !== null
    ) {
      setSystemparameterPayeeExist(false);
      getPayeeIdFromSystemParameter({
        paramNumber: 9015,
        functionalArea: "F1",
        lobCode: DEFAULT_LOB_VALUE,
        asOfDate: moment(
          new Date(
            new Date().toLocaleString("en-US", {
              timeZone: process.env.REACT_APP_TIMEZONE
            })
          )
        ).format("YYYY-DD-MM")
      });
      if (
        validatePayeeOnBlur &&
        validatePayeeResult &&
        validatePayeeResult.multiplePayeeDetails &&
        validatePayeeResult.multiplePayeeDetails.length > 1
      ) {
        setOpenSysPayeeTable(true);
        setSystemPayeeTableData(validatePayeeResult.multiplePayeeDetails);
        setPayeeTab({
          ...payeeTab,
          provBillSysId: validatePayeeResult.provBillSysId
            ? validatePayeeResult.provBillSysId
            : ""
        });
      } else {
        let sysIDPayeeName = null;
        let sysIDPayee = null;
        let provBillID = null;
        if (
          payeeTab.sysID &&
          editFlag &&
          validatePayeeResult &&
          validatePayeeResult.multiplePayeeDetails &&
          validatePayeeResult.multiplePayeeDetails.length > 1
        ) {
          validatePayeeResult.multiplePayeeDetails.map(payeeDetails => {
            if (payeeDetails.sysID === payeeTab.sysID) {
              sysIDPayeeName = payeeDetails.payeeName;
              sysIDPayee = payeeDetails.sysID;
              provBillID = payeeDetails.provBillingSysID;
            }
          });
        }
        setPayeeTab({
          ...payeeTab,
          sysID: sysIDPayee || (validatePayeeResult.payeePayerID === null
              ? ""
              : validatePayeeResult.payeePayerID),
          entityName: sysIDPayeeName || validatePayeeResult.entityName,
          provBillSysId: provBillID || (validatePayeeResult.provBillSysId
              ? validatePayeeResult.provBillSysId
              : "")
        });
        if (
          validatePayeeResult.dispositionVOList &&
          validatePayeeResult.dispositionVOList.length > 0
        ) {
          setReferenceDis(
            JSON.parse(JSON.stringify(validatePayeeResult.dispositionVOList))
          );
          const tempDispoVOList = [...validatePayeeResult.dispositionVOList];
          tempDispoVOList.map((value, index) => {
            value._recvRsnCdDescr = mapCodeDescription(
              value.recvRsnCd,
              financialReasCodeDD
            );
            value.rcLongDesc = getLongDescription(
              financialReasCodeDD,
              value.recvRsnCd
            );
          });
        }
        validatePayeeResult.dispositionVOList.map(value => {
          if (value.recvRsnCd) {
            value._recvRsnCdDescr = mapCodeDescription(
              value.recvRsnCd,
              initialDispositionRsnCodeDD
            );
            value.rcLongDesc = getLongDescription(
              initialDispositionRsnCodeDD,
              value.recvRsnCd
            );
          }
        });
        setDispositionTabTableData(
          JSON.parse(JSON.stringify(validatePayeeResult.dispositionVOList))
        );
      }
    }
  }, [validatePayeeResult]);
  useEffect(() => {
    if (
      initialDispositionRsnCodeDD &&
      initialDispositionRsnCodeDD.length > 0 &&
      dispositoinTabTableData &&
      dispositoinTabTableData.length > 0
    ) {
      dispositoinTabTableData.map(value => {
        if (value.recvRsnCd) {
          value._recvRsnCdDescr = mapCodeDescription(
            value.recvRsnCd,
            initialDispositionRsnCodeDD
          );
          value.rcLongDesc = getLongDescription(
            initialDispositionRsnCodeDD,
            value.recvRsnCd
          );
        }
      });
    }
  }, [initialDispositionRsnCodeDD, dispositoinTabTableData]);
  useEffect(() => {
    // eslint-disable-next-line no-mixed-operators
    if (
      adjustData &&
      !adjustData.systemFailure &&
      (adjustData.responseCodeVO === null ||
        (adjustData.responseCodeVO !== undefined &&
          adjustData.responseCodeVO.respcode === "01") ||
        (adjustData.responseCodeVO !== undefined &&
          adjustData.responseCodeVO.respcode === ""))
    ) {
      const tempData = JSON.parse(JSON.stringify(adjustData));
      if (tempData.receiptVO) {
        tempData.receiptVO.entityName = payeeTab.entityName
          ? payeeTab.entityName
          : "";
      }
      tempData.receiptVO.memberName = payeeTab.memberName;
      props.history.push({
        pathname: "/FinancialReceiptAdjustment",
        state: {
 adjustData: tempData,
values: ValuesFromFinReceipt,
payeeTypeIDDropdown: PayeeTypeIDDropdown,
          dropdowns: addDropdowns,
atrNumber: originalTab.atrNumber
        }
      });
    } else {
      if (adjustData && !adjustData.systemFailure) {
        seterrorMessages([
          FinancialReceiptConstants.BALANCE_AMOUNT_ZERO_ADJUST
        ]);
      }
    }
    if (adjustData && adjustData.systemFailure) {
      seterrorMessages([FinancialReceiptConstants.GENERIC_SYSTEM_ERROR]);
    }
  }, [adjustData]);

  useEffect(() => {
    if (
      checkReplacementData &&
      checkReplacementData != null &&
      checkReplacementData !== ""
    ) {
      if (checkReplacementData.receiptVO) {
        props.history.push({
          pathname: "/ReissueUpdate",
          state: {
            checkReplacementData: checkReplacementData,
            fcnSearchData: replacementData,
            atrNumber: originalTab.atrNumber,
            reasonCodeDD: financialReasCodeDD,
            values: ValuesFromFinReceipt,
            payeeTypeIDDropdown: PayeeTypeIDDropdown,
            dropdowns: addDropdowns,
            memberIDTypeDD: memberIDTypeDD
          }
        });
      } else if (
        checkReplacementData.responseCodeVO &&
        checkReplacementData.responseCodeVO.respcode &&
        checkReplacementData.responseCodeVO.respcode !== "01"
      ) {
        if (checkReplacementData.responseCodeVO.respdesc) {
          seterrorMessages([checkReplacementData.responseCodeVO.respdesc]);
        } else if (checkReplacementData.responseCodeVO.errorMessages) {
          seterrorMessages(checkReplacementData.responseCodeVO.errorMessages);
        }
      }
    }
    if (checkReplacementData && checkReplacementData.systemFailure) {
      seterrorMessages([FinancialReceiptConstants.GENERIC_SYSTEM_ERROR]);
    }
  }, [checkReplacementData]);

  const getAdjust = event => {
    getAdjustData(dataFromPrev);
  };

  useEffect(() => {
    validValueDropdowns([
      Dropdowns.F_RSN_CD,
      Dropdowns.PAYEE_TYPE,
      Dropdowns.PROVIDER_ID_TYPE,
      Dropdowns.PAYEE_ID_TYPE_CD_MEMBER,
      Dropdowns.PAYEE_ID_TYPE_CD_OTHER,
      Dropdowns.PAYEE_ID_TYPE_CD_TPL,
      Dropdowns.MEMBER_TYPE_DD,
      Dropdowns.FUND_CODE,
      Dropdowns.PAY_STATUS
    ]);
    dispatch(GET_SYSTEM_LIST_DROPDOWNS([
      Dropdowns.REASON_CODES_9301,
      Dropdowns.REASON_CODES_9302,
      Dropdowns.REASON_CODES_9303,
      Dropdowns.REASON_CODES_9304,
    ], 'FS_REASON_CODES'));
  }, [])

  const Return = event => {
    if (originalTab.balanceAmount != originalTab.originalAmount || originalTab.voidDate) {
      seterrorMessages([FinancialReceiptConstants.RETURN_ONLY_ONCE_ERROR]);
      return false;
    }
    const request = JSON.parse(JSON.stringify(dataFromPrev));
    request.FinacialReasoncodeLD = getLongDescription(
      financialReasCodeDD,
      request.financialReasonValue
    );
    props.history.push({
      pathname: "/FinancialReceiptReturn",
      state: {
        searchData: request,
        payeeType: payeeTypeDD,
        payeeTypeCode: payeeIDTypeDD,
        payeeName: payeeTab.entityName,
        values: ValuesFromFinReceipt,
        payeeTypeIDDropdown: PayeeTypeIDDropdown,
        dropdowns: addDropdowns,
        atrNumber: originalTab.atrNumber
      }
    });
  };
  const Reverse = event => {
    const request = JSON.parse(JSON.stringify(dataFromPrev));
    request.entityType = request.entityType.split("-")[0];
    getReverseAdd(request);
  };

  /* navigateToCheckReplacement: this function is used to navigate to reissue add screen */
  const navigateToCheckReplacement = e => {
    if (
      checkReplacementRsnCodes.some(
        value => value.code === originalTab.financialReason
      )
    ) {
      setAllowNavigation(false);
      getCheckReplacementData(replacementData[0]);
    } else {
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (AddReverseResp) {
      if (AddReverseResp.systemFailure) {
        seterrorMessages([FinancialReceiptConstants.PROCESSING_ERROR]);
      } else {
        props.history.push({
          pathname: "/FinancialReceiptReversal",
          state: {
            searchData: { ...AddReverseResp, entityName: payeeTab.entityName },
            fundCodeDropdown: drugRebateReasonCode.some(
              value => value.code === originalTab.financialReason
            )
              ? collationCode213DD
              : collationCodeDD,
            reasonCodeDD: financialReasCodeDD,
            values: ValuesFromFinReceipt,
payeeTypeIDDropdown: PayeeTypeIDDropdown,
            addDropdowns: addDropdowns,
            atrNumber: originalTab.atrNumber
          }
        });
      }
    }
  }, [AddReverseResp]);

  // disposition row click for edit

  const dispositionRowClick = row => {
    setSuccessMessages([]);
  };
  const [usageTypeCodeData, setUsageTypeCodeData] = React.useState([]);
  const [editNoteData, setEditNoteData] = React.useState({});
  const [notesTableData, setNotesTableData] = React.useState([]);
  let notesDataArray = [];

  const [notesInput, setNotesInput] = React.useState({
    auditUserID: loginUserID,
    auditTimeStamp: getUTCTimeStamp(),
    addedAuditUserID: loginUserID,
    addedAuditTimeStamp: getUTCTimeStamp(),
    versionNo: 0,
    dbRecord: false,
    sortColumn: null,
    tableName: null,
    noteSetSK: null,
    noteSourceName: null,
    notesList: notesTableData,
    globalNotesList: [],
    checkAll: null,
    addNewLinkRender: null,
    filterLinkRender: null,
    printLinkRender: null,
    completeNotesList: []
  });
  const [noteSetListInput, setNoteSetListInput] = React.useState({
    auditUserID: loginUserID,
    auditTimeStamp: getUTCTimeStamp(),
    addedAuditUserID: loginUserID,
    addedAuditTimeStamp: getUTCTimeStamp(),
    versionNo: 0,
    dbRecord: false,
    sortColumn: null,
    noteTextValue: null,
    userIdName: null,
    notesCexAuditUserID: null,
    notesCexAuditTimeStamp: null,
    notesCexAddedAuditUserID: null,
    notesCexAddedAuditTimeStamp: null,
    noteSetSK: null,
    usageTypeDesc: "",
    shortNotes: null,
    checked: false,
    renderNoHistoryMsg: false,
    noteSequenceNumber: null,
    currentNote: null,
    rowValue: null,
    usageTypeList: null,
    strBeginDate: moment(
      new Date(
        new Date().toLocaleString("en-US", {
          timeZone: process.env.REACT_APP_TIMEZONE
        })
      )
    ).format("MM/DD/YYYY hh:mm:ss"),
    usageTypeCode: DEFAULT_DD_VALUE,
    tableName: null,
    noteText: "",
    commonEntityName: null,
    commonEntityTypeCode: null,
    commonEntityId: null,
    entityId: null,
    filterbeginDate: moment(
      new Date(
        new Date().toLocaleString("en-US", {
          timeZone: process.env.REACT_APP_TIMEZONE
        })
      )
    ).format("YYYY-MM-DD"),
    filterEndDate: null,
    userId: loginUserID,
    noteCexVersionNum: 0,
    saNoteSequenceNumber: null,
    notesCexnoteTextValue: 0,
    id: generateUUID()
  });

  const addNotes = data => {
    if (editFlag) {
      setAllowNavigation(true);
    }
    const noteText = data;
    notesDataArray = notesTableData;
    notesDataArray.push(noteText);
    setNotesTableData(notesDataArray);
    setNotesInput({ ...notesInput, notesList: notesDataArray });
  };
  // Notes ENd
  const [disableNotes, setDisableNotes] = React.useState(true);
  const validateParentForm = () => {
    let flag = false;
    if (payeeTab.entityType === DEFAULT_DD_VALUE) {
      flag = flag || true;
    }
    if (payeeTab.entityIDTypeCode === DEFAULT_DD_VALUE) {
      flag = flag || true;
    }
    if (
      payeeTab.entityID === "" ||
      payeeTab.entityID === null ||
      payeeTab.entityID === undefined
    ) {
      flag = flag || true;
    }
    if (payeeTab.memberIDType !== DEFAULT_DD_VALUE) {
      if (
        payeeTab.memberID === undefined ||
        payeeTab.memberID === null ||
        payeeTab.memberID === ""
      ) {
        flag = flag || true;
      }
    }
    if (
      payeeTab.memberID !== "" &&
      payeeTab.memberIDType === DEFAULT_DD_VALUE
    ) {
      flag = flag || true;
    }
    return flag;
  };
  useEffect(() => {
    setDisableNotes(validateParentForm());
  }, [payeeTab]);

  const clearSuccessErrorMsgs = () => {
    seterrorMessages([]);
    setSuccessMessages([]);
  };
  // Payee Popup Implementation Starts
  const entityReset = {
    ...payeeTab,
    entityID: ''
  };
  const [systemPayeeTableData, setSystemPayeeTableData] = React.useState([]);
  const [currentSysID, setCurrentSysID] = React.useState(null);
  const [openSysPayeeTable, setOpenSysPayeeTable] = React.useState(false);
  const [canCel, setCancel] = useState(false);
  const handleCloseSysPayeeTable = e => {
    setCancel(true);
    setFocusCheck(true);
    setOpenSysPayeeTable(false);
   setPayeeTab(entityReset);
   setCurrentSysID(null);
  };
  const inputRefCursor = useRef(null);
  useEffect(() => {
    if (inputRefCursor.current && canCel) {
      inputRefCursor.current.focus();
    }
  }, [canCel]);
  const onRadioButtonClick = row => {
    setCurrentSysID(row);
  };
  const onContinue = () => {
    if (currentSysID) {
      setPayeeTab({
        ...payeeTab,
        sysID: currentSysID.sysID,
        entityName: currentSysID.payeeName,
        commonEntitySK: currentSysID.sysID
      });
      if (
        currentSysID.dispositionVOList &&
        currentSysID.dispositionVOList.length > 0
      ) {
        setReferenceDis(
          JSON.parse(JSON.stringify(currentSysID.dispositionVOList))
        );
        const tempDispoVOList = [...currentSysID.dispositionVOList];
        tempDispoVOList.map((value, index) => {
          value._recvRsnCdDescr = mapCodeDescription(
            value.recvRsnCd,
            financialReasCodeDD
          );
          value.rcLongDesc = getLongDescription(
            financialReasCodeDD,
            value.recvRsnCd
          );
        });
      }
      currentSysID.dispositionVOList.map(value => {
        if (value.recvRsnCd) {
          value._recvRsnCdDescr = mapCodeDescription(
            value.recvRsnCd,
            initialDispositionRsnCodeDD
          );
          value.rcLongDesc = getLongDescription(
            initialDispositionRsnCodeDD,
            value.recvRsnCd
          );
        }
      });
      setDispositionTabTableData(
        JSON.parse(JSON.stringify(currentSysID.dispositionVOList))
      );
      setCurrentSysID(null);
      setOpenSysPayeeTable(false);
    }
  };
  // Payee popup Implementation ends

  const ValuesFromFinReceipt = props.history && props.history.location.state && (props.history?.location?.state?.values || props.history?.state?.values);
  const PayeeTypeIDDropdown = props.history?.location?.state?.payeeTypeIDDropdown || props.history?.state?.payeeTypeIDDropdown || props.history?.state?.PayeeTypeIDDropdown;

  const rowDeleteFinReceipt = (data) => {
    setRowFinReceiptData(data);
  };

  function deleteConfirm () {
    if (rowFinReceiptData != null || rowFinReceiptData !== undefined) {
      setAllowNavigation(true);
      let updatedTableDetails = JSON.parse(JSON.stringify(historyData));
      const updatePayload = JSON.parse(
        JSON.stringify(props.location.state.payloadViewData[0])
      );

      const frontEndOnlyRecordsToDelete = historyData.filter(
        (record) => !record.isDBRecord && rowFinReceiptData.includes(record.id)
      );

      var updatedHistoryList = frontEndOnlyRecordsToDelete.map(historyList => {
        return { ...historyList, dispCanBeReversed: true };
      });

      updatedTableDetails = updatedTableDetails.filter(
        (record) => !frontEndOnlyRecordsToDelete.some((r) => r.id === record.id)
      );

      var updatedHistoryDetails = updatedTableDetails.map(historyList => {
        return { ...historyList, dispCanBeReversed: false };
      });

      updatePayload.fetchHistoryVOList = [...updatedHistoryList, ...updatedHistoryDetails];
      setUpdateData(JSON.parse(JSON.stringify(updatePayload)));

      const recordsToDelete = updatedTableDetails.filter(
        (record) => record.isDBRecord && rowFinReceiptData.includes(record.id)
      );
      if (recordsToDelete && recordsToDelete.length) {
          const databaseRecordsToDelete = recordsToDelete.filter((record) =>
            deleteDetailsState.some((deleteRecord) => deleteRecord.id === record.id)
          );
          updatedTableDetails = updatedTableDetails.filter(
            (record) => !databaseRecordsToDelete.some((r) => r.id === record.id)
          );
          setFinReceiptDeleteData(databaseRecordsToDelete);
        }

      setHistoryData(updatedTableDetails);
      setRowFinReceiptData([]);
    }
  }

  const cancelMinorSection = () => {
    setAllowNavigation(false);
    setTimeout(() => {
      setDialogOpen(false);
      setFocusCheck(false);
      props.history.push({
        pathname: '/FinancialReceiptSearch',
        tableRender: !props.history.location?.state?.isFromAdd,
        state: { values: ValuesFromFinReceipt, payeeTypeIDDropdown: PayeeTypeIDDropdown }
      });
    }, 100);
  };
  const majorCancel = () => {
    seterrorMessages([]);
    if (focusCheck) {
      setDialogOpen(true);
    } else {
      setDialogOpen(false);
      props.history.push({
        pathname: '/FinancialReceiptSearch',
        tableRender: !props.history.location?.state?.isFromAdd,
        state: { values: ValuesFromFinReceipt, payeeTypeIDDropdown: PayeeTypeIDDropdown }
      });
    }
  };

  return (
    <div className="pos-relative w-100 h-100">
      <div className="tabs-container" ref={toPrintRef}>
      <Prompt
          when={allowNavigation}
          message={location =>
            `Are you sure you want to go to ${location.pathname}`
          }
        />

        {editFlag ? (
          <div
            align="right"
            className="nav-menu"
            style={{ paddingRight: "5px" }}
          >
            {userInquiryPrivileges &&
              parseFloat(originalTab.balanceAmount) != 0 &&
              !sysParameterPayeeExist ? (
                <span>
                  <a onClick={getAdjust}>Adjustment</a>
                </span>
              ) : (
                <span>Adjustment</span>
              )}
            {userInquiryPrivileges &&
              checkReplacementRsnCodes.some(
                value => value.code === originalTab.financialReason
              ) &&
              !sysParameterPayeeExist ? (
                <span>
                  <a onClick={navigateToCheckReplacement}>
                    &nbsp;&nbsp;Check Replacement
                </a>
                </span>
              ) : (
                <span>&nbsp;&nbsp;Check Replacement</span>
              )}
            {userInquiryPrivileges &&
              !disableReverseRsnCodes.some(
                value => value.code === originalTab.financialReason
              ) &&
              parseFloat(originalTab.balanceAmount) != 0 &&
              !sysParameterPayeeExist ? (
                <span>
                  <a onClick={Reverse}>&nbsp;&nbsp;Reverse</a>
                </span>
              ) : (
                <span>&nbsp;&nbsp;Reverse</span>
              )}
            {userInquiryPrivileges &&
              (stopPaymentRsn?.findIndex(e => e.code === originalTab?.financialReason) > -1 && (parseFloat(originalTab.balanceAmount) != 0 &&
              !sysParameterPayeeExist)) ? (
                <span>
                  <a onClick={Return}>&nbsp;&nbsp;Return/Void Check</a>
                </span>
              ) : (
                <span>&nbsp;&nbsp;Return/Void Check</span>
              )}
          </div>
        ) : null}
        <ErrorMessages errorMessages={errorMessages} />
        {errorMessages.length === 0 ? (
          <SuccessMessages successMessages={successMessages} />
        ) : null}
        <div className="tab-header">
          <div className="tab-heading float-left">
            {editFlag ? "Update Receipt" : "Add Receipt"}
          </div>
          <div className="float-right mt-2">
                        <Button
              className="btn btn-success ml-1"
              onClick={receiptAddMasterSave}
              disabled={!userInquiry}
            >
              <i className="fa fa-check" aria-hidden="true"></i>
              Save
            </Button>
            {editFlag ? (
              <Button
                className={
                  auditButtonToggle
                    ? "btn btn-primary ml-1"
                    : "btn btn-text1 btn-transparent ml-1"
                }
                onClick={() => {
                  setAuditButtonToggle(!auditButtonToggle);
                  setShowLogTable(true);
                }}
              >
                <i className="fa fa-calendar-check-o" aria-hidden="true"></i>
                Audit Log
              </Button>
            ) : null}
              <Button title="Cancel" onClick={majorCancel}>Cancel</Button>
          </div>
          <div className="clearfix"></div>
        </div>
        <div className="tab-body">
          <PayeeTab
            validateMember={validateMember}
            userInquiryPrivileges={userInquiryPrivileges}
            addDropdowns={addDropdowns}
            payeeTypeDD={payeeTypeDD}
            payeeIDTypeDD={payeeIDTypeDD}
            memberIDTypeDD={memberIDTypeDD}
            payeeTab={payeeTab}
            inputRefCursor={inputRefCursor}
            payeeTabHandleChanges={payeeTabHandleChanges}
            validatePayeeBtnEvent={validatePayeeBtnEvent}
            showPayeeError={showPayeeError}
            payeeErrorMsg={payeeErrorMsg}
            editFlag={editFlag}
            sysParameterPayeeExist={sysParameterPayeeExist}
            regularPayee={regularPayee}
          />
          <div className="tab-body">
            <div className="tab-panelbody">
              <div className="tab-holder mb-3 mt-2">
                <AppBar position="static">
                  <Tabs
                    variant="fullWidth"
                    value={value}
                    onChange={handleChangeTabs}
                    aria-label="simple tabs example"
                  >
                    <Tab label="Original" classes={{ root: classes.tab100 }} />
                    {editFlag ? (
   <Tab
                      label="Disposition"
                      classes={{ root: classes.tab100 }}
                    />) : null}
                   {editFlag ? (
 <Tab
                      label="Refund/General Recovery Disposition"
                      classes={{ root: classes.tab100 }}
                    />) : null}
                     {editFlag ? (<Tab
                      label="Other/Billing Receivable Disposition"
                      classes={{ root: classes.tab100 }}
                    />) : null}
                    {editFlag ? (
                      <Tab label="History" classes={{ root: classes.tab100 }} />
                    ) : null}
                    {<Tab label="Notes" classes={{ root: classes.tab100 }} />}
                    {<Tab label="Attachments" />}
                  </Tabs>
                </AppBar>
                <TabPanel value={value} index={0}>
                  {value === 0 && (
                    <div className="tab-holder my-3 p-0">
                      <OriginalTab
                        userInquiryPrivileges={userInquiryPrivileges}
                        activityTypeCodeDD={activityTypeCodeDD}
                        financialReasCodeDD={
                          editFlag
                            ? financialReasCodeDD
                            : financialReasCodeDDAdd
                        }
                        pendingReasonDD={pendingReasonDD}
                        collationCodeDD={
                          drugRebateReasonCode.some(
                            value => value.code === originalTab.financialReason
                          )
                            ? collationCode213DD
                            : collationCodeDD
                        }
                        originalTab={originalTab}
                        originalTabHandleChanges={originalTabHandleChanges}
                        selectedDepositDate={selectedDepositDate}
                        handleDepositDateChange={handleDepositDateChange}
                        validateOriginalDetails={callValidateOriginalDetails}
                        showOriginalError={showOriginalError}
                        originalErrorMsg={originalErrorMsg}
                        editFlag={editFlag}
                        originalTabFormat={originalTabFormat}
                        removeOriginalTabFormat={removeOriginalTabFormat}
                        checkNumberPad={checkNumberPad}
                      />
                    </div>
                  )}
                </TabPanel>
                <TabPanel value={value} index={1}>
				{value === 1 && editFlag && (
                    <div className="tab-holder my-3 p-0">
                      <DispositionTab
                        userInquiryPrivileges={userInquiryPrivileges}
                        dispositoinTabTableData={dispositoinTabTableData}
                        onRowClick={dispositionRowClick}
                        originalTab={originalTab}
                        editFlag={editFlag}
                        dispositionReasonCodeDD={dispositionRsnCodeDD}
                        seterrorMessages={seterrorMessages}
                        initialDispositionRsnCodeDD={
                          initialDispositionRsnCodeDD
                        }
                        setAllowNavigation={setAllowNavigation}
                        onClickAuditDialogLog={onClickAuditDialogLog}
                        uniqueKey={"receivableFCN"}
                        setClose={setClose}
                        close={close}
                        setAuditLogRow={setAuditLogRow}
                        auditRowData={auditTableData}
                        setAuditRowData={setAuditTableData}
                        setShowLogTable={setShowLogDialogTable}
                        showLogDialogTable={showLogDialogTable}
                        setFocusCheck={setFocusCheck}
                        validValRsnCodes={validValRsnCodes}
                        crswalk_FS_9301={systemListDropDowns?.FS_9301?.filter(e => e.startingValue === originalTab.financialReason)?.map(e => e.endingValue)}
                        crswalk_FS_9302={systemListDropDowns?.FS_9302}
                      />
                    </div>
                  )}
                </TabPanel>
                <TabPanel value={value} index={2}>
				{value === 2 && editFlag && (
                    <div className="tab-holder my-3 p-0">
                      <RefundRecoveryTab
                        userInquiryPrivileges={userInquiryPrivileges}
                        atrNumber={originalTab.atrNumber || ''}
                        historyData={historyData}
                        setFocusCheck={setFocusCheck}
                        initalFocusCheck={initalFocusCheck}
                        refundRecoveryVOList={props.location.state?.payloadViewData[0]?.refundRecoveryVOList}
                        refundDispositionRsnCodeDD={refundDispositionRsnCodeDD}
                        collationCodeDD={
                          drugRebateReasonCode.some(
                            value => value.code === originalTab.financialReason
                          )
                            ? collationCode213DD
                            : collationCodeDD
                        }
                        cosDD={cosDD}
                        payeeTypeDD={payeeTypeDD}
                        refundPayeeIDTypeDD={refundPayeeIDTypeDD}
                        setRefundPayeeIDTypeDD={setRefundPayeeIDTypeDD}
                        addRefundNewFCN={addRefundNewFCN}
                        onChangePayeeType={onChangePayeeType}
                        payeeTab={payeeTab}
                        originalTab={originalTab}
                        addDropdowns={addDropdowns}
                        addRefundTabTableData={addRefundTabTableData}
                        setAddRefundTabTableData={setAddRefundTabTableData}
                        seterrorMessages={seterrorMessages}

                        generateFCN={generateFCN}
                        overPaymentsData={overPaymentsData}
                        setAllowNavigation={setAllowNavigation}
                        sysParameterPayeeExist={sysParameterPayeeExist}
                        onClickAuditDialogLog={onClickAuditDialogRefundLog}
                        uniqueKey={"reasonCode"}
                        setClose={setClose}
                        close={close}
                        validValRsnCodes={validValRsnCodes}
                        crswalk_FS_9304={systemListDropDowns?.FS_9304?.filter(e => e.startingValue === originalTab.financialReason)?.map(e => e.endingValue)}
                      />
                    </div>
                  )}
                  {showLogDialogRefundTable ? (
                    <div id="audit-refund-table">
                      <AuditTableComponent
                        print
                        auditRowData={auditRefundTableData}
                        setAuditRowData={setAuditRefundTableData}
                        setShowLogTable={setShowLogDialogRefundTable}
                        setClose={setClose}
                      />{" "}
                    </div>
                  ) : null}
                </TabPanel>
                <TabPanel value={value} index={3}>
				{value === 3 && editFlag && (
                    <div className="tab-holder my-3 p-0">
                      <OtherBillingReceivableTab
                        userInquiryPrivileges={userInquiryPrivileges}
                        otherBillingFormat={otherBillingFormat}
                        atrNumber={originalTab.atrNumber || ''}
                        removeOtherBillingFormat={removeOtherBillingFormat}
                        otherBillingTab={otherBillingTab}
                        otherBillingTabHandleChanges={
                          otherBillingTabHandleChanges
                        }
                        dispositionReasonCodeDD={otherDispositionRsnCodeDD}
                        collationCodeDD={
                          drugRebateReasonCode.some(
                            value => value.code === originalTab.financialReason
                          )
                            ? collationCode213DD
                            : collationCodeDD
                        }
                        cosDD={cosDD}
                        showOtherTabError={showOtherTabError}
                        otherTabErrMsg={otherTabErrMsg}
                        sysParameterPayeeExist={sysParameterPayeeExist}
                        seterrorMessages={seterrorMessages}
                        tabPanelValue={value}
                        validValRsnCodes={validValRsnCodes}
                        crswalkFS9303={systemListDropDowns?.FS_9303?.filter(e => e.startingValue === originalTab.financialReason)?.map(e => e.endingValue)}
                      />
                    </div>
                  )}
                </TabPanel>
                <TabPanel
                  value={value}
                  index={editFlag ? 4 : ""}
                >
                  {value === 4 && editFlag && (
                    <div className="tab-holder my-3 p-0">
                      <HistoryTab
                        userInquiryPrivileges={userInquiryPrivileges}
                        originalTab={originalTab}
                        dropdowns={addDropdowns}
                        historyData={historyData}
                        tempArray={tempArray}
                        financialReasCodeDD={financialReasCodeDD}
                        globalHistory={globalHistory}
                        onTableRowDelete={rowDeleteFinReceipt}
                        editPageFlag={editFlag}
                        deleteConfirm={deleteConfirm}
                      />
                    </div>
                  )}
                </TabPanel>
                <TabPanel
                  value={value}
                  index={editFlag ? 5 : 1}
                >
                  {value === 5 || value === 1 ? (
                    <div className="tab-holder p-0">
                      <Notes
                        addNotes={addNotes}
                        disableNotes={disableNotes}
                        notesTableData={notesTableData}
                        noteSetListInput={noteSetListInput}
                        setNoteSetListInput={setNoteSetListInput}
                        usageTypeCodeData={usageTypeCodeData}
                        editNoteData={editNoteData}
                        setEditNoteData={setEditNoteData}
                        setSuccessMessages={clearSuccessErrorMsgs}
                        isNotesTable={true}
                        setFocusCheck={setFocusCheck}
                        setErrorMessages= {seterrorMessages}
                        userInquiryPrivileges = {userInquiry}
                      />
                    </div>
                  ) : null}
                </TabPanel>
                {
                  <TabPanel
                    value={value}
                    index={editFlag ? 6 : 2}
                  >
                    <div className="tab-holder p-0">
                      <Attachments
                        disableAttachments={disableNotes}
                        userInquiryPrivileges={userInquiry}
                        attachmentFile={attachmentFile}
                        setAttachmentFile={setAttachmentFile}
                        attachmentsTableData={attachmentsTableData}
                        setAttachmentsTableData={setAttachmentsTableData}
                        parentErrorMesage={seterrorMessages}
                        setAllowNavigation={setAllowNavigation}
                        setSystemSuccesMessages={clearSuccessErrorMsgs}
                        setFocusCheck={setFocusCheck}
                      />
                    </div>
                  </TabPanel>
                }

                {showLogTable ? (
                  <div id="audit-table">
                    <AuditTableComponent
                      print
                      auditRowData={auditRowData}
                      setAuditRowData={setAuditRowData}
                      setShowLogTable={setShowLogTable}
                    />{" "}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <Footer print />
        <Dialog
              open={dialogOpen}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              className="custom-alert-box"
      >
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <>
                    Unsaved changes will be lost. <br />
                    Are you sure you want to continue?
                  </>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
              setDialogOpen(false);
            }}
			                 color="primary"
                  className="btn btn-transparent"
          >
                  STAY ON THIS PAGE!
                </Button>
                <Button
                  onClick={cancelMinorSection}
                  color="primary"
                  className="btn btn-success"
                  autoFocus
          >
                  CONTINUE <i className="fa fa-arrow-right ml-1" />
                </Button>
              </DialogActions>
            </Dialog>
      </div>
      {validatePayeeOnBlur ? (
        <SystemPayeeTable
          payeeType={payeeTypeDesc}
          payeeIdTypeCode={payeeTypeIdCodeDesc}
          payeeId={payeeTab.entityID}
          openSysPayeeTable={openSysPayeeTable}
          handleCloseSysPayeeTable={handleCloseSysPayeeTable}
          uniqueKey={"sysID"}
          onTableCellButtonClick={onRadioButtonClick}
          tableData={systemPayeeTableData}
          currentSysID={currentSysID}
          onContinue={onContinue}
        />
      ) : null}
    </div>
  );
}
