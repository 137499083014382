/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

export const PLEASE_SELECT_ONE = 'Please Select One';
export const CANCEL = 'Cancel';
export const NUMBER_ONLY_REGEX = /[a-zA-Z!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~ ]/;
export const CHARACTERS_NONNUMERIC_REGEX = /[0-9]/;
export const AMOUNT = /^[-]?[0-9]{0,9}\.?[0-9]{0,2}$/;
export const DATE_ERROR_1964 = 'Date must be greater than or equal to 01/01/1964.';
export const SAVE = 'Save';
export const PRINT = 'Print';
export const HELP = 'Help';
export const NOTES = 'Notes';
export const LOB = 'LOB';
export const PAYEE_TYPE = 'Payee Type';
export const PAYEE_ID_TYPE_CODE = 'Payee ID Type Code';
export const PAYEE_ID = 'Payee ID';
export const PAYEE_NAME = 'Payee Name';
export const SYSTEM_PAYEE_ID = 'System Payee ID';
export const ACTIVITY_TYPE = 'Activity Type';
export const PAYMENT_SCHEDULE = 'Payment Schedule';
export const HISTORY = 'History';
export const ATTACHMENT = 'Attachment';
export const REASON_CODE = 'Reason Code';
export const SAVE_SUCCESS = 'System successfully saved the Information.';
export const ERR_PROCESSING_REQ = 'There was an error processing the request. Please retry the transaction.';
export const MEMBER_ID_TYPE = 'Member ID Type';
export const MEMBER_ID = 'Member ID';
export const MEMBER_NAME = 'Member Name';
export const PAYEE_NAME_NOT_FOUND = 'Payee name not found.';
export const INCORRECT_DATA_FORMAT = 'Data entered is in the incorrect format.';
export const DEFAULT_DATE_ERROR = 'Default Date can not be prior to todays date.';
export const INCORRECT_FORMAT = 'Please enter the data in correct format.';
export const INVALID_ADDRESS = 'Invalid Service Location Address.';
export const VERIFIED_ADDRESS = 'Please verify or Enter a valid Address.';
export const DASHBOARD_HEADER_TEXT = 'Conduent Medicaid Suite';
export const SESSION_EXPIRY_MSG = "Your session is about to expire. Do you want to continue with this session?";
export const ANIMATE_TITLE = "Session About To Expire!";
export const FINANCIAL = 'Financial';
export const CORRECTION_PENDING_APPROVAL_INDICATOR ='P';
export const Default_Establishment_ThruDate='12/31/9999';
export const CORRECTION_YES_APPROVAL_INDICATOR = 'Yes';
export const CORRECTION_NO_APPROVAL_INDICATOR = 'No';
export const CORRECTION_Y_IND='Y';
export const CORRECTION_N_IND='N';
export const DEFAULT_LOB_VALUE = 'MED';
export const PAYOUT_APPROVAL_STATUS_YES = 'Yes';
export const PAYOUT_PAYMENT_STATUS_PAID = 'P';
export const ACCOUNT_NAME_MAX_LENGTH = '16';
export const ACCOUNT_NUMBER_MAX_LENGTH = '17';
export const BANK_NAME_MAX_LENGTH = '23';
export const ROUTING_NUMBER_MAX_LENGTH = '9';
export const ENTITY_TYPE_O = 'O';
export const ENTITY_TYPE_OTHER= 'O-Other'
export const CONSIDER_USPS_NO="N";
export const DATE_FORMAT = "MM/DD/YYYY";
export const MAX_DATE = "31 Dec 9999 00:00:00 GMT";
export const MAX_DATE_FORMAT = "12/31/9999";
export const CHECK_DATE = 'checkDate';
export const ADJUSTMENT_REASON_CODE = 'adjusmentReasonCode';
export const RECOUPMENTTYPECODE_DOLLARAMT="A"
export const RECOUPMENTTYPECODE_PERCENTAMT="B"