/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { Component, useEffect } from 'react';
import { useSelector } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import '../../AccountReceivables.scss';
import { withRouter } from 'react-router';
import * as AppConstants from '../../../../SharedModules/AppConstants';
import DateFnsUtils from '@date-io/date-fns';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import numeral from 'numeral';
import InputAdornment from '@material-ui/core/InputAdornment';
import { CLAIMS_INQUIRY_TCN_LINK } from '../../../../SharedModules/services/service';
import { getLongDescription } from '../../../../SharedModules/CommonUtilities/commonUtilities'
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import { Link } from 'react-router-dom';
import NativeDropDown from '../../../../SharedModules/Dropdowns/NativeDropDown';

/* ClaimReceivableTabDetail()
 * This component is used to display Claims Receivable details (inside first tab in claims receivables screen)
 * Inside this component we are getting values using props sent by ClaimsReceivable.js
 */
function ClaimReceivableTabDetail(props) {
  const DEFAULT_DD_VALUE = process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE;
  const userInquiryPrivileges = !global.globalIsReadOnly();
  return (
    <div>
      <form noValidate autoComplete="off">
        <div className="form-wrapper">
          <div className="mui-custom-form input-md">
            <TextField
              id="fcn"
              fullWidth
              disabled={true}
              label="FCN"
              InputLabelProps={{
                shrink: true
              }}
              value={props.values.fcn}
            />
          </div>
          <div className="mui-custom-form input-md">
            <TextField
              id="establishedDate"
              fullWidth
              label="Established Date"
              disabled
              InputLabelProps={{
                shrink: true
              }}
              value={props.values.establishedDate}
            />
          </div>
          <div className="mui-custom-form input-md">
            <TextField
              id="lastDate"
              fullWidth
              label="Last Date Applied"
              disabled
              InputLabelProps={{
                shrink: true
              }}
              value={props.values.lastDate}
            />
          </div>
          <div className="mui-custom-form input-md">
            <TextField
              id="arLetter"
              fullWidth
              label="A/R Letter Sent"
              disabled
              InputLabelProps={{
                shrink: true
              }}
              value={props.values.arLetterDesc ? props.values.arLetterDesc : props.values.arLetter}
            />
          </div>
        </div>

        <div className="form-wrapper">
          <div className="mui-custom-form input-md">
            <TextField
              id="arInDays"
              fullWidth
              label="A/R Age in Days"
              disabled
              InputLabelProps={{
                shrink: true
              }}
              value={props.values.arInDays}
            />
          </div>
          <div className="mui-custom-form input-md">
            <TextField
              id="originalAmount"
              fullWidth
              label="Original A/R Amount"
              InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment>, readOnly: true, className: 'Mui-disabled' }}
              InputLabelProps={{
                shrink: true
              }}
              value={numeral(props.values.originalAmount).format('0,0.00')}
            />
          </div>
          <div className="mui-custom-form input-md">
            <TextField
              id="adjustedAmount"
              fullWidth
              label="Adjusted A/R Amount"
              InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment>, readOnly: true, className: 'Mui-disabled' }}
              InputLabelProps={{
                shrink: true
              }}
              value={numeral(props.values.adjustedAmount).format('0,0.00')}
            />
          </div>
          <div className="mui-custom-form input-md">
            <TextField
              id="balanceAmount"
              fullWidth
              label="Balance Amount"
              InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment>, readOnly: true, className: 'Mui-disabled' }}
              InputLabelProps={{
                shrink: true
              }}
              value={numeral(props.values.balanceAmount).format('0,0.00')}
            />
          </div>
        </div>
        <div className="form-wrapper">
          <div className="mui-custom-form input-md has-link">
            <TextField
              id="ftTcn"
              fullWidth
              label="Related FT TCN"
              disabled
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <a href={CLAIMS_INQUIRY_TCN_LINK + props.values.ftTcn} target='_blank'>
                      {props.values.ftTcn}
                    </a>
                  </InputAdornment>
                )
              }}
            />
          </div>
          <NativeDropDown
            id="payeeStatus"
              label="Payee Status"
            value={props.values.payeeStatus || DEFAULT_DD_VALUE}
            onChange={props.handleChange('payeeStatus')}
              title={getLongDescription(props.payeePaymentStatusFrmState, props.values.payeeStatus)}
            dropdownList={props.payeePaymentStatusFrmState
                ? props.payeePaymentStatusFrmState.map((item, index) => (
                  <option key={index} value={item.code}>
                    {item.description}
                  </option>
                ))
                : null}
          />
  
        </div>
        <div className="tab-holder">
          <div className="row mb-2">
            <div className="col-5 mb-3" style={{ maxWidth: '398px' }}>
              <fieldset className="custom-fieldset">
                <legend>Account:</legend>
                <div className="form-wrapper vertical-wrapper">
                  <div className="mui-custom-form min-md">
                    <TextField
                      fullWidth
                      id="standard-fiscal-year"
                      label="Fiscal Year"
                      placeholder=""
                      inputProps={{ maxlength: 4 }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      type="number"
                      value={props.values.fiscalYear}
                      onChange={props.handleChange('fiscalYear')}
                      helperText={props.errors[5] ? props.errorText[5] : null}
                      error={props.errors[5] ? props.errorText[5] : null}
                      onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 4);
                      }}
                      disabled
                      onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
                    >
                    </TextField>
                  </div>
                  <NativeDropDown
                    className='min-md'
                    id="Category_of_Service"
                    _ label="Category of Service"
                      value={props.values.categoryOfService}
                    onChange={props.handleChange('categoryOfService')}
                    inputProps={{ maxlength: 3 }}
                      disabled
                      title={getLongDescription(props.COSData, props.values.categoryOfService)}
                    dropdownList={props.COSData ? props.COSData.map((option, index) => (
                      <option key={index} value={option.code}>
                          {option.description}
                      </option>
                      )) : null}

                  />
                  <NativeDropDown
                    className='min-md'
                    id="Fund_code"
                    label="Fund Code"
                      value={props.values.collocationCode}
                    onChange={props.handleChange('collocationCode')}
                      inputProps={{ maxlength: 10 }}
                    errTxt={props.errors[6] ? props.errorText[6] : null}
                      disabled
                      title={getLongDescription(props.fundCodeData, props.values.collocationCode)}
                    dropdownList={props.fundCodeData ? props.fundCodeData.map((option, index) => (
                      <option key={index} value={option.code}>
                          {option.description}
                      </option>
                      )) : null}

                  />
                </div>
              </fieldset>
            </div>
            <div className="col-7 mb-3" style={{ paddingLeft: '50px' }}>
              <fieldset className="r-custom-fieldset no-padding ">
                <legend>Recoupment:</legend>
                <div className="form-wrapper">
                  <NativeDropDown
                      id="recoupmentCode"
                    className='min-md'
                      disabled={!userInquiryPrivileges ? !userInquiryPrivileges : (!!props.recoupmentDisabled)}
                    isRequired
                      label="Recoupment Type Code"
                      value={props.values.recoupmentCode}
                      onChange={props.handleChange('recoupmentCode')}
                    errTxt={props.errors[0] ? props.errorText[0] : null}
                    dropdownList={props.recoupmentCodeData.map((item, index) => (
                      <option key={index} value={item.code}>
                          {item.description}
                      </option>
                      ))}
                  />

                  <div className="mui-custom-form min-md">
                    <TextField
                      required={props.values.recoupmentCode === 'A'}
                      fullWidth
                      id="installmentValue"
                      label="Installment Value"
                      inputProps={{ maxlength: 14 }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                      }}
                      value={props.values.installmentValue}
                      onChange={props.handleChange('installmentValue')}
                      onBlur={props.formatDecimalField('installmentValue')}
                      onFocus={props.removeFormatDecimalFieldInstallment('installmentValue')}
                      disabled={!userInquiryPrivileges ? !userInquiryPrivileges : !!props.disabled}
                      helperText={props.errors[1] ? props.errorText[1] : null}
                      error={props.errors[1] ? props.errorText[1] : null}
                      onInput={e => {
                        e.target.value = e.target.value.replace(
                          /[a-zA-Z!@#$%^&*()_+=[\]{};':"\\|<>/?~ ]/,
                          ''
                        );
                      }}
                    >
                    </TextField>
                  </div>
                </div>
                <div className="form-wrapper">
                  <div className="mui-custom-form min-md">
                    <TextField
                      id="percentValue"
                      fullWidth
                      required={props.values.recoupmentCode === 'B' || props.values.recoupmentCode === 'D'}
                      label="Percent Value"
                      inputProps={{ maxlength: 14 }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>
                      }}
                      value={props.values.percentValue}
                      onChange={props.handleChange('percentValue')}
                      disabled={!userInquiryPrivileges ? !userInquiryPrivileges : !!props.disabled}
                      helperText={props.errors[2] ? props.errorText[2] : null}
                      error={props.errors[2] ? props.errorText[2] : null}
                      onBlur={props.formatPercent('percentValue')}
                      onInput={e => {
                        e.target.value = e.target.value.replace(
                          /[a-zA-Z!@#$%^&*()_+=[\]{};':"\\|,<>/?~ ]/,
                          ''
                        );
                      }}
                    >
                    </TextField>
                  </div>

                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <div className="mui-custom-form min-md input-date with-date">
                      <KeyboardDatePicker
                        maxDate={Date.parse('31 Dec 9999 00:00:00 GMT')}
                        id="startDate"
                        label="Start Date"
                        format="MM/dd/yyyy"
                        InputLabelProps={{
                          shrink: true
                        }}
                        disabled={!userInquiryPrivileges ? !userInquiryPrivileges : !!props.disabled}
                        placeholder="mm/dd/yyyy"
                        value={props.selectedStartDate}
                        onChange={props.handleStartDateChange}
                        onKeyUp={props.handleStartDateText}
                        helperText={props.errors[3] ? props.errorText[3] : null}
                        error={props.errors[3] ? props.errorText[3] : null}
                        KeyboardButtonProps={{
                          'aria-label': 'change date'
                        }}
                        required={props.values.recoupmentCode === 'B' || props.values.recoupmentCode === 'A' || props.values.recoupmentCode === 'D'}
                      />
                    </div>
                  </MuiPickersUtilsProvider>
                </div>
                <div className="form-wrapper">
                  <NativeDropDown
                    isRequired={props.values.recoupmentCode === AppConstants.RECOUPMENTTYPECODE_PERCENTAMT || props.values.recoupmentCode === AppConstants.RECOUPMENTTYPECODE_DOLLARAMT}
                      id="frequency"
                    className='min-md'
                      disabled={!userInquiryPrivileges ? !userInquiryPrivileges : !!props.disabled}
                      label="Frequency"
                      value={props.values.frequency}
                      onChange={props.handleChange('frequency')}
                    errTxt={props.errors[4] ? props.errorText[4] : null}
                    dropdownList={props.frequencyDataDropdown.map((item, index) => (
                      <option key={index} value={item.code}>
                          {item.description}
                      </option>
                      ))}
                  />
                  <div className="form-wrapper">
                    <div
                      className="mui-custom-form with-select min-md override-width-61" style={{ alignSelf: 'flex-end' }}
                    >
                      {userInquiryPrivileges ? <a onClick={props.defaultRecoupment} style={{ color: 'blue', cursor: 'pointer' }}>
                        Use Payee Default Recoupment
                </a> : <span>Use Payee Default Recoupment</span>
                      }
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
          </div>
        </div>
        <div className="col-12" style={{ paddingRight: '19px' }}>
          <fieldset className="custom-fieldset">
            <legend>Funding Source Allocation:</legend>
            <div className="form-wrapper">

              <div className="mui-custom-form">
                <TextField
                  id="standard-federal"
                  fullWidth
                  label="Federal"
                  placeholder=""
                  value={props.values.federal}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    readOnly: true,
                    className: 'Mui-disabled'
                  }}
                  inputProps={{ maxlength: 14 }}
                  InputLabelProps={{
                    shrink: true
                  }}
                >
                </TextField>
              </div>
              <div className="mui-custom-form">
                <TextField
                  id="standard-state"
                  fullWidth
                  label="State"
                  placeholder=""
                  value={props.values.state}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    readOnly: true,
                    className: 'Mui-disabled'
                  }}
                  inputProps={{ maxlength: 14 }}
                  InputLabelProps={{
                    shrink: true
                  }}
                >
                </TextField>
              </div>
              <div className="mui-custom-form">
                <TextField
                  id="standard-county"
                  fullWidth
                  label="County"
                  placeholder=""
                  value={props.values.county}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    readOnly: true,
                    className: 'Mui-disabled'
                  }}
                  inputProps={{ maxlength: 14 }}
                  InputLabelProps={{
                    shrink: true
                  }}
                >
                </TextField>
              </div>
              <div className="mui-custom-form">
                <TextField
                  id="standard-other"
                  fullWidth
                  label="Other"
                  placeholder=""
                  value={props.values.other}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    readOnly: true,
                    className: 'Mui-disabled'
                  }}
                  inputProps={{ maxlength: 14 }}
                  InputLabelProps={{
                    shrink: true
                  }}
                >
                </TextField>
              </div>
            </div>
          </fieldset>
        </div>
      </form>
    </div>
  );
}

export default withRouter((ClaimReceivableTabDetail));
