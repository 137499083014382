/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect, useRef } from "react";
import DialogContentText from '@material-ui/core/DialogContentText';
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { Button } from "react-bootstrap";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import InputAdornment from "@material-ui/core/InputAdornment";
import Attachments from "../../../../../../../SharedModules/Attachments/Attachments";
import Radio from "@material-ui/core/Radio";
import { Link, Prompt } from "react-router-dom";
import { redirectSystemID } from "../../../../../../../SharedModules/TabRedirections/TabRedirections";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import numeral from "numeral";
import DateFnsUtils from "@date-io/date-fns";
import * as ErrorConstants from "../../ErrorCheck";
import LienLevyDetailsComponent from "./LienLevyDetailsComponent";
import LienLevyHistoryTableComponent from "./LienLevyHistoryComponent";
import ErrorMessages from "../../../../../../../SharedModules/Errors/ErrorMessages";
import PaymentScheduleTable from "./paymentScheduleTable";
import moment from "moment";
import * as commonMethods from "../../../../../../../SharedModules/CommonUtilities/commonUtilities";
import { getLongDescription } from "../../../../../../../SharedModules/CommonUtilities/commonUtilities";
import {
  getDateInMMDDYYYYFormat,
  getUTCTimeStamp,
  generateUUID,
  validateDateMinimumValue,
  compareTwoDates,
  compareTwoDatesGreaterThanOrEqual,
  getDateInMMDDYYYYFormatWithApendZero
} from "../../../../../../../SharedModules/DateUtilities/DateUtilities";
import {
  getDropdownDataAction,
  getPayeeIdType,
  addLienLevyAdd,
  lienLevyHolderIdTypeDropDownAction,
  searchFCNAccountReceivableAction,
  getDefaultRecoupmentDetails,
  validateLienHolderDetails
} from "../../../../../Store/Actions/accountReceivable/accountReceivableActions";
import { useDispatch, useSelector } from "react-redux";
import SuccessMessages from "../../../../../../../SharedModules/Errors/SuccessMessage";
import TabPanel from "../../../../../../../SharedModules/TabPanel/TabPanel";
import * as AppConstants from "../../../../../../../SharedModules/AppConstants";
import dropdownCriteria from "./LienLevyAdd.json";
import {
  getSystemParameterSpecificValue,
  getMaxFrequencyParameter
} from "../../../../../../../SharedModules/store/Actions/SharedAction";
import Footer from "../../../../../../../SharedModules/Layout/footer";
import Notes from "../../../../../../../SharedModules/Notes/Notes";
import { searchSystemParameterAction } from "../../../../../../ApplicationConfiguration/Store/Actions/systemParameter/systemParameterActions";
import SystemPayeeTable from "../../../../../../../SharedModules/SystemIDTable/SystemPayeeIDTable";
import NativeDropDown from "../../../../../../../SharedModules/Dropdowns/NativeDropDown";

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

export default function FinancialAccountLienLevy (props) {
  const DEFAULT_DD_VALUE = process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE;
  const logInUserID = useSelector(state => state.sharedState.logInUserID);
  // Notes Integration Begin

  const userInquiryPrivileges = !global.globalIsReadOnly();
  const [notesTableData, setNotesTableData] = React.useState([]);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [focusCheck, setFocusCheck] = React.useState(false);
  const [withHoldingStatus, setWithHoldingStatus] = React.useState(false);
  const [notesInput, setNotesInput] = React.useState({
    auditUserID: logInUserID,
    auditTimeStamp: getUTCTimeStamp(),
    addedAuditUserID: logInUserID,
    addedAuditTimeStamp: getUTCTimeStamp(),
    versionNo: 0,
    dbRecord: false,
    sortColumn: null,
    tableName: null,
    noteSetSK: null,
    noteSourceName: null,
    notesList: notesTableData,
    globalNotesList: [],
    checkAll: null,
    addNewLinkRender: null,
    filterLinkRender: null,
    printLinkRender: null,
    completeNotesList: []
  });
  const [noteSetListInput, setNoteSetListInput] = React.useState({
    auditUserID: logInUserID,
    auditTimeStamp: getUTCTimeStamp(),
    addedAuditUserID: logInUserID,
    addedAuditTimeStamp: getUTCTimeStamp(),
    versionNo: 0,
    dbRecord: false,
    sortColumn: null,
    noteTextValue: null,
    userIdName: null,
    notesCexAuditUserID: null,
    notesCexAuditTimeStamp: null,
    notesCexAddedAuditUserID: null,
    notesCexAddedAuditTimeStamp: null,
    noteSetSK: null,
    usageTypeDesc: "",
    shortNotes: null,
    checked: false,
    renderNoHistoryMsg: false,
    noteSequenceNumber: null,
    currentNote: null,
    rowValue: null,
    usageTypeList: null,
    strBeginDate: moment(
      new Date(
        new Date().toLocaleString("en-US", {
          timeZone: process.env.REACT_APP_TIMEZONE
        })
      )
    ).format("MM/DD/YYYY hh:mm:ss"),
    usageTypeCode: DEFAULT_DD_VALUE,
    tableName: null,
    noteText: "",
    commonEntityName: null,
    commonEntityTypeCode: null,
    commonEntityId: null,
    entityId: null,
    filterbeginDate: moment(
      new Date(
        new Date().toLocaleString("en-US", {
          timeZone: process.env.REACT_APP_TIMEZONE
        })
      )
    ).format("YYYY-MM-DD"),
    filterEndDate: null,
    userId: logInUserID,
    noteCexVersionNum: 0,
    saNoteSequenceNumber: null,
    notesCexnoteTextValue: 0,
    id: generateUUID()
  });
  const [usageTypeCodeData, setUsageTypeCodeData] = React.useState([]);
  const [payeePaymentStatusFrmState, setPayeePaymentStatusFrmState] = React.useState([]);
  const [approvalData, setApprovalData] = React.useState([]);
  const [editNoteData, setEditNoteData] = React.useState({});
  const [IsF16007, setIsF16007] = React.useState(false);
  // Notes Integration End
  const [color, setColor] = React.useState("");

  const [rowPaymentScheduleData, setRowPaymentScheduleData] = React.useState(
    []
  );
  const [payeeTypeData, setPayeeTypeData] = React.useState([]);
  const [activityTypeData, setActivityTypeData] = React.useState([]);
  const [reasonCodeData, setReasonCodeData] = React.useState([]);
  const [payeeTypeIdCodeData, setPayeeTypeIdCodeData] = React.useState([]);
  let payload = [];

  const dispatch = useDispatch();

  const [recoupmentTypeClicked, setRecoupmentTypeClicked] = React.useState(false);
  const [attachmentsTableData, setAttachmentsTableData] = React.useState([]);
  const [attachmentFile, setAttachmentFile] = React.useState([]);
  const [systemSuccessMessages, setSystemSuccesMessages] = React.useState([]);
  const [lienHolderTypeData, setLienHolderTypeData] = React.useState([]);
  const [lienHolderIdTypeData, setLienHolderIdTypeData] = React.useState([]);
  const [dropDownCheckList, setDropDownCheckList] = React.useState([]);
  const [values, setValues] = React.useState({
    originalARAmount: "",
    maxWithholdAmount: "",
    approval: "N",
    underAppeal: "N",
    fiscalYear: "",
    categoryOfService: DEFAULT_DD_VALUE,
    payeeStatus: DEFAULT_DD_VALUE,
    collocationCode: DEFAULT_DD_VALUE,
    recoupmentTypeCode: DEFAULT_DD_VALUE,
    installmentValue: "",
    percentValue: "",
    frequency: DEFAULT_DD_VALUE,
    exemptFromPenalty: "N",
    exemptFromInterest: "N",
    exemptFromCollectionAgency: "N",
    amountDue: "",
    defaultOnPaymentSchedule: "N",
    payeeType: DEFAULT_DD_VALUE,
    payeeIdTypeCode: DEFAULT_DD_VALUE,
    reasonCode: DEFAULT_DD_VALUE,
    activityCode: DEFAULT_DD_VALUE,
    systemPayeeId: "",
    payeeId: "",
    payeePayerName: "",
    fcn: "",
    adjustedARAmount: "",
    balanceAmount: "",
    relatedFTTCN: "",
    establishedDate: "",
    lastDateApplied: "",
    arAgeInDays: "",
    arLettersent: "",
    categoryOfServiceData: [],
    collocationCodeData: [],
    calculatedInterest: "",
    calculatedPenalty: "",
    feesAssesed: "",
    frequencyData: [],
    recoupmentData: [],
    originalARAmountData: 0,
    appliedAmountData: 0,
    balanceAmountData: 0,
    lienHolderTypeData: [],
    lienHolderType: DEFAULT_DD_VALUE,
    lienHolderIdTypeData: lienHolderIdTypeData || [],
    lienHolderIdType: DEFAULT_DD_VALUE,
    lienHolderName: "",
    lienHolderId: "",
    paymentScheduleType: DEFAULT_DD_VALUE,
    recoupmentStartDate: null,
    numberofInstallments: "",
    interestCalculation: "N",
    interestPercentage: "",
    paymentScheduleFrequency: DEFAULT_DD_VALUE,
    recoupedAmount: "0.00",
    installmentAmount: "",
    thirdPartyIDFromDB: "",
    atrNumber: ''
  });
  const toPrintRef = useRef();
  const [frequencyDataDropdown, setFrequencyDataDropdown] = React.useState([]);
  const [
    selectedRecoupmentStartDate,
    setSelectedRecoupmentStartDate
  ] = React.useState(null);
  const [retainSelectedDueDate, setRetainSelectedDueDate] = React.useState(
    null
  );
  const [paymentScheduleVO, setPaymentScheduleVO] = React.useState({
    installmentAmount: "",
    recoupmentAmount: "0.00"
  });
  const [scheduleTable, setScheduleTable] = React.useState(true);
  const [
    paymentScheduleTypeError,
    setPaymentScheduleTypeError
  ] = React.useState("");
  const [
    recoupmentStartDateError,
    setRecoupmentStartDateError
  ] = React.useState("");
  const [
    numberofInstallmentError,
    setNumberofinstallmentError
  ] = React.useState("");
  const [frequencyError, setFrequencyError] = React.useState("");
  const [interestCalError, setInstallmentCalError] = React.useState("");
  const [retainPaymentSchedule, setRetainPaymentSchedule] = React.useState({});
  const [paymentScheduleTypeData, setPaymentScheduleTypeData] = React.useState(
    []
  );
  const [frequencyRecoupData, setFrequencyRecoupData] = React.useState([]);
  const [selectedBeginDate, setSelectedBeginDate] = React.useState(null);
  const [selectedEndDate, setSelectedEndDate] = React.useState(null);
  const [selectedRecoupmentDate, setSelectedRecoupmentDate] = React.useState(
    null
  );
  const [selectedDueDate, setSelectedDueDate] = React.useState(null);
  const [selectedDefaultDate, setSelectedDefaultDate] = React.useState(null);
  const [paymentScheduleData, setPaymentSchedleData] = React.useState([]);
  const [lienLevy, setLienLevy] = React.useState({});
  const [retainValues, setRetainValues] = React.useState({});
  const [allowNavigation, setAllowNavigation] = React.useState(true);
  const [
    selectedInitialPaymentDate,
    setSelectedInitialPaymentDate
  ] = React.useState(null);
  const [selectedSatisfiedDate, setSelectedSatisfiedDate] = React.useState(
    null
  );
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(false);

  const errorMessagesArray = [];
  const popUpErrorMessagesArray = [];
  const [errorMessages, seterrorMessages] = React.useState([]);
  const [
    {
      showOriginalArAmmountError,
      showFiscalYearError,
      showLienHolderTypeError,
      showLienHolderTypeCodeError,
      showLienHolderIDError,
      showCollocationCodeError,
      showRecoupmentTypeCodeError,
      showDueDateError,
      showAmountDueError,
      showInvalidBeginDateError,
      showInvalidEndDateError,
      showEndDateLesserError,
      showBeginDateRequiredError,
      showEndDateRequiredError,
      showPercentError,
      showInstallementError,
      showStartDateRequiredError,
      showDueDateGreaterError,
      showMinDueDateError,
      showDueDateInvalidError,
      showNegativeAmountError,
      showGreaterThanZeroAmountError,
      showOriginalArAmountPopError,
      showFrequencyError,
      showATRError
    },
    setShowError
  ] = React.useState(false);
  const [
    {
      showOriginalArAmountErrorText,
      showFiscalYearErrorText,
      showLienHolderTypeErrorText,
      showLienHolderTypeCodeErrorText,
      showLienHolderIDText,
      showCollocationCodeErrorText,
      showRecoupmentTypeCodeErrorText,
      showDueDateErrorText,
      showAmountDueErrorText,
      showInvalidBeginDateText,
      showInvalidEndDateInvalidText,
      showEndDateLesserErrorText,
      showBeginDateRequiredErrorText,
      showEndDateRequiredErrorText,
      showPercentErrorText,
      showInstallmentErrorText,
      showStartDateRequiredErrorText,
      showDueDateGreaterErrorText,
      showMinDueDateErrorText,
      showDueDateInvalidErrorText,
      showNegativeAmmountErrorText,
      showGreaterThanZeroAmountText,
      showOriginalArAmountPopErrorText,
      showFrequencyErrorText,
      showATRErrorText
    },
    setShowErrorText
  ] = React.useState("");

  const [popupErrorMessages, setPopUpErrorMessage] = React.useState([]);

  const [tabOne, setTabOne] = React.useState(true);
  const [tabTwo, setTabTwo] = React.useState(false);
  const [tabThree, setTabThree] = React.useState(false);
  const [tabFour, setTabFour] = React.useState(false);
  const [add, setAdd] = React.useState(true);
  const [retainEdit, setRetainEdit] = React.useState({});
  const [editData, setEditData] = React.useState({});
  const [paymentScheduleChanged, setPaymentScheduleChanged] = React.useState(
    false
  );
  const [payeeTypeDesc, setPayeeTypeDesc] = React.useState("");
  const [payeeTypeIdCodeDesc, setPayeeTypeIdCodeDesc] = React.useState("");
  const [systemPayeeTableData, setSystemPayeeTableData] = React.useState([]);
  const [currentSysID, setCurrentSysID] = React.useState(null);
  const [openSysPayeeTable, setOpenSysPayeeTable] = React.useState(false);
  const [canCel, setCancel] = React.useState(false);
  // add notes
  let notesDataArray = [];

  const addNotes = data => {
    setAllowNavigation(true);
    const noteText = data;
    notesDataArray = notesTableData;

    notesDataArray.push(noteText);
    setNotesTableData(notesDataArray);
    setNotesInput({ ...notesInput, notesList: notesDataArray });
  };

  // clear out success/error msgs on click of add notes
  const clearSuccessErrorMsgs = () => {
    setSystemSuccesMessages([]);
    seterrorMessages([]);
    setShowErrorText("");
    setShowError([]);
  };

  const onValidateLienHolderDetails = value =>
    dispatch(validateLienHolderDetails(value));
  const onValidateLienHolderResponse = useSelector(
    state => state.accountReceivableState.lienHolderDetails
  );

  const getDropdownData = dropdownValues =>
    dispatch(getDropdownDataAction(dropdownValues));

  const onPayeeTypeIdDropDown = value => dispatch(getPayeeIdType(value));
  const onLienLevyAdd = value => {
    dispatch(
      addLienLevyAdd(
        value,
        attachmentsTableData.length > 0 ? attachmentFile : null
      )
    );
  };
  const onLienLevyHolderIdTypeDropDown = value =>
    dispatch(lienLevyHolderIdTypeDropDownAction(value));
  // Search the newly add record for redirecting to Edit screen
  const onSearch = fcn => dispatch(searchFCNAccountReceivableAction(fcn));
  payload = useSelector(state => state.accountReceivableState.payload);
  const onGetRecoupmetDetails = values => {
    dispatch(getDefaultRecoupmentDetails(values));
  };
  const getRecoupmetDetails = useSelector(
    state => state.accountReceivableState.fetchRecoupmetDetails
  );

  let dropdown = [];

  useEffect(() => {
    getDropdownData(dropdownCriteria);
    getPayeeIdFromSystemParameter({
      paramNumber: 9025,
      functionalArea: "F1",
      lobCode: AppConstants.DEFAULT_LOB_VALUE,
      asOfDate: moment(
        new Date(
          new Date().toLocaleString("en-US", {
            timeZone: process.env.REACT_APP_TIMEZONE
          })
        )
      ).format("YYYY-DD-MM")
    });
  }, []);

  dropdown = useSelector(state => state.accountReceivableState.dropdownData);
  const addLienLevyAddResponse = useSelector(
    state => state.accountReceivableState.lienLevyAdd
  );
  const sucessMessages = [];
  const systemErrorMessges = [];
  let lienLevyHolderIdTypeDropDown = [];
  lienLevyHolderIdTypeDropDown = useSelector(
    state => state.accountReceivableState.lienLevyHolderIdType
  );
  useEffect(() => {
    if (lienLevyHolderIdTypeDropDown) {
      setLienHolderIdTypeData(lienLevyHolderIdTypeDropDown);
    }
  }, [lienLevyHolderIdTypeDropDown]);

  useEffect(() => {
    if (
      addLienLevyAddResponse &&
      !addLienLevyAddResponse.systemFailure &&
      addLienLevyAddResponse.respcode === "03" &&
      addLienLevyAddResponse.respdesc === ""
    ) {
      const error = ErrorConstants.GENERIC_SYSTEM_ERROR;
      setSystemSuccesMessages([]);
      systemErrorMessges.push(error);
      seterrorMessages(systemErrorMessges);
    }
    if (
      addLienLevyAddResponse &&
      !addLienLevyAddResponse.systemFailure &&
      addLienLevyAddResponse.respcode === "03" &&
      addLienLevyAddResponse.respdesc !== ""
    ) {
      const error = ErrorConstants.GENERIC_SYSTEM_ERROR;
      setSystemSuccesMessages([]);
      systemErrorMessges.push(error);
      seterrorMessages(systemErrorMessges);
    }
    if (
      addLienLevyAddResponse &&
      !addLienLevyAddResponse.systemFailure &&
      addLienLevyAddResponse.respcode === "01" &&
      addLienLevyAddResponse.respdesc !== ""
    ) {
      const success = AppConstants.SAVE_SUCCESS;
      setAllowNavigation(false);
      seterrorMessages([]);
      setSystemSuccesMessages([]);
      sucessMessages.push(success);
      setSystemSuccesMessages(sucessMessages);
      onSearch(values.fcn);
      setWithHoldingStatus(lienLevy.witholdingVO.withHoldingStatus);
      localStorage.setItem('withHoldingStatus', lienLevy.witholdingVO.withHoldingStatus);
    }
    if (
      addLienLevyAddResponse &&
      !addLienLevyAddResponse.systemFailure &&
      addLienLevyAddResponse.respcode === "02" &&
      addLienLevyAddResponse.respdesc !== ""
    ) {
      const error = addLienLevyAddResponse.errorMessages;
      setSystemSuccesMessages([]);
      systemErrorMessges.push(error);
      seterrorMessages(error);
    }
    if (addLienLevyAddResponse && addLienLevyAddResponse.systemFailure) {
      const error = ErrorConstants.GENERIC_SYSTEM_ERROR;
      setSystemSuccesMessages([]);
      systemErrorMessges.push(error);
      seterrorMessages(systemErrorMessges);
    }
  }, [addLienLevyAddResponse]);

  useEffect(() => {
    if (dropdown && dropdown.listObj) {
      if (dropdown.listObj["Financial#6007"]) {
        setDropDownCheckList(dropdown.listObj["Financial#6007"]);
      }
      if (dropdown.listObj["General#6002"]) {
        setPayeeTypeData(dropdown.listObj["General#6002"]);
      }
      if (dropdown.listObj["Financial#3013"]) {
        setActivityTypeData(dropdown.listObj["Financial#3013"]);
      }
      if (dropdown.listObj["Financial#7"]) {
        setReasonCodeData(dropdown.listObj["Financial#7"]);
      }
      if (dropdown.listObj["General#6002"]) {
        setLienHolderTypeData(dropdown.listObj["General#6002"]);
      }
      if (dropdown.listObj["General#1012"]) {
        setUsageTypeCodeData(dropdown.listObj["General#1012"]);
      }
      if (dropdown.listObj["Financial#6014"]) {
        setApprovalData(dropdown.listObj["Financial#6014"]);
      }
      if (dropdown.listObj["Financial#1009"]) {
        setFrequencyDataDropdown(dropdown.listObj["Financial#1009"]);
      }
      if (dropdown.listObj["Financial#6017"]) {
        setPaymentScheduleTypeData(dropdown.listObj["Financial#6017"]);
      }
      if (dropdown.listObj["Financial#6016"]) {
        setFrequencyRecoupData(dropdown.listObj["Financial#6016"]);
      }
      if (dropdown.listObj['Financial#6023']) {
        setPayeePaymentStatusFrmState(dropdown.listObj['Financial#6023']);
      }
    }
  }, [dropdown]);

  useEffect(() => {
    if (approvalData && approvalData.length > 0) {
      if (approvalData.some(value => value.code === values.activityCode)) {
        setValues({ ...values, approval: "Y" });
      } else {
        setValues({ ...values, approval: "N" });
      }
    }
  }, [approvalData]);
  // Redirect to edit screen
  useEffect(() => {
    if (payload && payload.respcode === "02" && payload.respdesc !== "") {
      errorMessagesArray.push(payload.respdesc);
      seterrorMessages(errorMessagesArray);
    }
    if (
      payload != null &&
      payload !== "" &&
      payload.respcode === undefined &&
      payload.systemFailure === undefined
    ) {
      const withHoldSearchResult = payload;
      props.history.push({
        pathname: "/FinancialAccountLienLevyUpdate",
        state: { withHoldSearchResult, systemSuccessMessages, values: valuesFromARAdd },
        isFromAdd: true
      });
    }
    if (payload && payload.systemFailure !== undefined) {
      errorMessagesArray.push(ErrorConstants.TRANSACTION_ERROR);
      seterrorMessages(errorMessagesArray);
    }
  }, [payload]);

  // F1 6007 Changes starts
  const SystemParameterValue = useSelector(
    state => state.sharedState.SystemParameterData
  );
  useEffect(() => {
    if (
      SystemParameterValue &&
      !SystemParameterValue.systemFailure &&
      IsF16007
    ) {
      if (values.recoupmentTypeCode === "B" && SystemParameterValue.valuePCT) {
        const percentVal = SystemParameterValue.valuePCT * 100;
        setValues({
          ...values,
          percentValue: percentVal
            ? numeral(percentVal).format("0.00")
            : percentVal,
          installmentValue: ""
        });
      }
      if (values.recoupmentTypeCode === "A" && SystemParameterValue.valueAmt) {
        setValues({
          ...values,
          installmentValue: SystemParameterValue.valueAmt
            ? numeral(SystemParameterValue.valueAmt).format("0,0.00")
            : SystemParameterValue.valueAmt,
          percentValue: ""
        });
      }
    }
  }, [SystemParameterValue, IsF16007]);
  useEffect(() => {
    if (dropDownCheckList && values.reasonCode !== DEFAULT_DD_VALUE) {
      if (
        commonMethods.checkValueExists(dropDownCheckList, values.reasonCode)
      ) {
        setIsF16007(true);
      }
    }
  }, [dropDownCheckList, values.reasonCode]);
  // F1 6007 Changes ends

  useEffect(() => {
    if (
      values.lienHolderType &&
      values.lienHolderType !== DEFAULT_DD_VALUE
    ) {
      onLienLevyHolderIdTypeDropDown(values.lienHolderType);
    }
  }, [values.lienHolderType]);

  const searchSystemParameterPercentage = value => {
    dispatch(searchSystemParameterAction(value));
  };

  const systemParameterPayloadPercentage = useSelector(
    state => state.appConfigState.systemParameterState.payload
  );

  const handleRecoupmentStartDateChange = date => {
    setFocusCheck(true);
    setSelectedRecoupmentStartDate(date);
  };

  const handleChangePaymentSchedule = name => event => {
    setFocusCheck(true);
    setAllowNavigation(true);
    if (name === 'installmentAmount') {
      if (event.target.value && event.target.value.includes('.')) {
        if (commonMethods.checkingDecimal(event.target.value)) {
          setPaymentScheduleVO({ ...paymentScheduleVO, [name]: event.target.value });
        }
      } else {
        setPaymentScheduleVO({ ...paymentScheduleVO, [name]: event.target.value });
      }
    } else {
      setPaymentScheduleVO({
        ...paymentScheduleVO,
        [name]: event.target.value
      });
    }
  };

  useEffect(() => {
    if (values.paymentScheduleType === "A") {
      setPaymentSchedleData([]);
      setScheduleTable(false);
    }
    if (values.paymentScheduleType === "M") {
      setScheduleTable(true);
      setPaymentSchedleData([]);
    }
  }, [values.paymentScheduleType]);

  const getPayeeIdFromSystemParameter = systemParameter =>
    dispatch(getMaxFrequencyParameter(systemParameter));
  const frequencyResult = useSelector(
    state => state.sharedState.frequencyParameter
  );

  useEffect(() => {
    if (
      systemParameterPayloadPercentage &&
      systemParameterPayloadPercentage.length > 0
    ) {
      const systemParameterPercentage =
        systemParameterPayloadPercentage[0].systemParameterDetail[0].valuePCT *
        100;
      const percentValue = systemParameterPercentage;
      const installementValue = "";
      const recoupmentTypeCode = "D";
      const frequency = "1";
      const amount = "";
      const defaultOnPaymentSchedule = "N";
      const originalARAmount = values.originalARAmount;
      const maxWithholdAmount = values.maxWithholdAmount;
      const recoupmentsection = {
        percent: percentValue,
        installment: installementValue
          ? numeral(installementValue.toString()).format("0,0.00")
          : installementValue,
        recoupment: recoupmentTypeCode,
        frequency: frequency,
        amount: amount,
        defaultOnPaymentSchedule: defaultOnPaymentSchedule,
        originalARAmount: originalARAmount,
        maxWithholdAmount: maxWithholdAmount
      };
      setValues(
        setInputValues(
          {
            ...lienLevy,
            grossLevelDetails: {
              ...lienLevy.grossLevelDetails,
              approval: values.approval
            }
          },
          values,
          recoupmentsection
        )
      );
      setSelectedRecoupmentDate(
        new Date(
          new Date().toLocaleString("en-US", {
            timeZone: process.env.REACT_APP_TIMEZONE
          })
        )
      );
    }
  }, [systemParameterPayloadPercentage]);

  const systemParamValuetoPercentage = {
    parameterNumber: "9005",
    paramNumber: null,
    functionalArea: "F1",
    description: null,
    parameterNumberStartsWith: false,
    descriptionStartsOrContains: null,
    withoutNotesDataFlag: false
  };

  useEffect(() => {
    if (!recoupmentTypeClicked) {
      if (values.recoupmentTypeCode === "N") {
        setSelectedRecoupmentStartDate(null);
        setPaymentSchedleData([]);
        const percentValue = "0";
        const installementValue = "0";
        const recoupmentTypeCode = "N";
        const frequency = DEFAULT_DD_VALUE;
        const amount = "";
        const defaultOnPaymentSchedule = "N";
        const originalARAmount = values.originalARAmount;
        const maxWithholdAmount = values.maxWithholdAmount;
        const recoupmentsection = {
          percent: percentValue,
          installment: installementValue
            ? numeral(installementValue.toString()).format("0,0.00")
            : installementValue,
          recoupment: recoupmentTypeCode,
          frequency: frequency,
          amount: amount,
          defaultOnPaymentSchedule: defaultOnPaymentSchedule,
          originalARAmount: originalARAmount,
          maxWithholdAmount: maxWithholdAmount
        };
        setValues(
          setInputValues(
            lienLevy,
            {
              ...values,
              paymentScheduleType: DEFAULT_DD_VALUE,
              recoupmentStartDate: null,
              numberofInstallments: "",
              interestCalculation: "N",
              interestPercentage: "",
              paymentScheduleFrequency: DEFAULT_DD_VALUE
            },
            recoupmentsection
          )
        );
        setSelectedRecoupmentDate(null);
      }
      if (values.recoupmentTypeCode === "S") {
        const percentValue = "";
        const installementValue = "";
        const recoupmentTypeCode = "S";
        const frequency = DEFAULT_DD_VALUE;
        const amount = "";
        const defaultOnPaymentSchedule = "N";
        const originalARAmount = values.originalARAmount;
        const maxWithholdAmount = values.maxWithholdAmount;
        const recoupmentsection = {
          percent: percentValue,
          installment: installementValue
            ? numeral(installementValue.toString()).format("0,0.00")
            : installementValue,
          recoupment: recoupmentTypeCode,
          frequency: frequency,
          amount: amount,
          defaultOnPaymentSchedule: defaultOnPaymentSchedule,
          originalARAmount: originalARAmount,
          maxWithholdAmount: maxWithholdAmount
        };
        setValues(setInputValues(lienLevy, values, recoupmentsection));
        setSelectedRecoupmentDate(null);
      }
      if (values.recoupmentTypeCode === "A" && !IsF16007) {
        setSelectedRecoupmentStartDate(null);

        setPaymentSchedleData([]);
        const percentValue = "";
        const installementValue = "0";
        const recoupmentTypeCode = "A";
        const frequency = "1";
        const amount = "";
        const defaultOnPaymentSchedule = "N";
        const originalARAmount = values.originalARAmount;
        const maxWithholdAmount = values.maxWithholdAmount;
        const recoupmentsection = {
          percent: percentValue,
          installment: installementValue
            ? numeral(installementValue.toString()).format("0,0.00")
            : installementValue,
          recoupment: recoupmentTypeCode,
          frequency: frequency,
          amount: amount,
          defaultOnPaymentSchedule: defaultOnPaymentSchedule,
          originalARAmount: originalARAmount,
          maxWithholdAmount: maxWithholdAmount
        };
        setValues(
          setInputValues(
            lienLevy,
            {
              ...values,
              paymentScheduleType: DEFAULT_DD_VALUE,
              recoupmentStartDate: null,
              numberofInstallments: "",
              interestCalculation: "N",
              interestPercentage: "",
              paymentScheduleFrequency: DEFAULT_DD_VALUE
            },
            recoupmentsection
          )
        );
        setSelectedRecoupmentDate(
          new Date(
            new Date().toLocaleString("en-US", {
              timeZone: process.env.REACT_APP_TIMEZONE
            })
          )
        );
      }
      if (values.recoupmentTypeCode === "A" && IsF16007) {
        setSelectedRecoupmentStartDate(null);
        setPaymentSchedleData([]);
        const percentValue = "";
        const installementValue = "";
        const recoupmentTypeCode = "A";
        const frequency = "1";
        const amount = "";
        const defaultOnPaymentSchedule = "N";
        const originalARAmount = values.originalARAmount;
        const maxWithholdAmount = values.maxWithholdAmount;
        const recoupmentsection = {
          percent: percentValue,
          installment: installementValue
            ? numeral(installementValue.toString()).format("0,0.00")
            : installementValue,
          recoupment: recoupmentTypeCode,
          frequency: frequency,
          amount: amount,
          defaultOnPaymentSchedule: defaultOnPaymentSchedule,
          originalARAmount: originalARAmount,
          maxWithholdAmount: maxWithholdAmount
        };
        dispatch(
          getSystemParameterSpecificValue({
            paramNumber: 9010,
            functionalArea: "F1",
            lobCode: AppConstants.DEFAULT_LOB_VALUE,
            asOfDate: moment(
              new Date(
                new Date().toLocaleString("en-US", {
                  timeZone: process.env.REACT_APP_TIMEZONE
                })
              )
            ).format("YYYY-DD-MM")
          })
        );
        setValues(
          setInputValues(
            lienLevy,
            {
              ...values,
              paymentScheduleType: DEFAULT_DD_VALUE,
              recoupmentStartDate: null,
              numberofInstallments: "",
              interestCalculation: "N",
              interestPercentage: "",
              paymentScheduleFrequency: DEFAULT_DD_VALUE
            },
            recoupmentsection
          )
        );
        setSelectedRecoupmentDate(
          new Date(
            new Date().toLocaleString("en-US", {
              timeZone: process.env.REACT_APP_TIMEZONE
            })
          )
        );
      }
      if (values.recoupmentTypeCode === "B" && !IsF16007) {
        setSelectedRecoupmentStartDate(null);
        setPaymentSchedleData([]);
        const percentValue = "100.00";
        const installementValue = "";
        const recoupmentTypeCode = "B";
        const frequency = "1";
        const amount = "";
        const defaultOnPaymentSchedule = "N";
        const originalARAmount = values.originalARAmount;
        const maxWithholdAmount = values.maxWithholdAmount;
        const recoupmentsection = {
          percent: percentValue,
          installment: installementValue
            ? numeral(installementValue.toString()).format("0,0.00")
            : installementValue,
          recoupment: recoupmentTypeCode,
          frequency: frequency,
          amount: amount,
          defaultOnPaymentSchedule: defaultOnPaymentSchedule,
          originalARAmount: originalARAmount,
          maxWithholdAmount: maxWithholdAmount
        };
        setValues(
          setInputValues(
            lienLevy,
            {
              ...values,
              paymentScheduleType: DEFAULT_DD_VALUE,
              recoupmentStartDate: null,
              numberofInstallments: "",
              interestCalculation: "N",
              interestPercentage: "",
              paymentScheduleFrequency: DEFAULT_DD_VALUE
            },
            recoupmentsection
          )
        );
        setSelectedRecoupmentDate(
          new Date(
            new Date().toLocaleString("en-US", {
              timeZone: process.env.REACT_APP_TIMEZONE
            })
          )
        );
      }
      if (values.recoupmentTypeCode === "B" && IsF16007) {
        setSelectedRecoupmentStartDate(null);
        setPaymentSchedleData([]);
        const percentValue = "100.00";
        const installementValue = "";
        const recoupmentTypeCode = "B";
        const frequency = "1";
        const amount = "";
        const defaultOnPaymentSchedule = "N";
        const originalARAmount = values.originalARAmount;
        const maxWithholdAmount = values.maxWithholdAmount;
        const recoupmentsection = {
          percent: percentValue,
          installment: installementValue
            ? numeral(installementValue.toString()).format("0,0.00")
            : installementValue,
          recoupment: recoupmentTypeCode,
          frequency: frequency,
          amount: amount,
          defaultOnPaymentSchedule: defaultOnPaymentSchedule,
          originalARAmount: originalARAmount,
          maxWithholdAmount: maxWithholdAmount
        };
        dispatch(
          getSystemParameterSpecificValue({
            paramNumber: 9011,
            functionalArea: "F1",
            lobCode:AppConstants.DEFAULT_LOB_VALUE,
            asOfDate: moment(
              new Date(
                new Date().toLocaleString("en-US", {
                  timeZone: process.env.REACT_APP_TIMEZONE
                })
              )
            ).format("YYYY-DD-MM")
          })
        );
        setValues(
          setInputValues(
            lienLevy,
            {
              ...values,
              paymentScheduleType: DEFAULT_DD_VALUE,
              recoupmentStartDate: null,
              numberofInstallments: "",
              interestCalculation: "N",
              interestPercentage: "",
              paymentScheduleFrequency: DEFAULT_DD_VALUE
            },
            recoupmentsection
          )
        );
        setSelectedRecoupmentDate(
          new Date(
            new Date().toLocaleString("en-US", {
              timeZone: process.env.REACT_APP_TIMEZONE
            })
          )
        );
      }
      if (values.recoupmentTypeCode === "D") {
        searchSystemParameterPercentage(systemParamValuetoPercentage);
        setValues({
          ...values,
          paymentScheduleType: DEFAULT_DD_VALUE,
          recoupmentStartDate: null,
          numberofInstallments: "",
          interestCalculation: "N",
          interestPercentage: "",
          paymentScheduleFrequency: DEFAULT_DD_VALUE
        });
        setSelectedRecoupmentStartDate(null);
        setPaymentSchedleData([]);
      }
    }
  }, [values.recoupmentTypeCode, recoupmentTypeClicked]);

  let payeeTypeIdDropDown = [];
  payeeTypeIdDropDown = useSelector(
    state => state.accountReceivableState.payeeIdTypeDropdown
  );

  useEffect(() => {
    if (payeeTypeIdDropDown && payeeTypeIdDropDown.payeeIdTypeCode) {
      setPayeeTypeIdCodeData(payeeTypeIdDropDown.payeeIdTypeCode);
    }
  }, [payeeTypeIdDropDown]);

  const handleInitialPaymentDate = date => {
    setFocusCheck(true);
    setSelectedInitialPaymentDate(date);
  };

  const handleSatisfiedDate = date => {
    setFocusCheck(true);
    setSelectedSatisfiedDate(date);
  };
  const handleBeginDateChange = date => {
    setFocusCheck(true);
    setSelectedBeginDate(date);
  };
  const handleEndDateChange = date => {
    setFocusCheck(true);
    setSelectedEndDate(date);
  };

  const handleRecoupmentDateChange = date => {
    setFocusCheck(true);
    setAllowNavigation(true);
    setSelectedRecoupmentDate(date);
  };

  const handleDueDateChange = date => {
    setFocusCheck(true);
    setAllowNavigation(true);
    setSelectedDueDate(date);
  };

  function setInputValues (lienLevyDetails, values, recoupmentsection) {
    return {
      lob: AppConstants.DEFAULT_LOB_VALUE,
      payeeType:
        lienLevyDetails && lienLevyDetails.payeeDetails
          ? lienLevyDetails.payeeDetails.payeePayerType
          : DEFAULT_DD_VALUE,
      payeeIdTypeCode:
        lienLevyDetails && lienLevyDetails.payeeDetails
          ? lienLevyDetails.payeeDetails.payeePayerIdTypeCode
          : DEFAULT_DD_VALUE,
      reasonCode:
        lienLevyDetails && lienLevyDetails.payeeDetails
          ? lienLevyDetails.payeeDetails.reasonCode
          : DEFAULT_DD_VALUE,
      activityCode:
        lienLevyDetails && lienLevyDetails.payeeDetails
          ? lienLevyDetails.payeeDetails.activityType
          : DEFAULT_DD_VALUE,
      systemPayeeId:
        lienLevyDetails && lienLevyDetails.payeeDetails
          ? lienLevyDetails.payeeDetails.systemPayeeId
          : "",
      payeeId:
        lienLevyDetails && lienLevyDetails.payeeDetails
          ? lienLevyDetails.payeeDetails.payeePayerId
          : "",
      payeePayerName:
        lienLevyDetails && lienLevyDetails.payeeDetails
          ? lienLevyDetails.payeeDetails.payeePayerName
          : "",
      fcn:
        lienLevyDetails && lienLevyDetails.grossLevelDetails
          ? lienLevyDetails.grossLevelDetails.fcnNumber
          : "",
      payeeStatus:
        lienLevyDetails && lienLevyDetails.grossLevelDetails
          ? lienLevyDetails.grossLevelDetails.payeeStatus
          : "",
      adjustedARAmount:
        lienLevyDetails && lienLevyDetails.grossLevelDetails
          ? lienLevyDetails.grossLevelDetails.adjustedARAmount
          : "",
      balanceAmount:
        lienLevyDetails && lienLevyDetails.grossLevelDetails
          ? lienLevyDetails.grossLevelDetails.balanceAmount
          : "",
      approval:
        lienLevyDetails && lienLevyDetails.grossLevelDetails
          ? lienLevyDetails.grossLevelDetails.approval === false
            ? "N"
            : "Y"
          : "N",
      relatedFTTCN:
        lienLevyDetails && lienLevyDetails.grossLevelDetails
          ? lienLevyDetails.grossLevelDetails.relatedFTTCN
          : "",
      establishedDate:
        lienLevyDetails && lienLevyDetails.grossLevelDetails
          ? lienLevyDetails.grossLevelDetails.establishedDate
          : "",
      lastDateApplied:
        lienLevyDetails && lienLevyDetails.grossLevelDetails
          ? lienLevyDetails.grossLevelDetails.lastDateApplied
          : null,
      arAgeInDays:
        lienLevyDetails && lienLevyDetails.grossLevelDetails
          ? lienLevyDetails.grossLevelDetails.arAgeInDays
          : null,
      underAppeal:
        lienLevyDetails && lienLevyDetails.grossLevelDetails
          ? lienLevyDetails.grossLevelDetails.underAppeal === false
            ? "N"
            : "Y"
          : "N",
      arLettersent:
        lienLevyDetails && lienLevyDetails.grossLevelDetails
          ? lienLevyDetails.grossLevelDetails.arLetterSent
          : "",
      categoryOfServiceData:
        lienLevyDetails && lienLevyDetails.accountDetails
          ? lienLevyDetails.accountDetails.categoryOfService
          : [],
      categoryOfService: values.categoryOfService,
      exemptFromCollectionAgency:
        lienLevyDetails && lienLevyDetails.exemptionDetails
          ? lienLevyDetails.exemptionDetails.excemptionFromCollectionAgency ===
            true
            ? "Y"
            : "N"
          : "N",
      exemptFromInterest:
        lienLevyDetails && lienLevyDetails.exemptionDetails
          ? lienLevyDetails.exemptionDetails.excemptionFromInterest === true
            ? "Y"
            : "N"
          : "N",
      exemptFromPenalty:
        lienLevyDetails && lienLevyDetails.exemptionDetails
          ? lienLevyDetails.exemptionDetails.excemptionFromPenalty === true
            ? "Y"
            : "N"
          : "N",
      collocationCodeData:
        lienLevyDetails && lienLevyDetails.accountDetails
          ? lienLevyDetails.accountDetails.fundCode
          : [],
      collocationCode: values.collocationCode,
      recoupmentData:
        lienLevyDetails && lienLevyDetails.recoupmentDetails
          ? lienLevyDetails.recoupmentDetails.recoupmentTypeCode
          : [],
      frequencyData:
        lienLevyDetails && lienLevyDetails.recoupmentDetails
          ? lienLevyDetails.recoupmentDetails.frequency
          : [],
      recoupmentTypeCode: recoupmentsection.recoupment,
      frequency: recoupmentsection.frequency,
      percentValue: recoupmentsection.percent
        ? numeral(recoupmentsection.percent).format("0.00")
        : recoupmentsection.percent,
      installmentValue: recoupmentsection.installment
        ? numeral(recoupmentsection.installment.toString()).format("0,0.00")
        : recoupmentsection.installment,
      amountDue: recoupmentsection.amount,
      defaultOnPaymentSchedule: recoupmentsection.defaultOnPaymentSchedule,
      originalARAmountData:
        lienLevyDetails && lienLevyDetails.grossLevelDetails
          ? lienLevyDetails.grossLevelDetails.originalARAmount
          : 0,
      balanceAmountData:
        lienLevyDetails && lienLevyDetails.grossLevelDetails
          ? lienLevyDetails.grossLevelDetails.balanceAmount
          : 0,
      appliedAmountData:
        lienLevyDetails && lienLevyDetails.grossLevelDetails
          ? lienLevyDetails.grossLevelDetails.appliedAmountData
          : 0,
      fiscalYear:
        lienLevyDetails && lienLevyDetails.accountDetails
          ? lienLevyDetails.accountDetails.scoaFiscalYear
          : values.fiscalYear,
      lienHolderTypeData:
        lienLevyDetails && lienLevyDetails.lienHolderType
          ? lienLevyDetails.lienHolderType
          : [],
      lienHolderType: values.lienHolderType
        ? values.lienHolderType
        : DEFAULT_DD_VALUE,
      lienHolderIdTypeData: lienHolderIdTypeData || [],
      lienHolderIdType: values.lienHolderIdType
        ? values.lienHolderIdType
        : DEFAULT_DD_VALUE,
      lienHolderId: values.lienHolderId,
      originalARAmount: recoupmentsection.originalARAmount,
      maxWithholdAmount: recoupmentsection.maxWithholdAmount,
      paymentScheduleType: values.paymentScheduleType,
      paymentScheduleFrequency: values.paymentScheduleFrequency,
      recoupmentStartDate: values.recoupmentStartDate,
      numberofInstallments: values.numberofInstallments,
      interestCalculation: values.interestCalculation,
      interestPercentage: values.interestPercentage,
      recoupedAmount: values.recoupedAmount,
      installmentAmount: values.installmentValue
    };
  }
  const lienLevyDetails = props.location.state?.continueResults;
  const setInput = (lienLevyDetails, recoupmentsection) => {
    lienLevyDetails.grossLevelDetails.establishedDate = getDateInMMDDYYYYFormat(
      new Date(
        new Date().toLocaleString("en-US", {
          timeZone: process.env.REACT_APP_TIMEZONE
        })
      )
    );
    setValues(setInputValues(lienLevyDetails, values, recoupmentsection));
    setSelectedRecoupmentDate(
      getDateInMMDDYYYYFormat(
        new Date(
          new Date().toLocaleString("en-US", {
            timeZone: process.env.REACT_APP_TIMEZONE
          })
        )
      )
    );
  };
  useEffect(() => {
    if (lienLevyDetails) {
      const percentValue =
        lienLevyDetails && lienLevyDetails.recoupmentDetails
          ? lienLevyDetails.recoupmentDetails.percentValue
          : "";
      const installementValue =
        lienLevyDetails &&
          lienLevyDetails.recoupmentDetails &&
          lienLevyDetails.recoupmentDetails.installmentValue
          ? numeral(lienLevyDetails.recoupmentDetails.installmentValue).format(
            "0,0.00"
          )
          : "";
      const maxWithholdAmount =
        lienLevyDetails &&
          lienLevyDetails.witholdingVO &&
          lienLevyDetails.witholdingVO.maxWithholdAmount
          ? numeral(
            Number(
              lienLevyDetails.witholdingVO.maxWithholdAmount.replace(/,/g, "")
            ).toPrecision()
          ).format("0,0.00")
          : "";
      const recoupmentTypeCode = "B";
      const frequency = values.frequency;
      const amount = "";
      const defaultOnPaymentSchedule = "N";
      const originalARAmount =
        lienLevyDetails &&
          lienLevyDetails.grossLevelDetails &&
          lienLevyDetails.grossLevelDetails.originalARAmount
          ? numeral(lienLevyDetails.grossLevelDetails.originalARAmount).format(
            "0,0.00"
          )
          : values.originalARAmount;
      const recoupmentsection = {
        percent: percentValue,
        installment: installementValue
          ? numeral(installementValue.toString()).format("0,0.00")
          : installementValue,
        recoupment: recoupmentTypeCode,
        frequency: frequency,
        amount: amount,
        defaultOnPaymentSchedule: defaultOnPaymentSchedule,
        originalARAmount: originalARAmount,
        maxWithholdAmount: maxWithholdAmount
      };
      setInput(lienLevyDetails, recoupmentsection);
      setLienLevy(lienLevyDetails);
    }
  }, [lienLevyDetails]);

  useEffect(() => {
    if (
      (lienLevyDetails.payeeDetails &&
      lienLevyDetails.payeeDetails.payeePayerType &&
      lienLevyDetails.payeeDetails.payeePayerType !== DEFAULT_DD_VALUE) ||
      values.payeeType !== DEFAULT_DD_VALUE
    ) {
      onPayeeTypeIdDropDown(
        lienLevyDetails?.payeeDetails?.payeePayerType
          || values.payeeType
      );
    }
  }, [
       lienLevyDetails?.payeeDetails?.payeePayerType
      , values?.payeeType
  ]);

  useEffect(() => {
    if (commonMethods.checkValueExists(dropDownCheckList, values.reasonCode)) {
      const percentValue =
        lienLevyDetails && lienLevyDetails.recoupmentDetails
          ? lienLevyDetails.recoupmentDetails.percentValue
          : "";
      const installementValue =
        lienLevyDetails &&
          lienLevyDetails.recoupmentDetails &&
          lienLevyDetails.recoupmentDetails.installmentValue
          ? numeral(lienLevyDetails.recoupmentDetails.installmentValue).format(
            "0,0.00"
          )
          : "";
      const recoupmentTypeCode = "B";
      const frequency = "1";
      const amount = "";
      const defaultOnPaymentSchedule = "N";
      const originalARAmount =
        lienLevyDetails &&
          lienLevyDetails.grossLevelDetails &&
          lienLevyDetails.grossLevelDetails.originalARAmount
          ? numeral(lienLevyDetails.grossLevelDetails.originalARAmount).format(
            "0,0.00"
          )
          : values.originalARAmount;
      const maxWithholdAmount =
        lienLevyDetails &&
          lienLevyDetails.witholdingVO &&
          lienLevyDetails.witholdingVO.maxWithholdAmount
          ? numeral(
            Number(
              lienLevyDetails.witholdingVO.maxWithholdAmount.replace(/,/g, "")
            ).toPrecision()
          ).format("0,0.00")
          : values.maxWithholdAmount;
      const recoupmentsection = {
        percent: percentValue,
        installment: installementValue
          ? numeral(installementValue.toString()).format("0,0.00")
          : installementValue,
        recoupment: recoupmentTypeCode,
        frequency: frequency,
        amount: amount,
        defaultOnPaymentSchedule: defaultOnPaymentSchedule,
        originalARAmount: originalARAmount,
        maxWithholdAmount: maxWithholdAmount
      };
      if (
        commonMethods.checkValueExists(dropDownCheckList, values.reasonCode)
      ) {
        dispatch(
          getSystemParameterSpecificValue({
            paramNumber: 9011,
            functionalArea: "F1",
            lobCode:AppConstants.DEFAULT_LOB_VALUE,
            asOfDate: moment(
              new Date(
                new Date().toLocaleString("en-US", {
                  timeZone: process.env.REACT_APP_TIMEZONE
                })
              )
            ).format("YYYY-DD-MM")
          })
        );
      }
      setValues(setInputValues(lienLevyDetails, values, recoupmentsection));
    }
  }, [
    lienLevyDetails && lienLevyDetails.payeeDetails
      ? lienLevyDetails.payeeDetails.reasonCode
      : values.reasconCode,
    IsF16007
  ]);

  useEffect(() => {
    if (open) {
      document.getElementById("form_pop_up").scrollIntoView({
        behavior: "smooth"
      });
    }
  }, [open]);

  const handleClickOpenPaymentSchedule = () => {
    const errorArray = [];
    if (values.paymentScheduleType === DEFAULT_DD_VALUE) {
      errorArray.push(ErrorConstants.PAYMENT_SCHEDULE_TYPE);
      setPaymentScheduleTypeError(ErrorConstants.PAYMENT_SCHEDULE_TYPE);
    }
    if (!selectedRecoupmentStartDate) {
      setRecoupmentStartDateError(ErrorConstants.RECOPUMENT_START_DATE);
      errorArray.push(ErrorConstants.RECOPUMENT_START_DATE);
    }

    if (selectedRecoupmentStartDate) {
      var startDate = new Date(selectedRecoupmentStartDate);
      var estDate = new Date(values.establishedDate);
      var currentDate = new Date(
        new Date().toLocaleString("en-US", {
          timeZone: process.env.REACT_APP_TIMEZONE
        })
      );

      if (!moment(startDate).isValid()) {
        setRecoupmentStartDateError(ErrorConstants.DATA_INVALID);
        errorArray.push(ErrorConstants.DATA_INVALID);
      } else if (
        validateDateMinimumValue(moment(startDate).format("MM/DD/YYYY"))
      ) {
        setRecoupmentStartDateError(AppConstants.DATE_ERROR_1964);
        errorArray.push(minRecoupmentDateTextTemp);
      } else if (compareTwoDatesGreaterThanOrEqual(startDate, currentDate)) {
        const errorDateText = ErrorConstants.START_DATE_LESS_CURRENT_DATE_ERROR.replace(
          "{current date}",
          moment(currentDate).format("MM/DD/YYYY")
        );
        setRecoupmentStartDateError(errorDateText);
        errorArray.push(errorDateText);
      }
      if (
        moment(startDate).isValid() &&
        !validateDateMinimumValue(moment(startDate).format("MM/DD/YYYY"))
      ) {
        if (compareTwoDatesGreaterThanOrEqual(startDate, estDate)) {
          setRecoupmentStartDateError(
            ErrorConstants.START_DATE_LESS_AR_CREATED_ERROR
          );
          errorArray.push(ErrorConstants.START_DATE_LESS_AR_CREATED_ERROR);
        }
      }
    }
    if (errorArray.length === 0) {
      setPopUpErrorMessage([]);
      setSelectedDueDate(null);
      setSelectedDefaultDate(null);
      setOpen(true);
      setAdd(true);
      setShowError(false);
      setShowErrorText("");
      setPaymentScheduleVO({
        installmentAmount: "",
        recoupedAmount: "0.00"
      });
      setRetainPaymentSchedule({
        installmentAmount: "",
        recoupedAmount: "0.00"
      });
      setRetainSelectedDueDate(selectedDueDate);
      setRecoupmentStartDateError("");
      setPaymentScheduleTypeError("");
      seterrorMessages([]);
    } else {
      seterrorMessages(errorArray);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setPaymentScheduleVO({
      installmentAmount: "",
      recoupedAmount: "0.00"
    });
    seterrorMessages([]);
  };

  const handleChangeTabs = (event, newValue) => {
    setFocusCheck(true);
    setValue(newValue);
    if (newValue === 0) {
      setTabOne(true);
    }
    if (newValue === 1) {
      setTabTwo(true);
    }
    if (newValue === 2) {
      setTabThree(true);
    }
    if (newValue === 3) {
      setTabFour(true);
    }

    const colorCondition = checkFormValidations();
    if (newValue > 0) {
      if (colorCondition) {
        if (errorMessagesArray.length > 0) {
          setColor("red");
        } else {
          setColor("green");
        }
      }
    }
  };

  const savePaymentSchedule = () => {
    var selectedDueDateError;
    var amountDueError = false;
    var selectedDueDateGreaterErrorTemp = false;
    var minDueDateError = false;
    var minDefaultDateError = false;
    var invalidDueDateError = false;
    var invalidDefaultDateError = false;
    var negativeAmountError = false;
    var amountGreaterThanZero = false;
    var originalArAmountRequired = false;
    var selectedDueDateErrorText;
    var amountDueErrorText = "";
    var selectedDueDateErrorTextTemp = "";
    var minDueDateErrorText = "";
    var minDefaultDateErrorText = "";
    var invalidDueDateErrorText = "";
    var invalidDefaultDateErrorText = "";
    var negativeAmountErrorText = "";
    var amountGreaterThanZeroText = "";
    var originalArAmountRequiredText = "";

    if (!selectedDueDate) {
      selectedDueDateError = true;
      selectedDueDateErrorText = ErrorConstants.DUE_DATE_REQUIRED;
      popUpErrorMessagesArray.push(selectedDueDateErrorText);
    }

    if (selectedDueDate) {
      if (!moment(selectedDueDate).isValid()) {
        invalidDueDateError = true;
        invalidDueDateErrorText = ErrorConstants.DUE_DATE_INVALID;
        popUpErrorMessagesArray.push(invalidDueDateErrorText);
      }
      if (!invalidDueDateError) {
        if (validateDateMinimumValue(selectedDueDate)) {
          minDueDateError = true;
          minDueDateErrorText = AppConstants.DATE_ERROR_1964;
          popUpErrorMessagesArray.push(minDueDateErrorText);
        }
      }
    }

    if (selectedDefaultDate) {
      if (!moment(selectedDefaultDate).isValid()) {
        invalidDefaultDateError = true;
        invalidDefaultDateErrorText = ErrorConstants.DEFAULT_DATE_INVALID;
        popUpErrorMessagesArray.push(invalidDefaultDateErrorText);
      }

      if (!invalidDefaultDateError) {
        if (selectedDefaultDate) {
          if (validateDateMinimumValue(selectedDefaultDate)) {
            minDefaultDateError = true;
            minDefaultDateErrorText = AppConstants.DATE_ERROR_1964;
            popUpErrorMessagesArray.push(minDefaultDateErrorText);
          } else if (
            compareTwoDatesGreaterThanOrEqual(
              new Date(selectedDefaultDate),
              new Date(
                new Date().toLocaleString("en-US", {
                  timeZone: process.env.REACT_APP_TIMEZONE
                })
              )
            )
          ) {
            minDefaultDateError = true;
            minDefaultDateErrorText = ErrorConstants.DEFAULT_DATE_LESS;
            popUpErrorMessagesArray.push(minDefaultDateErrorText);
          }
        }
      }
    }

    if (
      selectedDueDate &&
      compareTwoDatesGreaterThanOrEqual(
        new Date(selectedDueDate),
        new Date(
          new Date().toLocaleString("en-US", {
            timeZone: process.env.REACT_APP_TIMEZONE
          })
        )
      )
    ) {
      selectedDueDateGreaterErrorTemp = true;
      selectedDueDateErrorTextTemp = ErrorConstants.DUE_DATE_ERROR;
      popUpErrorMessagesArray.push(selectedDueDateErrorTextTemp);
    }

    if (
      selectedDueDate &&
      compareTwoDatesGreaterThanOrEqual(
        new Date(selectedDueDate),
        new Date(selectedRecoupmentStartDate)
      )
    ) {
      selectedDueDateGreaterErrorTemp = true;
      selectedDueDateErrorTextTemp = ErrorConstants.DUE_DATE_RECOUP;
      popUpErrorMessagesArray.push(selectedDueDateErrorTextTemp);
    }

    if (paymentScheduleVO.installmentAmount) {
      if (
        values.originalARAmount === "" ||
        values.originalARAmount === undefined
      ) {
        originalArAmountRequired = true;
        originalArAmountRequiredText =
          ErrorConstants.ORIGINAL_AR_AMOUNT_REQUIRED;
        popUpErrorMessagesArray.push(originalArAmountRequiredText);
      } else {
        if (
          paymentScheduleVO.installmentAmount
            .toString()
            .replace(/,/g, "")
            .match(/^[-]?[0-9]{0,11}\.?[0-9]{0,2}$/) === null
        ) {
          negativeAmountError = true;
          negativeAmountErrorText = ErrorConstants.DATA_INVALID;
          popUpErrorMessagesArray.push(negativeAmountErrorText);
        } else {
          if (
            !(
              paymentScheduleVO.installmentAmount
                .toString()
                .replace(/,/g, "")
                .match(/^[-]?[0-9]{12,15}\.?[0-9]{0,2}?$/) === null
            )
          ) {
            negativeAmountError = true;
            negativeAmountErrorText = ErrorConstants.DATA_INVALID;
            popUpErrorMessagesArray.push(negativeAmountErrorText);
          }
        }
        if (+paymentScheduleVO.installmentAmount < 0) {
          negativeAmountError = true;
          negativeAmountErrorText = ErrorConstants.NEGATIVE_AMOUNT;
          popUpErrorMessagesArray.push(negativeAmountErrorText);
        }
        if (+paymentScheduleVO.installmentAmount === 0) {
          amountGreaterThanZero = true;
          amountGreaterThanZeroText = ErrorConstants.GREATER_THAN_ZERO_AMOUNT;
          popUpErrorMessagesArray.push(amountGreaterThanZeroText);
        }
        if (
          paymentScheduleVO.installmentAmount &&
          values.originalARAmount &&
          parseFloat(paymentScheduleVO.installmentAmount.replace(/,/g, "")) >
          parseFloat(
            values.originalARAmount
              .replace(/,/g, "")
              .replace("$", "")
              .trim()
          )
        ) {
          amountDueError = true;
          amountDueErrorText =
            ErrorConstants.AMOUNTDUE_ORIGINALAMOUNT_VALIDATION;
          popUpErrorMessagesArray.push(
            ErrorConstants.AMOUNTDUE_ORIGINALAMOUNT_VALIDATION
          );
        }
      }
    } else {
      amountDueError = true;
      amountDueErrorText = ErrorConstants.AMOUNT_DUE;
      popUpErrorMessagesArray.push(amountDueErrorText);
    }
    setPopUpErrorMessage(popUpErrorMessagesArray);
    seterrorMessages(popUpErrorMessagesArray);
    setShowError({
      showDueDateError: selectedDueDateError,
      showAmountDueError: amountDueError,
      showDueDateGreaterError: selectedDueDateGreaterErrorTemp,
      showMinDueDateError: minDueDateError,
      showMinDefaultDateError: minDefaultDateError,
      showDueDateInvalidError: invalidDueDateError,
      showDefaultDateError: invalidDefaultDateError,
      showNegativeAmountError: negativeAmountError,
      showGreaterThanZeroAmountError: amountGreaterThanZero,
      showOriginalArAmountPopError: originalArAmountRequired
    });
    setShowErrorText({
      showDueDateErrorText: selectedDueDateErrorText,
      showAmountDueErrorText: amountDueErrorText,
      showDueDateGreaterErrorText: selectedDueDateErrorTextTemp,
      showMinDueDateErrorText: minDueDateErrorText,
      showMinDefaultDateErrorText: minDefaultDateErrorText,
      showDueDateInvalidErrorText: invalidDueDateErrorText,
      showDefaultDUeDateErrorText: invalidDefaultDateErrorText,
      showNegativeAmmountErrorText: negativeAmountErrorText,
      showGreaterThanZeroAmountText: amountGreaterThanZeroText,
      showOriginalArAmountPopErrorText: originalArAmountRequiredText
    });
    if (add) {
      const scheduleData = paymentScheduleData;

      if (popUpErrorMessagesArray.length === 0) {
        var value = {
          id: generateUUID(),
          scheduleSK: null,
          dueDate: selectedDueDate
            ? moment(new Date(selectedDueDate).getTime()).format("MM/DD/YYYY")
            : null,
          installmentAmount: paymentScheduleVO.installmentAmount,
          recoupedAmount: paymentScheduleVO.recoupedAmount
        };
        if (scheduleData.length > 0) {
          let dateFlag = false;
          scheduleData.map((option) => {
            if (option.dueDate === value.dueDate) {
              dateFlag = true;
            }
          });
          if (dateFlag) {
            const duplicateMessage = ErrorConstants.DUPLICATE_DUE_DATE;
            setShowError({
              showDueDateError: true
            });
            setShowErrorText({
              showDueDateErrorText: duplicateMessage
            });
            window.scrollTo(0, 0);
          } else {
            scheduleData.push(value);
            setPaymentSchedleData(scheduleData);
            handleClose();
          }
        } else {
          scheduleData.push(value);
          setPaymentSchedleData(scheduleData);
          handleClose();
        }
      }
    } else {
      const scheduleData = paymentScheduleData;
      if (popUpErrorMessagesArray.length === 0) {
        if (scheduleData.length > 0) {
          let dateFlag = false;
          scheduleData.map((option) => {
            if (
              option.dueDate ===
              moment(new Date(selectedDueDate).getTime()).format(
                "MM/DD/YYYY"
              ) &&
              option.id !== paymentScheduleVO.id
            ) {
              dateFlag = true;
            }
          });
          if (dateFlag) {
            const duplicateMessage = ErrorConstants.DUPLICATE_DUE_DATE;
            setShowError({
              showDueDateError: true
            });
            setShowErrorText({
              showDueDateErrorText: duplicateMessage
            });
            window.scrollTo(0, 0);
          } else {
            paymentScheduleData.map(value => {
              if (value.id === paymentScheduleVO.id) {
                value.dueDate = selectedDueDate
                  ? moment(new Date(selectedDueDate).getTime()).format(
                    "MM/DD/YYYY"
                  )
                  : null;
                value.installmentAmount = paymentScheduleVO.installmentAmount;
              }
              setPaymentSchedleData(paymentScheduleData);
              setOpen(false);
              setAdd(true);
            });
          }
        }
      }
    }
  };

  const formatDecimalField = name => event => {
    if (event.target.value !== "") {
      setValues({
        ...values,
        [name]: numeral(event.target.value).format("0,0.00")
      });
    }
  };
  const formatPercent = name => event => {
    if (values.percentValue) {
      setValues({
        ...values,
        [name]: numeral(event.target.value).format("0.00")
      });
    }
  };
  const removeFormatDecimalFieldWHAmount = () => () => {
    if (values.maxWithholdAmount) {
      setValues({
        ...values,
        maxWithholdAmount: numeral(values.maxWithholdAmount).format("0.00")
      });
    }
  };
  const removeFormatDecimalField = () => () => {
    if (values.originalARAmount) {
      setValues({
        ...values,
        originalARAmount: numeral(values.originalARAmount).format("0.00")
      });
    }
  };
  const removeFormatDecimalFieldPercent = () => () => {
    if (values.interestPercentage) {
      setValues({
        ...values,
        interestPercentage: numeral(values.interestPercentage).format("0.00")
      });
    }
  };
  const removeFormatDecimalFieldInstallment = () => () => {
    if (values.installmentValue) {
      setValues({
        ...values,
        installmentValue: numeral(values.installmentValue).format("0.00")
      });
    }
  };

  const formatDecimalFieldInstallment = () => () => {
    if (paymentScheduleVO.installmentAmount) {
      setPaymentScheduleVO({
        ...paymentScheduleVO,
        installmentAmount: numeral(paymentScheduleVO.installmentAmount).format(
          "0,0.00"
        )
      });
    }
  };

  const removeFormatDecimalFieldAmountDue = () => () => {
    if (paymentScheduleVO.installmentAmount) {
      setPaymentScheduleVO({
        ...paymentScheduleVO,
        installmentAmount: numeral(paymentScheduleVO.installmentAmount).format(
          "0.00"
        )
      });
    }
  };

  useEffect(() => {
    if (paymentScheduleChanged) {
      setPaymentSchedleData([]);
      setValues({
        ...values,
        recoupmentStartDate: null,
        numberofInstallments: "",
        interestCalculation: "N",
        interestPercentage: "",
        paymentScheduleFrequency: DEFAULT_DD_VALUE
      });
      setSelectedRecoupmentStartDate(null);
    }
  }, [paymentScheduleChanged]);
  useEffect(() => {
    if (
      getRecoupmetDetails &&
      !getRecoupmetDetails.systemFailure &&
      getRecoupmetDetails.recoupmentVO && recoupmentTypeClicked
    ) {
      const amount = "";
      const defaultOnPaymentSchedule = "N";
      const originalARAmount = values.originalARAmount;
      const maxWithholdAmount = values.maxWithholdAmount;
      const recoupmentsection = {
        percent: getRecoupmetDetails.recoupmentVO.percentageOriginalAmount,
        installment: getRecoupmetDetails.recoupmentVO.installmentAmount
          ? numeral(
            getRecoupmetDetails.recoupmentVO.installmentAmount.toString()
          ).format("0,0.00")
          : getRecoupmetDetails.recoupmentVO.installmentAmount,
        recoupment: getRecoupmetDetails.recoupmentVO.recoupmentTypeCode,
        frequency: getRecoupmetDetails.recoupmentVO.frequency,
        amount: amount,
        defaultOnPaymentSchedule: defaultOnPaymentSchedule,
        originalARAmount: originalARAmount,
        maxWithholdAmount: maxWithholdAmount
      };
      if (
        recoupmentsection.frequency !== values.frequency ||
        recoupmentsection.recoupment !== values.recoupmentTypeCode ||
        Number(recoupmentsection.installment) !== Number(values.installmentValue) ||
        Number(recoupmentsection.percent) !== Number(values.percentValue) ||
        getRecoupmetDetails.recoupmentVO.startDateStr !== moment(selectedRecoupmentDate).format('MM/DD/YYYY')
      ) { setFocusCheck(true); }
      setSelectedRecoupmentDate(getRecoupmetDetails.recoupmentVO.startDateStr);
      setValues(setInputValues(lienLevy, values, recoupmentsection));
      seterrorMessages([]);
    }
    if (getRecoupmetDetails && getRecoupmetDetails.systemFailure) {
      seterrorMessages([ErrorConstants.TRANSACTION_ERROR]);
    }
  }, [getRecoupmetDetails]);

  const handleDefaultRecoupment = () => {
    setAllowNavigation(true);
    setRecoupmentTypeClicked(true);
    /**
     *       establishedDate:
        lienLevyDetails && lienLevyDetails.grossLevelDetails
          ? new Date(lienLevyDetails.grossLevelDetails.establishedDate)
          : "",
     */
    let estDate =
      lienLevy && lienLevy.grossLevelDetails
        ? lienLevy.grossLevelDetails.establishedDate
        : null;
    estDate = new Date(estDate);

    const estDateFormatted = getDateInMMDDYYYYFormat(estDate);
    const paymentScheduleTemp = paymentScheduleData;
    paymentScheduleTemp.map(value => {
      value.auditUserID = logInUserID;
      value.auditTimeStamp = getUTCTimeStamp();
      value.addedAuditUserID = logInUserID;
      value.addedAuditTimeStamp = getUTCTimeStamp();
      value.installmentAmount = numeral(value.installmentAmount).format(
        "0.00"
      );
      value.recoupedAmount = numeral(value.recoupedAmount).format("0.00");
    });
    onGetRecoupmetDetails(commonMethods.removeLeadingTrailingSpaces({
      adjustedArAmt: 0,
      versionNoForFinancialDetail: 0,
      versionNo: 0,
      fcnMediaCode: null,
      fcnSequenceNo:
        lienLevy && lienLevy.grossLevelDetails
          ? lienLevy.grossLevelDetails.fcnNumber.slice(
            lienLevy.grossLevelDetails.fcnNumber.length - 5,
            lienLevy.grossLevelDetails.fcnNumber.length
          )
          : 0,
      receivableFCN:
        lienLevy && lienLevy.grossLevelDetails
          ? lienLevy.grossLevelDetails.fcnNumber
          : 0,
      payeePayerID: values.payeeId,
      payeePayerIDTypeCode:
        lienLevy && lienLevy.grossLevelDetails
          ? lienLevy.payeeDetails.payeePayerIdTypeCode
          : "",
      payeePayerTypeCode:
        lienLevy && lienLevy.payeeDetails
          ? lienLevy.payeeDetails.payeePayerType
          : "",
      provBillSysId:
        lienLevy && lienLevy.payeeDetails.billProvSysId
          ? lienLevy.payeeDetails.billProvSysId
          : "",
      auditUserID: logInUserID,
      auditTimeStamp: getUTCTimeStamp(),
      addedAuditUserID: logInUserID,
      addedAuditTimeStamp: getUTCTimeStamp(),
      payeePayerIdFromDB:
        lienLevy && lienLevy.grossLevelDetails
          ? lienLevy.payeeDetails.systemPayeeId
          : 0,
      withholdingVO: commonMethods.removeLeadingTrailingSpaces({
        thirdPartyID: values.lienHolderId,
        thirdPartyIDFromDB: values.thirdPartyIDFromDB
          ? values.thirdPartyIDFromDB
          : lienLevy && lienLevy.witholdingVO
            ? lienLevy.witholdingVO.thirdPartyIDFromDB
            : "",
        thirdPartyType: values.lienHolderType,
        thirdPartyIDType: values.lienHolderIdType
      }),
      lob: AppConstants.DEFAULT_LOB_VALUE,
      activityTypeCode:
        lienLevy && lienLevy.payeeDetails
          ? lienLevy.payeeDetails.activityType
          : "",
      originalAmount: values.originalARAmount,
      balanceAmount:
        lienLevy && lienLevy.witholdingVO
          ? lienLevy.witholdingVO.balanceAmount
          : "",
      establishedDate: estDateFormatted,
      establishedDateStr: estDateFormatted,
      accountReceivableReasonCode:
        lienLevy && lienLevy.payeeDetails
          ? lienLevy.payeeDetails.reasonCode
          : "",
      memberID: null,
      memberIDTypeCode: null,
      approvalIndicator: values.approval === "Y" ? 1 : 0,
      lastAppliedDate:
        lienLevy && lienLevy.grossLevelDetails
          ? lienLevy.grossLevelDetails.lastDateApplied
          : null,
      advancePaymentReceivableVO: {
        manualCheckNumber: "0"
      },
      manualCheckDateStr: null,
      holdLetterIndicator: false,
      receivablePurgeIndicator: false,
      calculatedInterest:
        lienLevy && lienLevy.grossLevelDetails
          ? lienLevy.grossLevelDetails.calculatedInterest
          : 0.0,
      calculatedPenalty:
        lienLevy && lienLevy.grossLevelDetails
          ? lienLevy.grossLevelDetails.calculatedPenalty
          : 0.0,
      calculatedFees:
        lienLevy && lienLevy.grossLevelDetails
          ? lienLevy.grossLevelDetails.calculatedFees
          : 0.0,
      agingCode:
        lienLevy && lienLevy.grossLevelDetails
          ? lienLevy.grossLevelDetails.arAgeInDays
          : null,
      recoupmentVO: {
        recoupmentTypeCode: values.recoupmentTypeCode
          ? values.recoupmentTypeCode === DEFAULT_DD_VALUE
            ? null
            : values.recoupmentTypeCode
          : null,
        installmentAmount: values.installmentValue
          ? values.installmentValue.toString().replace(/,/g, "")
          : values.installmentValue,
        percentageOriginalAmount: values.percentValue,
        startDateStr: selectedRecoupmentDate
          ? moment(new Date(selectedRecoupmentDate).getTime()).format(
            "MM/DD/YYYY"
          )
          : null,
        endDate: null,
        frequency: values.frequency
          ? values.frequency === DEFAULT_DD_VALUE
            ? null
            : values.frequency
          : null,
        releaseDateStr: null,
        returnDateStr: null
      },
      accountVO: {
        scoaFiscalYear: values.fiscalYear,
        cos: values.categoryOfService
          ? values.categoryOfService === DEFAULT_DD_VALUE
            ? null
            : values.categoryOfService
          : null,
        fundCode: values.collocationCode
          ? values.collocationCode === DEFAULT_DD_VALUE
            ? null
            : values.collocationCode
          : null
      },
      exemptionsVO: {
        interestExemptionsInd: values.exemptFromInterest === "Y" ? 1 : 0,
        penaltyExemptionsInd: values.exemptFromPenalty === "Y" ? 1 : 0,
        collectionAgencyInd: values.exemptFromCollectionAgency === "Y" ? 1 : 0
      },
      grossLevelReceivableVO: {
        appealIndicator: values.underAppeal === "Y" ? 1 : 0,
        beginDateStr: selectedBeginDate
          ? moment(new Date(selectedBeginDate).getTime()).format("MM/DD/YYYY")
          : null,
        endDateStr: selectedEndDate
          ? moment(new Date(selectedEndDate).getTime()).format("MM/DD/YYYY")
          : null
      },
      schedulePaymentList: paymentScheduleTemp,
      relatedFTTCN:
        lienLevy && lienLevy.grossLevelDetails
          ? lienLevy.grossLevelDetails.relatedFTTCN
          : null,
      voidIndicator: 0,
      adjustmentVO: {
        adjustmentActivityType: null,
        interest: null,
        penalty: null,
        fee: null,
        adjustmentReasonCode: null,
        establishedDate: null,
        balanceAmount: null,
        originalAmount: null
      },
      transferVO: {
        payeePayerID: null,
        payeePayerIDTypeCode: null,
        payeePayerTypeCode: null,
        balanceAmount: null,
        establishedDate: null,
        payeePayerIdFromDB: null,
        transferReasonCode: null,
        agingCode: null
      },
      noteSetVO: notesInput,
      paymentScheduleType:
        values.paymentScheduleType === DEFAULT_DD_VALUE
          ? null
          : values.paymentScheduleType,
      recoupmentStartDate: selectedRecoupmentStartDate
        ? moment(selectedRecoupmentStartDate).format("MM/DD/YYYY")
        : null,
      numberofInstallments: values.numberofInstallments
        ? values.numberofInstallments
        : null,
      interestCalculation: values.interestCalculation,
      interestPercentage:
        values.interestPercentage !== ""
          ? numeral(values.interestPercentage).format("0.00")
          : null,
      paymentScheduleFrequency:
        values.paymentScheduleFrequency !== DEFAULT_DD_VALUE
          ? values.paymentScheduleFrequency
          : null
    }));
    setRetainValues(values);
  };

  const resetPaymentSchedule = () => {
    setPopUpErrorMessage([]);
    seterrorMessages([]);
    setShowError(false);
    setShowErrorText("");
    setPaymentScheduleVO(retainPaymentSchedule);
    setSelectedDueDate(retainSelectedDueDate);
  };
  const calculateSchedule = () => {
    const errorArray = [];
    var originalArAmountRequired = false;
    var originalArAmountRequiredText = "";
    let paymentScheduleTypeText = "";
    let recoupmentStartDateText = "";
    let noOfInstallmentText = "";
    let frequencyText = "";
    let calPercentText = "";
    setPaymentScheduleTypeError("");
    setRecoupmentStartDateError("");
    setFrequencyError("");
    setNumberofinstallmentError("");
    setInstallmentCalError("");
    if (values.paymentScheduleType === DEFAULT_DD_VALUE) {
      paymentScheduleTypeText = ErrorConstants.PAYMENT_SCHEDULE_TYPE;
      errorArray.push(ErrorConstants.PAYMENT_SCHEDULE_TYPE);
      setPaymentScheduleTypeError(ErrorConstants.PAYMENT_SCHEDULE_TYPE);
    }
    if (
      values.originalARAmount === "" ||
      values.originalARAmount === undefined
    ) {
      originalArAmountRequired = true;
      originalArAmountRequiredText = ErrorConstants.ORIGINAL_AR_AMOUNT_REQUIRED;
      errorArray.push(originalArAmountRequiredText);
    }
    if (!selectedRecoupmentStartDate) {
      recoupmentStartDateText = ErrorConstants.RECOPUMENT_START_DATE;
      setRecoupmentStartDateError(ErrorConstants.RECOPUMENT_START_DATE);
      errorArray.push(ErrorConstants.RECOPUMENT_START_DATE);
    }

    if (selectedRecoupmentStartDate) {
      var startDate = new Date(selectedRecoupmentStartDate);
      var estDate = new Date(values.establishedDate);
      var currentDate = new Date(
        new Date().toLocaleString("en-US", {
          timeZone: process.env.REACT_APP_TIMEZONE
        })
      );

      if (!moment(startDate).isValid()) {
        recoupmentStartDateText = ErrorConstants.DATA_INVALID;
        setRecoupmentStartDateError(ErrorConstants.DATA_INVALID);
        errorArray.push(ErrorConstants.DATA_INVALID);
      } else if (
        validateDateMinimumValue(moment(startDate).format("MM/DD/YYYY"))
      ) {
        recoupmentStartDateText = AppConstants.DATE_ERROR_1964;
        setRecoupmentStartDateError(AppConstants.DATE_ERROR_1964);
        errorArray.push(minRecoupmentDateTextTemp);
      } else if (compareTwoDatesGreaterThanOrEqual(startDate, currentDate)) {
        const errorDateText = ErrorConstants.START_DATE_LESS_CURRENT_DATE_ERROR.replace(
          "{current date}",
          moment(currentDate).format("MM/DD/YYYY")
        );
        recoupmentStartDateText = errorDateText;
        setRecoupmentStartDateError(errorDateText);
        errorArray.push(errorDateText);
      }
      if (
        moment(startDate).isValid() &&
        !validateDateMinimumValue(moment(startDate).format("MM/DD/YYYY"))
      ) {
        if (compareTwoDatesGreaterThanOrEqual(startDate, estDate)) {
          recoupmentStartDateText =
            ErrorConstants.START_DATE_LESS_AR_CREATED_ERROR;
          setRecoupmentStartDateError(
            ErrorConstants.START_DATE_LESS_AR_CREATED_ERROR
          );
          errorArray.push(ErrorConstants.START_DATE_LESS_AR_CREATED_ERROR);
        }
      }
    }
    if (values.paymentScheduleFrequency === DEFAULT_DD_VALUE) {
      frequencyText = ErrorConstants.FREQUENCY;
      setFrequencyError(ErrorConstants.FREQUENCY);
      errorArray.push(ErrorConstants.FREQUENCY);
    }
    if (values.numberofInstallments === "") {
      noOfInstallmentText = ErrorConstants.NUMBER_OF_INSTALLMENTS;
      setNumberofinstallmentError(ErrorConstants.NUMBER_OF_INSTALLMENTS);
      errorArray.push(ErrorConstants.NUMBER_OF_INSTALLMENTS);
    }
    if (values.numberofInstallments !== "") {
      if (Number(values.numberofInstallments) > frequencyResult.valueNum) {
        noOfInstallmentText =
          ErrorConstants.INSTALLMENT_MORE +
          frequencyResult.valueNum.toString() +
          ".";
        setNumberofinstallmentError(
          ErrorConstants.INSTALLMENT_MORE +
          frequencyResult.valueNum.toString() +
          "."
        );
        errorArray.push(
          ErrorConstants.INSTALLMENT_MORE +
          frequencyResult.valueNum.toString() +
          "."
        );
      }
    }
    if (
      values.interestCalculation === "Y" &&
      values.interestPercentage === ""
    ) {
      calPercentText = ErrorConstants.INTEREST_PERCENT;
      setInstallmentCalError(ErrorConstants.INTEREST_PERCENT);
      errorArray.push(ErrorConstants.INTEREST_PERCENT);
    }
    if (
      values.interestPercentage !== "" &&
      values.interestPercentage
        .toString()
        .replace(/,/g, "")
        .match(/^[-]?[0-9]{0,7}\.?[0-9]{0,2}$/) === null &&
      values.paymentScheduleType === "A"
    ) {
      calPercentText = ErrorConstants.DATA_INVALID;
      setInstallmentCalError(ErrorConstants.DATA_INVALID);
      errorArray.push(ErrorConstants.DATA_INVALID);
    }
    if (errorArray.length === 0) {
      const scheduleArray = [];
      const amountStep = numeral(
        Number(
          Number(values.originalARAmount.toString().replace(/,/g, "")) /
          Number(values.numberofInstallments)
        )
      ).format("0.00");
      const numberOfRows = Number(
        numeral(
          Number(values.originalARAmount.toString().replace(/,/g, "")) /
          Number(amountStep)
        ).format("0")
      );
      const dateTemp = new Date(selectedRecoupmentStartDate);
      let daysStep = 0;
      switch (values.paymentScheduleFrequency) {
        case "2":
          daysStep = 7;
          break;
        case "3":
          daysStep = 14;
          break;
        case "4":
          daysStep = 30;
          break;
      }
      let sumOfInstallmentAmount = 0;
      for (let i = 0; i < numberOfRows; i++) {
        let value;
        if (i === 0) {
          value = {
            scheduleSK: null,
            dueDate: moment(dateTemp.setDate(dateTemp.getDate())).format(
              "MM/DD/YYYY"
            ),
            installmentAmount: numeral(amountStep).format("0.00"),
            recoupedAmount: "0.00"
          };
        } else {
          value = {
            scheduleSK: null,
            dueDate: moment(
              dateTemp.setDate(dateTemp.getDate() + daysStep)
            ).format("MM/DD/YYYY"),
            installmentAmount: numeral(amountStep).format("0.00"),
            recoupedAmount: "0.00"
          };
        }
        sumOfInstallmentAmount = numeral(
          Number(numeral(sumOfInstallmentAmount).format("0.00")) +
          Number(numeral(amountStep).format("0.00"))
        ).format("0.00");
        scheduleArray.push(value);
      }
      if (
        numeral(values.originalARAmount).format("0.00") !==
        numeral(sumOfInstallmentAmount).format("0.00")
      ) {
        scheduleArray[numberOfRows - 1].installmentAmount = numeral(
          parseFloat(
            numeral(scheduleArray[numberOfRows - 1].installmentAmount).format(
              "0.00"
            )
          ) +
          (parseFloat(numeral(values.originalARAmount).format("0.00")) -
            parseFloat(numeral(sumOfInstallmentAmount).format("0.00")))
        ).format("0,0.00");
      }
      setPaymentSchedleData(scheduleArray);
      setPaymentScheduleTypeError("");
      setRecoupmentStartDateError("");
      setFrequencyError("");
      setNumberofinstallmentError("");
      setInstallmentCalError("");
      seterrorMessages([]);
    } else {
      window.scrollTo(0, 0);
      setShowError({
        showOriginalArAmountPopError: originalArAmountRequired
      });
      setShowErrorText({
        showOriginalArAmountPopErrorText: originalArAmountRequiredText
      });
      setPaymentScheduleTypeError(paymentScheduleTypeText);
      setRecoupmentStartDateError(recoupmentStartDateText);
      setFrequencyError(frequencyText);
      setNumberofinstallmentError(noOfInstallmentText);
      setInstallmentCalError(calPercentText);
      seterrorMessages(errorArray);
    }
  };
  function PaymentRowDeleteClick () {
    let temNewDialogData = [...paymentScheduleData];
    for (
      let i = 0;
      i < rowPaymentScheduleData.rowPaymentScheduleData.length;
      i++
    ) {
      temNewDialogData = temNewDialogData.filter(
        payment =>
          payment.id !== rowPaymentScheduleData.rowPaymentScheduleData[i]
      );
    }
    setPaymentSchedleData(temNewDialogData);
    setRowPaymentScheduleData([]);
  }

  const fieldDescription = (dropdownFilter, Code) => {
    const filteredValue = dropdownFilter.filter(
      (rCode) => rCode.code === Code
    );
    if (filteredValue && filteredValue.length > 0) {
      return filteredValue[0].description;
    }
    return Code;
  };

  const handleChange = name => event => {
    setFocusCheck(true);
    setAllowNavigation(true);
    if (
      name === "paymentScheduleType" &&
      values.paymentScheduleType !== event.target.value
    ) {
      setPaymentScheduleChanged(generateUUID());
      setPaymentScheduleTypeError("");
      setRecoupmentStartDateError("");
      setFrequencyError("");
      setNumberofinstallmentError("");
      setInstallmentCalError("");
    }
    if (name === "interestCalculation") {
      setValues({
        ...values,
        [name]: event.target.value,
        interestPercentage: ""
      });
    } else {
      setValues({ ...values, [name]: event.target.value });
    }
    if (
      name === "numberofInstallments" ||
      name === "paymentScheduleFrequency"
    ) {
      setPaymentSchedleData([]);
    }
    if (
      name === "lienHolderType" &&
      event.target.value !== DEFAULT_DD_VALUE
    ) {
      const payeeTypeDesc = fieldDescription(
        lienHolderTypeData,
        event.target.value
      );
      setPayeeTypeDesc(payeeTypeDesc);
    }
    if (
      name === "lienHolderIdType" &&
      event.target.value !== DEFAULT_DD_VALUE
    ) {
      const payeeTypeIdDesc = fieldDescription(
        lienHolderIdTypeData,
        event.target.value
      );
      setPayeeTypeIdCodeDesc(payeeTypeIdDesc);
    }
    if (name === 'recoupmentTypeCode') {
      setRecoupmentTypeClicked(false);
    }
  };

  const handleChangeForDecimalValues = name => event => {
    setFocusCheck(true);
    setAllowNavigation(true);
    if (name === "installmentValue") {
      if (event.target.value && event.target.value.includes('.')) {
        if (commonMethods.checkingDecimal(event.target.value)) {
          setValues({ ...values, [name]: event.target.value });
        }
      } else {
        setValues({ ...values, [name]: event.target.value });
      }
    } else if (name === "originalARAmount") {
      if (event.target.value && event.target.value.includes('.')) {
        if (commonMethods.checkingDecimal(event.target.value)) {
          setValues({ ...values, [name]: event.target.value });
        }
      } else {
        setValues({ ...values, [name]: event.target.value });
      }
    } else {
      setValues({ ...values, [name]: event.target.value });
    }
  };
  var showOriginalArAmmountErrorTemp = false;
  var showApprovalErrorTemp = false;
  var showFiscalYearErrorTemp = false;
  var showLienHolderTypeErrorTemp = false;
  var showLienHolderTypeCodeErrorTemp = false;
  var showLienHolderIDErrorTemp = false;
  var showCollocationCodeErrorTemp = false;
  var showRecoupmentTypeCodeErrorTemp = false;
  var showInvalidBeginDateErrorTemp = false;
  var showInvalidEndDateErrorTemp;
  var showEndDateLesserErrorTemp;
  var showBeginDateRquiredErrorTemp = false;
  var showEndDateRequiredErrorTemp = false;
  var showPercentErrorTemp = false;
  var showInstallmentErrorTemp = false;
  var showATRErrorTemp = false;
  var startDateErrorTemp = false;
  var startDateReqErrorTemp = false;
  var minimumBeginDateError = false;
  var minimumEndDateError = false;
  var minimumRecoupmentDaateError = false;
  var showOriginalArAmountErrorTextTemp = "";
  var showApprovalErrorTextTemp = "";
  var showLienHolderTypeErrorTextTemp = "";
  var showLienHolderTypeCodeErrorTextTemp = "";
  var showLienHolderIDTextTemp = "";
  var showFiscalYearErrorTextTemp = "";
  var showCollocationCodeErrorTextTemp = "";
  var showRecoupmentTypeCodeErrorTextTemp = "";
  var showATRErrorTextTemp = '';
  var showInvalidBeginDateTextTemp = "";
  var showInvalidEndDateInvalidTextTemp = "";
  var showEndDateLesserErrorTextTemp = "";
  var showBeginDateRequiredErrorTextTemp = "";
  var showEndDateRequiredErrorTextTemp = "";
  var minBeginDateTextTemp = "";
  var minEndDateTextTemp = "";
  var minRecoupmentDateTextTemp = "";
  var showPercentErrorTextTemp = "";
  var showInstallmentErrorTextTemp = "";
  var showStartDateErrorTextTemp = "";
  var startDateReqErrorTextTemp = "";

  const checkFormValidations = () => {
    const regexATR = /[^A-Za-z0-9]/;
    var showFrequencyErrorText = "";
    var showFrequencyError = false;
    var checkFormat = false;
    if (paymentScheduleData.length > 0 && values.recoupmentTypeCode === "S") {
      let summation = 0;
      paymentScheduleData.map(value => {
        summation =
          summation + Number(numeral(value.installmentAmount).format("0.00"));
      });
      if (
        numeral(summation.toString()).format("0.00") !==
        numeral(
          values.originalARAmount
            .toString()
            .replace(/,/g, "")
            .replace("$", "")
            .trim()
        ).format("0.00")
      ) {
        errorMessagesArray.push(ErrorConstants.SUM_AMOUNT);
      }
    }
    if (
      values.originalARAmount === "" ||
      values.originalARAmount === undefined ||
      values.originalARAmount === null
    ) {
      showOriginalArAmmountErrorTemp = true;
      showOriginalArAmountErrorTextTemp =
        ErrorConstants.ORIGINAL_AR_AMOUNT_REQUIRED;
      errorMessagesArray.push(showOriginalArAmountErrorTextTemp);
    }
    if (
      IsF16007 &&
      values.recoupmentTypeCode !== "N" &&
      values.recoupmentTypeCode !== "A" &&
      values.recoupmentTypeCode !== "B"
    ) {
      showRecoupmentTypeCodeErrorTemp = true;
      showRecoupmentTypeCodeErrorTextTemp =
        ErrorConstants.INVALID_RECOUPMENT_TYPE;
      errorMessagesArray.push(showRecoupmentTypeCodeErrorTextTemp);
    }

    if (values.atrNumber && regexATR.test(values.atrNumber)) {
      showATRErrorTemp = true;
      showATRErrorTextTemp = `${process.env.REACT_APP_TRANSACTION_NUMBER_LABEL} ${ErrorConstants.TRANSACTION_NUMBER_FORMAT}`;
      errorMessagesArray.push(showATRErrorTextTemp);
    } else (showATRErrorTemp = false);
    if (
      values.originalARAmount &&
      values.originalARAmount
        .toString()
        .replace(/,/g, "")
        .match(/^[-]?[0-9]{0,9}\.?[0-9]{0,2}$/) === null
    ) {
      showOriginalArAmmountErrorTemp = true;
      showOriginalArAmountErrorTextTemp = ErrorConstants.INCORRECT_DATA_FORMAT;
      checkFormat = true;
    }

    if (values.fiscalYear === "" || values.fiscalYear === undefined) {
      showFiscalYearErrorTemp = true;
      showFiscalYearErrorTextTemp = ErrorConstants.FISCAL_YEAR_REQUIRED;
      errorMessagesArray.push(showFiscalYearErrorTextTemp);
    }

    if (
      values.lienHolderType === "" ||
      values.lienHolderType === DEFAULT_DD_VALUE ||
      values.lienHolderType === undefined
    ) {
      showLienHolderTypeErrorTemp = true;
      showLienHolderTypeErrorTextTemp =
        ErrorConstants.LIEN_HOLDER_TYPE_REQUIRED;
      errorMessagesArray.push(showLienHolderTypeErrorTextTemp);
    }
    if (
      values.lienHolderIdType === "" ||
      values.lienHolderIdType === DEFAULT_DD_VALUE ||
      values.lienHolderIdType === undefined
    ) {
      showLienHolderTypeCodeErrorTemp = true;
      showLienHolderTypeCodeErrorTextTemp =
        ErrorConstants.LIEN_HOLDER_TYPE_CODE_REQUIRED;
      errorMessagesArray.push(showLienHolderTypeCodeErrorTextTemp);
    }
    if (values.lienHolderId === "" || values.lienHolderId === undefined) {
      showLienHolderIDErrorTemp = true;
      showLienHolderIDTextTemp = ErrorConstants.LIEN_HOLDER_ID_REQUIRED;
      errorMessagesArray.push(showLienHolderIDTextTemp);
    }
    if (
      values.collocationCode === "" ||
      values.collocationCode === DEFAULT_DD_VALUE ||
      values.collocationCode === undefined
    ) {
      showCollocationCodeErrorTemp = true;
      showCollocationCodeErrorTextTemp = ErrorConstants.COLLOCATION_CODE;
      errorMessagesArray.push(showCollocationCodeErrorTextTemp);
    }

    if (
      values.recoupmentTypeCode === "" ||
      values.recoupmentTypeCode === DEFAULT_DD_VALUE ||
      values.recoupmentTypeCode === undefined
    ) {
      showRecoupmentTypeCodeErrorTemp = true;
      showRecoupmentTypeCodeErrorTextTemp =
        ErrorConstants.RECOUPMENT_TYPE_CODE_REQUIRED;
      errorMessagesArray.push(showRecoupmentTypeCodeErrorTextTemp);
    }

    if (selectedBeginDate) {
      if (!moment(selectedBeginDate).isValid()) {
        showInvalidBeginDateErrorTemp = true;
        showInvalidBeginDateTextTemp = ErrorConstants.BEGIN_DATE_INVALID;
        errorMessagesArray.push(showInvalidBeginDateTextTemp);
      }
    }

    if (selectedEndDate) {
      if (!moment(selectedEndDate).isValid()) {
        showInvalidEndDateErrorTemp = true;
        showInvalidEndDateInvalidTextTemp = ErrorConstants.BEGIN_DATE_INVALID;
        errorMessagesArray.push(showInvalidEndDateInvalidTextTemp);
      }
    }

    if (
      selectedBeginDate &&
      selectedEndDate &&
      compareTwoDates(new Date(selectedEndDate), new Date(selectedBeginDate))
    ) {
      showEndDateLesserErrorTemp = true;
      showEndDateLesserErrorTextTemp = ErrorConstants.END_DATE_ERROR;
      errorMessagesArray.push(showEndDateLesserErrorTextTemp);
    }

    if (values.reasonCode === "102") {
      if (!selectedBeginDate) {
        showBeginDateRquiredErrorTemp = true;
        showBeginDateRequiredErrorTextTemp = ErrorConstants.BEGIN_DATE_REQUIRED;
        errorMessagesArray.push(showBeginDateRequiredErrorTextTemp);
      }
      if (!selectedEndDate) {
        showEndDateRequiredErrorTemp = true;
        showEndDateRequiredErrorTextTemp = ErrorConstants.END_DATE_REQUIRED;
        errorMessagesArray.push(showEndDateRequiredErrorTextTemp);
      }

      if (selectedBeginDate) {
        if (!moment(selectedBeginDate).isValid()) {
          showInvalidEndDateErrorTemp = true;
          showInvalidBeginDateTextTemp = ErrorConstants.BEGIN_DATE_INVALID;
          errorMessagesArray.push(showInvalidBeginDateTextTemp);
        }
      }

      if (selectedEndDate) {
        if (!moment(selectedEndDate).isValid()) {
          showInvalidEndDateErrorTemp = true;
          showInvalidEndDateInvalidTextTemp = ErrorConstants.BEGIN_DATE_INVALID;
          errorMessagesArray.push(showInvalidEndDateInvalidTextTemp);
        }
      }
    }

    if (values.percentValue) {
      var regexPercent = /^[0-9]{1,11}(\.\d{1,2})?%?$/;
      if (!regexPercent.test(values.percentValue)) {
        showPercentErrorTemp = true;
        showPercentErrorTextTemp = ErrorConstants.PERCENT_INVALID_ERROR;
        checkFormat = true;
      }
    }

    if (
      values.percentValue &&
      values.percentValue
        .toString()
        .replace(/,/g, "")
        .match(/^[-]?[0-9]{0,11}\.?[0-9]{0,2}$/) === null
    ) {
      showPercentErrorTemp = true;
      showPercentErrorTextTemp = ErrorConstants.PERCENT_INVALID_ERROR;
      checkFormat = true;
    }

    if (values.percentValue && values.percentValue > 100) {
      showPercentErrorTemp = true;
      showPercentErrorTextTemp =
        ErrorConstants.PERCENT_VALUE_CAN_NOT_GREATER_100;
      errorMessagesArray.push(showPercentErrorTextTemp);
      errorMessagesArray.push(ErrorConstants.PERCENT_MUST_BE_WITHIN_0_100);
    }
    if (
      values.recoupmentTypeCode !== "N" &&
      values.percentValue &&
      values.percentValue === 0
    ) {
      showPercentErrorTemp = true;
      showPercentErrorTextTemp = ErrorConstants.PERCENT_ZERO;
      errorMessagesArray.push(showPercentErrorTextTemp);
      errorMessagesArray.push(ErrorConstants.PERCENT_MUST_BE_WITHIN_0_100);
    }
    if (values.percentValue && values.percentValue < 0) {
      showPercentErrorTemp = true;
      showPercentErrorTextTemp = ErrorConstants.PERCENT_NEGATIVE_ERROR;
      errorMessagesArray.push(showPercentErrorTextTemp);
      errorMessagesArray.push(ErrorConstants.PERCENT_MUST_BE_WITHIN_0_100);
    }

    if (values.installmentValue) {
      var regex = /^[0-9]{1,11}(\.\d{1,2})?%?$/;
      if (!regex.test(values.installmentValue.toString().replace(/,/g, ""))) {
        showInstallmentErrorTemp = true;
        showInstallmentErrorTextTemp = ErrorConstants.INSTALLMENT_INVALID;
        checkFormat = true;
      }
    }

    if (values.installmentValue && parseFloat(values.installmentValue) < 0) {
      showInstallmentErrorTemp = true;
      showInstallmentErrorTextTemp = ErrorConstants.INSTALLMENT_NEGATIVE_ERROR;
      errorMessagesArray.push(showInstallmentErrorTextTemp);
      errorMessagesArray.push(ErrorConstants.INSTALLMENT_MUST_BE_GREATER_0);
    } else if (
      values.installmentValue &&
      values.originalARAmount &&
      parseFloat(values.installmentValue.toString().replace(/,/g, "")) >
      parseFloat(values.originalARAmount.toString().replace(/,/g, "")) &&
      !commonMethods.checkValueExists(dropDownCheckList, values.reasonCode)
    ) {
      showInstallmentErrorTemp = true;
      showInstallmentErrorTextTemp =
        ErrorConstants.INSTALLMENT_GREATER_ORIGINAL_AMOUNT_ERROR;
      errorMessagesArray.push(showInstallmentErrorTextTemp);
    }
    if (
      values.installmentValue &&
      values.installmentValue
        .toString()
        .replace(/,/g, "")
        .match(/^[-]?[0-9]{0,11}\.?[0-9]{0,2}$/) === null
    ) {
      showInstallmentErrorTemp = true;
      showInstallmentErrorTextTemp = ErrorConstants.INSTALLMENT_INVALID;
      checkFormat = true;
    }

    if (values.recoupmentTypeCode === "B" && !IsF16007) {
      if (!selectedRecoupmentDate && !values.percentValue) {
        errorMessagesArray.push(ErrorConstants.START_DATE_PERCENT_REQUIRED);
        showPercentErrorTemp = true;
        showPercentErrorTextTemp = ErrorConstants.PERCENT_REQUIRED;
        startDateReqErrorTemp = true;
        startDateReqErrorTextTemp = ErrorConstants.START_DATE_REQUIRED;
      } else {
        if (!values.percentValue) {
          showPercentErrorTemp = true;
          showPercentErrorTextTemp = ErrorConstants.PERCENT_REQUIRED;
          errorMessagesArray.push(showPercentErrorTextTemp);
        }
        if (!selectedRecoupmentDate) {
          startDateReqErrorTemp = true;
          startDateReqErrorTextTemp = ErrorConstants.START_DATE_REQUIRED;
          errorMessagesArray.push(startDateReqErrorTextTemp);
        }
      }
    }
    if(values.recoupmentTypeCode === AppConstants.RECOUPMENTTYPECODE_PERCENTAMT || 
      values.recoupmentTypeCode ===AppConstants.RECOUPMENTTYPECODE_DOLLARAMT){
      if(values.frequency === undefined ||
      values.frequency === DEFAULT_DD_VALUE ||
      values.frequency ==="" ){
        showFrequencyError = true;
        showFrequencyErrorText = ErrorConstants.FREQUENCY;
        errorMessagesArray.push(showFrequencyErrorText);
      }
    }
    if (values.recoupmentTypeCode === "D") {
      const RECOUPMENT_PERCENT_AND_DATE_REQUIRED =
        ErrorConstants.RECOUPMENT_PERCENT_AND_DATE_REQUIRED;
      if (!values.percentValue && !selectedRecoupmentDate) {
        showPercentErrorTemp = true;
        showPercentErrorTextTemp = ErrorConstants.PERCENT_REQUIRED;
        startDateReqErrorTemp = true;
        startDateReqErrorTextTemp = ErrorConstants.START_DATE_REQUIRED;
        errorMessagesArray.push(RECOUPMENT_PERCENT_AND_DATE_REQUIRED);
      } else {
        if (!values.percentValue) {
          showPercentErrorTemp = true;
          showPercentErrorTextTemp = ErrorConstants.PERCENT_REQUIRED;
          errorMessagesArray.push(showPercentErrorTextTemp);
        }
        if (!selectedRecoupmentDate) {
          startDateReqErrorTemp = true;
          startDateReqErrorTextTemp = ErrorConstants.START_DATE_REQUIRED;
          errorMessagesArray.push(startDateReqErrorTextTemp);
        }
      }
    }

    if (values.recoupmentTypeCode && values.recoupmentTypeCode === "B") {
      if (!selectedRecoupmentDate && IsF16007) {
        startDateReqErrorTemp = true;
        startDateReqErrorTextTemp = ErrorConstants.START_DATE_REQUIRED;
        errorMessagesArray.push(startDateReqErrorTextTemp);
      }

      if (values.installmentValue !== null && values.installmentValue !== "") {
        showInstallmentErrorTemp = true;
        showInstallmentErrorTextTemp = ErrorConstants.INSTALLMENT_MUST_BE_BLANK;
        errorMessagesArray.push(showInstallmentErrorTextTemp);
        if (values.percentValue) {
          errorMessagesArray.push(ErrorConstants.BOTH_VALUES_CANNOT_ENTER);
        }
      }
    }

    if (values.recoupmentTypeCode === "A") {
      if (!selectedRecoupmentDate && !values.installmentValue) {
        errorMessagesArray.push(ErrorConstants.INSTALLMENT_START_DATE_REQUIRED);
        showInstallmentErrorTemp = true;
        showInstallmentErrorTextTemp = ErrorConstants.INSTALLMENT_REQUIRED;
        startDateReqErrorTemp = true;
        startDateReqErrorTextTemp = ErrorConstants.START_DATE_REQUIRED;
      } else {
        if (!values.installmentValue) {
          showInstallmentErrorTemp = true;
          showInstallmentErrorTextTemp = ErrorConstants.INSTALLMENT_REQUIRED;
          errorMessagesArray.push(showInstallmentErrorTextTemp);
        } else if (parseInt(values.installmentValue) === 0) {
          showInstallmentErrorTemp = true;
          showInstallmentErrorTextTemp =
            ErrorConstants.INSTALLMENT_MUST_BE_GREATER_0;
          errorMessagesArray.push(showInstallmentErrorTextTemp);
        }
        if (!selectedRecoupmentDate) {
          startDateReqErrorTemp = true;
          startDateReqErrorTextTemp = ErrorConstants.START_DATE_REQUIRED;
          errorMessagesArray.push(startDateReqErrorTextTemp);
        }
      }
      if (values.percentValue !== "") {
        if (!values.installmentValue) {
          showPercentErrorTemp = true;
          showPercentErrorTextTemp = ErrorConstants.PERCENT_MUST_BE_BLANK;
          errorMessagesArray.push(showPercentErrorTextTemp);
        } else {
          showPercentErrorTemp = true;
          showPercentErrorTextTemp = ErrorConstants.PERCENT_MUST_BE_BLANK;
          errorMessagesArray.push(showPercentErrorTextTemp);
          errorMessagesArray.push(ErrorConstants.BOTH_VALUES_CANNOT_ENTER);
        }
      }
    }

    if (values.recoupmentTypeCode && values.recoupmentTypeCode === "N") {
      if (
        parseInt(values.installmentValue) > 0 ||
        parseInt(values.installmentValue) < 0 ||
        parseInt(values.percentValue) > 0 ||
        parseInt(values.percentValue) < 0
      ) {
        errorMessagesArray.push(ErrorConstants.INSTALLMENT_PERCENT_MUST_BE_0);
        if (values.percentValue !== "") {
          if (
            (values.percentValue !== "0" && values.percentValue !== "0.00") ||
            parseInt(values.percentValue) > 0 ||
            parseInt(values.percentValue) < 0
          ) {
            showPercentErrorTemp = true;
            showPercentErrorTextTemp =
              ErrorConstants.INSTALLMENT_PERCENT_MUST_BE_0;
          }
        }
        if (values.installmentValue !== "") {
          if (
            (values.installmentValue !== "0" &&
              values.installmentValue !== "0.00") ||
            parseInt(values.installmentValue) > 0 ||
            parseInt(values.installmentValue) < 0
          ) {
            showInstallmentErrorTemp = true;
            showInstallmentErrorTextTemp =
              ErrorConstants.INSTALLMENT_PERCENT_MUST_BE_0;
          }
        }
      }

      if (selectedRecoupmentDate) {
        startDateReqErrorTemp = true;
        startDateReqErrorTextTemp = ErrorConstants.START_DATE_BLANK;
        errorMessagesArray.push(startDateReqErrorTextTemp);
      }

      if (
        values.frequency &&
        values.frequency !== DEFAULT_DD_VALUE
      ) {
        showFrequencyError = true;
        showFrequencyErrorText = ErrorConstants.FREQUENCY_MUST_BE_BLANK;
        errorMessagesArray.push(showFrequencyErrorText);
      }
    }

    if (selectedRecoupmentDate) {
      var startDate = new Date(selectedRecoupmentDate);
      var estDate = new Date(values.establishedDate);
      var currentDate = new Date(
        new Date().toLocaleString("en-US", {
          timeZone: process.env.REACT_APP_TIMEZONE
        })
      );

      if (!moment(startDate).isValid()) {
        startDateReqErrorTemp = true;
        startDateReqErrorTextTemp = ErrorConstants.Invalid_Format_Error;
        errorMessagesArray.push(startDateReqErrorTextTemp);
      } else if (
        validateDateMinimumValue(moment(startDate).format("MM/DD/YYYY"))
      ) {
        minimumRecoupmentDaateError = true;
        minRecoupmentDateTextTemp = AppConstants.DATE_ERROR_1964;
        errorMessagesArray.push(minRecoupmentDateTextTemp);
      } else if (compareTwoDatesGreaterThanOrEqual(startDate, currentDate)) {
        startDateReqErrorTemp = true;
        startDateReqErrorTextTemp = ErrorConstants.START_DATE_LESS_CURRENT_DATE_ERROR.replace(
          "{current date}",
          moment(currentDate).format("MM/DD/YYYY")
        );
        errorMessagesArray.push(startDateReqErrorTextTemp);
      }
      if (
        moment(startDate).isValid() &&
        !validateDateMinimumValue(moment(startDate).format("MM/DD/YYYY"))
      ) {
        if (compareTwoDatesGreaterThanOrEqual(startDate, estDate)) {
          errorMessagesArray.push(
            ErrorConstants.START_DATE_LESS_AR_CREATED_ERROR
          );
        }
      }
    }

    if (checkFormat) {
      errorMessagesArray.push(ErrorConstants.INCORRECT_DATA_FORMAT);
      checkFormat = false;
    }
    // Leinholder ID validation
    if (values.lienHolderId === values.payeeId) {
      showLienHolderIDErrorTemp = true;
      showLienHolderIDTextTemp = ErrorConstants.LIEN_HOLDER_ID_SAME_ERR;
      errorMessagesArray.push(showLienHolderIDTextTemp);
    }
    setShowError({
      showOriginalArAmmountError: showOriginalArAmmountErrorTemp,
      showApprovalError: showApprovalErrorTemp,
      showFiscalYearError: showFiscalYearErrorTemp,
      showLienHolderTypeError: showLienHolderTypeErrorTemp,
      showLienHolderTypeCodeError: showLienHolderTypeCodeErrorTemp,
      showLienHolderIDError: showLienHolderIDErrorTemp,
      showCollocationCodeError: showCollocationCodeErrorTemp,
      showRecoupmentTypeCodeError: showRecoupmentTypeCodeErrorTemp,
      showInvalidBeginDateError: showInvalidBeginDateErrorTemp,
      showInvalidEndDateError: showInvalidEndDateErrorTemp,
      showEndDateLesserError: showEndDateLesserErrorTemp,
      showBeginDateRequiredError: showBeginDateRquiredErrorTemp,
      showEndDateRequiredError: showEndDateRequiredErrorTemp,
      showPercentError: showPercentErrorTemp,
      showInstallementError: showInstallmentErrorTemp,
      showStartDateError: startDateErrorTemp,
      showStartDateRequiredError: startDateReqErrorTemp,
      showMinBeginDateError: minimumBeginDateError,
      showMinEndDateError: minimumEndDateError,
      showMinRecoupmentDateError: minimumRecoupmentDaateError,
      showFrequencyError: showFrequencyError,
      showATRError: showATRErrorTemp
    });
    setShowErrorText({
      showOriginalArAmountErrorText: showOriginalArAmountErrorTextTemp,
      showApprovalErrorText: showApprovalErrorTextTemp,
      showFiscalYearErrorText: showFiscalYearErrorTextTemp,
      showLienHolderTypeErrorText: showLienHolderTypeErrorTextTemp,
      showLienHolderTypeCodeErrorText: showLienHolderTypeCodeErrorTextTemp,
      showLienHolderIDText: showLienHolderIDTextTemp,
      showCollocationCodeErrorText: showCollocationCodeErrorTextTemp,
      showRecoupmentTypeCodeErrorText: showRecoupmentTypeCodeErrorTextTemp,
      showATRErrorText: showATRErrorTextTemp,
      showInvalidBeginDateText: showInvalidBeginDateTextTemp,
      showInvalidEndDateInvalidText: showInvalidEndDateInvalidTextTemp,
      showEndDateLesserErrorText: showEndDateLesserErrorTextTemp,
      showBeginDateRequiredErrorText: showBeginDateRequiredErrorTextTemp,
      showEndDateRequiredErrorText: showEndDateRequiredErrorTextTemp,
      showPercentErrorText: showPercentErrorTextTemp,
      showInstallmentErrorText: showInstallmentErrorTextTemp,
      showStartDateErrorText: showStartDateErrorTextTemp,
      showStartDateRequiredErrorText: startDateReqErrorTextTemp,
      showMinBeginDateErrorText: minBeginDateTextTemp,
      showMinEndDateErrorText: minEndDateTextTemp,
      showMinRecoupmentDateErrorText: minRecoupmentDateTextTemp,
      showFrequencyErrorText: showFrequencyErrorText
    });
    seterrorMessages(errorMessagesArray);
    return true;
  };

  const saveLienLevyAdd = () => {
    checkFormValidations();
    if (values.recoupmentTypeCode === "S") {
      const errorArray = [];
      var originalArAmountRequired = false;
      var originalArAmountRequiredText = "";
      let paymentScheduleTypeText = "";
      let recoupmentStartDateText = "";
      let noOfInstallmentText = "";
      let frequencyText = "";
      let calPercentText = "";
      if (values.paymentScheduleType === DEFAULT_DD_VALUE) {
        paymentScheduleTypeText = ErrorConstants.PAYMENT_SCHEDULE_TYPE;
        errorArray.push(ErrorConstants.PAYMENT_SCHEDULE_TYPE);
        setPaymentScheduleTypeError(ErrorConstants.PAYMENT_SCHEDULE_TYPE);
      }
      if (
        values.originalARAmount === "" ||
        values.originalARAmount === undefined
      ) {
        originalArAmountRequired = true;
        originalArAmountRequiredText =
          ErrorConstants.ORIGINAL_AR_AMOUNT_REQUIRED;
        errorArray.push(originalArAmountRequiredText);
      }
      if (!selectedRecoupmentStartDate) {
        recoupmentStartDateText = ErrorConstants.RECOPUMENT_START_DATE;
        setRecoupmentStartDateError(ErrorConstants.RECOPUMENT_START_DATE);
        errorArray.push(ErrorConstants.RECOPUMENT_START_DATE);
      }

      if (selectedRecoupmentStartDate) {
        var startDate = new Date(selectedRecoupmentStartDate);
        var estDate = new Date(values.establishedDate);
        var currentDate = new Date(
          new Date().toLocaleString("en-US", {
            timeZone: process.env.REACT_APP_TIMEZONE
          })
        );

        if (!moment(startDate).isValid()) {
          recoupmentStartDateText = ErrorConstants.DATA_INVALID;
          setRecoupmentStartDateError(ErrorConstants.DATA_INVALID);
          errorArray.push(ErrorConstants.DATA_INVALID);
        } else if (
          validateDateMinimumValue(moment(startDate).format("MM/DD/YYYY"))
        ) {
          recoupmentStartDateText = AppConstants.DATE_ERROR_1964;
          setRecoupmentStartDateError(AppConstants.DATE_ERROR_1964);
          errorArray.push(minRecoupmentDateTextTemp);
        } else if (compareTwoDatesGreaterThanOrEqual(startDate, currentDate)) {
          const errorDateText = ErrorConstants.START_DATE_LESS_CURRENT_DATE_ERROR.replace(
            "{current date}",
            moment(currentDate).format("MM/DD/YYYY")
          );
          recoupmentStartDateText = errorDateText;
          setRecoupmentStartDateError(errorDateText);
          errorArray.push(errorDateText);
        }
        if (
          moment(startDate).isValid() &&
          !validateDateMinimumValue(moment(startDate).format("MM/DD/YYYY"))
        ) {
          if (compareTwoDatesGreaterThanOrEqual(startDate, estDate)) {
            recoupmentStartDateText =
              ErrorConstants.START_DATE_LESS_AR_CREATED_ERROR;
            setRecoupmentStartDateError(
              ErrorConstants.START_DATE_LESS_AR_CREATED_ERROR
            );
            errorArray.push(ErrorConstants.START_DATE_LESS_AR_CREATED_ERROR);
          }
        }
      }
      if (
        values.paymentScheduleFrequency === DEFAULT_DD_VALUE &&
        values.paymentScheduleType === "A"
      ) {
        frequencyText = ErrorConstants.FREQUENCY;
        setFrequencyError(ErrorConstants.FREQUENCY);
        errorArray.push(ErrorConstants.FREQUENCY);
      }
      if (
        values.numberofInstallments === "" &&
        values.paymentScheduleType === "A"
      ) {
        noOfInstallmentText = ErrorConstants.NUMBER_OF_INSTALLMENTS;
        setNumberofinstallmentError(ErrorConstants.NUMBER_OF_INSTALLMENTS);
        errorArray.push(ErrorConstants.NUMBER_OF_INSTALLMENTS);
      }
      if (
        values.numberofInstallments !== "" &&
        values.paymentScheduleType === "A"
      ) {
        if (Number(values.numberofInstallments) > frequencyResult.valueNum) {
          noOfInstallmentText =
            ErrorConstants.INSTALLMENT_MORE +
            frequencyResult.valueNum.toString() +
            ".";
          setNumberofinstallmentError(
            ErrorConstants.INSTALLMENT_MORE +
            frequencyResult.valueNum.toString() +
            "."
          );
          errorArray.push(
            ErrorConstants.INSTALLMENT_MORE +
            frequencyResult.valueNum.toString() +
            "."
          );
        }
      }
      if (
        values.interestCalculation === "Y" &&
        values.interestPercentage === "" &&
        values.paymentScheduleType === "A"
      ) {
        if (!errorMessagesArray.includes(ErrorConstants.INTEREST_PERCENT)) {
          calPercentText = ErrorConstants.INTEREST_PERCENT;
          setInstallmentCalError(ErrorConstants.INTEREST_PERCENT);
          errorArray.push(ErrorConstants.INTEREST_PERCENT);
        }
      }
      if (
        values.interestPercentage !== "" &&
        values.interestPercentage
          .toString()
          .replace(/,/g, "")
          .match(/^[-]?[0-9]{0,7}\.?[0-9]{0,2}$/) === null &&
        values.paymentScheduleType === "A"
      ) {
        calPercentText = ErrorConstants.DATA_INVALID;
        setInstallmentCalError(ErrorConstants.DATA_INVALID);
        errorArray.push(ErrorConstants.DATA_INVALID);
      }
      if (errorArray.length === 0) {
        setPaymentScheduleTypeError("");
        setRecoupmentStartDateError("");
        setFrequencyError("");
        setNumberofinstallmentError("");
        setInstallmentCalError("");
      } else {
        setPaymentScheduleTypeError(paymentScheduleTypeText);
        setRecoupmentStartDateError(recoupmentStartDateText);
        setFrequencyError(frequencyText);
        setNumberofinstallmentError(noOfInstallmentText);
        setInstallmentCalError(calPercentText);
        if (errorArray.length !== 0) {
          errorArray.map(value => {
            errorMessagesArray.push(value);
          });
        }
      }
    }
    if (errorMessagesArray.length <= 0) {
      setFocusCheck(false);

      let estDate =
        lienLevy && lienLevy.grossLevelDetails
          ? lienLevy.grossLevelDetails.establishedDate
          : null;
      estDate = new Date(estDate);
      const year = estDate.getFullYear();
      let date = estDate.getDate();
      let month = estDate.getMonth() + 1;
      if (date < 10) {
        date = "0" + date;
      }
      if (month < 10) {
        month = "0" + month;
      }

      const estDateFormatted = `${month}/${date}/${year}`;
      checkFormValidations();
      const paymentScheduleTemp = paymentScheduleData;
      paymentScheduleTemp.map(value => {
        value.auditUserID = logInUserID;
        value.auditTimeStamp = getUTCTimeStamp();
        value.addedAuditUserID = logInUserID;
        value.addedAuditTimeStamp = getUTCTimeStamp();
        value.installmentAmount = numeral(value.installmentAmount).format(
          "0.00"
        );
        value.recoupedAmount = numeral(value.recoupedAmount).format("0.00");
      });
      const value = commonMethods.removeLeadingTrailingSpaces({
        adjustedArAmt: 0,
        atrNumber: values?.atrNumber?.toUpperCase(),
        versionNoForFinancialDetail: 0,
        versionNo: 0,
        fcnMediaCode: null,
        fcnSequenceNo:
          lienLevy && lienLevy.grossLevelDetails
            ? lienLevy.grossLevelDetails.fcnNumber.slice(
              lienLevy.grossLevelDetails.fcnNumber.length - 5,
              lienLevy.grossLevelDetails.fcnNumber.length
            )
            : 0,
        receivableFCN:
          lienLevy && lienLevy.grossLevelDetails
            ? lienLevy.grossLevelDetails.fcnNumber
            : 0,
        payeePayerID: values.payeeId,
        payeePayerIDTypeCode:
          lienLevy && lienLevy.grossLevelDetails
            ? lienLevy.payeeDetails.payeePayerIdTypeCode
            : "",
        payeePayerTypeCode:
          lienLevy && lienLevy.payeeDetails
            ? lienLevy.payeeDetails.payeePayerType
            : "",
        provBillSysId:
          lienLevy && lienLevy.payeeDetails.billProvSysId
            ? lienLevy.payeeDetails.billProvSysId
            : "",
        auditUserID: logInUserID,
        auditTimeStamp: getUTCTimeStamp(),
        addedAuditUserID: logInUserID,
        addedAuditTimeStamp: getUTCTimeStamp(),
        payeePayerIdFromDB:
          lienLevy && lienLevy.grossLevelDetails
            ? lienLevy.payeeDetails.systemPayeeId
            : 0,
        withholdingVO: commonMethods.removeLeadingTrailingSpaces({
          thirdPartyID: values.lienHolderId,
          thirdPartyIDFromDB: values.thirdPartyIDFromDB
            ? values.thirdPartyIDFromDB
            : lienLevy && lienLevy.witholdingVO
              ? lienLevy.witholdingVO.thirdPartyIDFromDB
              : "",
          thirdPartyType: values.lienHolderType,
          thirdPartyIDType: values.lienHolderIdType
        }),
        lob: AppConstants.DEFAULT_LOB_VALUE,
        activityTypeCode:
          lienLevy && lienLevy.payeeDetails
            ? lienLevy.payeeDetails.activityType
            : "",
        originalAmount: values.originalARAmount,
        balanceAmount:
          lienLevy && lienLevy.witholdingVO
            ? lienLevy.witholdingVO.balanceAmount
            : "",
        establishedDate: estDateFormatted,
        establishedDateStr: estDateFormatted,
        accountReceivableReasonCode:
          lienLevy && lienLevy.payeeDetails
            ? lienLevy.payeeDetails.reasonCode
            : "",
        memberID: null,
        memberIDTypeCode: null,
        approvalIndicator: values.approval === "Y" ? 1 : 0,
        lastAppliedDate:
          lienLevy && lienLevy.grossLevelDetails
            ? lienLevy.grossLevelDetails.lastDateApplied
            : null,
        advancePaymentReceivableVO: {
          manualCheckNumber: "0"
        },
        manualCheckDateStr: null,
        holdLetterIndicator: false,
        receivablePurgeIndicator: false,
        calculatedInterest:
          lienLevy && lienLevy.grossLevelDetails
            ? lienLevy.grossLevelDetails.calculatedInterest
            : 0.0,
        calculatedPenalty:
          lienLevy && lienLevy.grossLevelDetails
            ? lienLevy.grossLevelDetails.calculatedPenalty
            : 0.0,
        calculatedFees:
          lienLevy && lienLevy.grossLevelDetails
            ? lienLevy.grossLevelDetails.calculatedFees
            : 0.0,
        agingCode:
          lienLevy && lienLevy.grossLevelDetails
            ? lienLevy.grossLevelDetails?.arLetterSent?.split('-')[0]
            : null,
        withHoldingStatus: lienLevy.witholdingVO.withHoldingStatus,
        recoupmentVO: {
          recoupmentTypeCode: values.recoupmentTypeCode
            ? values.recoupmentTypeCode === DEFAULT_DD_VALUE
              ? null
              : values.recoupmentTypeCode
            : null,
          installmentAmount: values.installmentValue
            ? values.installmentValue.toString().replace(/,/g, "")
            : values.installmentValue,
          percentageOriginalAmount: values.percentValue,
          startDateStr: selectedRecoupmentDate
            ? moment(new Date(selectedRecoupmentDate).getTime()).format(
              "MM/DD/YYYY"
            )
            : null,
          endDate: null,
          frequency: values.frequency
            ? values.frequency === DEFAULT_DD_VALUE
              ? null
              : values.frequency
            : null,
          releaseDateStr: null,
          returnDateStr: null
        },
        accountVO: {
          scoaFiscalYear: values.fiscalYear,
          cos: values.categoryOfService
            ? values.categoryOfService === DEFAULT_DD_VALUE
              ? null
              : values.categoryOfService
            : null,
          fundCode: values.collocationCode
            ? values.collocationCode === DEFAULT_DD_VALUE
              ? null
              : values.collocationCode
            : null
        },
        exemptionsVO: {
          interestExemptionsInd: values.exemptFromInterest === "Y" ? 1 : 0,
          penaltyExemptionsInd: values.exemptFromPenalty === "Y" ? 1 : 0,
          collectionAgencyInd: values.exemptFromCollectionAgency === "Y" ? 1 : 0
        },
        grossLevelReceivableVO: {
          appealIndicator: values.underAppeal === "Y" ? 1 : 0,
          beginDateStr: selectedBeginDate
            ? moment(new Date(selectedBeginDate).getTime()).format("MM/DD/YYYY")
            : null,
          endDateStr: selectedEndDate
            ? moment(new Date(selectedEndDate).getTime()).format("MM/DD/YYYY")
            : null
        },
        schedulePaymentList: paymentScheduleTemp,
        relatedFTTCN:
          lienLevy && lienLevy.grossLevelDetails
            ? lienLevy.grossLevelDetails.relatedFTTCN
            : null,
        voidIndicator: 0,
        adjustmentVO: {
          adjustmentActivityType: null,
          interest: null,
          penalty: null,
          fee: null,
          adjustmentReasonCode: null,
          establishedDate: null,
          balanceAmount: null,
          originalAmount: null
        },
        transferVO: {
          payeePayerID: null,
          payeePayerIDTypeCode: null,
          payeePayerTypeCode: null,
          balanceAmount: null,
          establishedDate: null,
          payeePayerIdFromDB: null,
          transferReasonCode: null,
          agingCode: null
        },
        noteSetVO: notesInput,
        paymentScheduleType:
          values.paymentScheduleType === DEFAULT_DD_VALUE
            ? null
            : values.paymentScheduleType,
        recoupmentStartDate: selectedRecoupmentStartDate
          ? moment(selectedRecoupmentStartDate).format("MM/DD/YYYY")
          : null,
        numberofInstallments: values.numberofInstallments
          ? values.numberofInstallments
          : null,
        interestCalculation: values.interestCalculation,
        interestPercentage:
          values.interestPercentage !== ""
            ? numeral(values.interestPercentage).format("0.00")
            : null,
        paymentScheduleFrequency:
          values.paymentScheduleFrequency !== DEFAULT_DD_VALUE
            ? values.paymentScheduleFrequency
            : null
      });
      const attachmentVOList = [];
      if (attachmentsTableData && attachmentsTableData.length > 0) {
        attachmentsTableData.map(value => {
          const attachmentVO = {
            auditUserID: logInUserID,
            auditTimeStamp: getUTCTimeStamp(),
            addedAuditUserID: logInUserID,
            addedAuditTimeStamp: getUTCTimeStamp(),
            versionNo: 0,
            dbRecord: true,
            sortColumn: null,
            attachmentSK: null,
            attachmentPageID: null,
            finalPath: null,
            detachInd: false,
            historyIndicator: false,
            cascadeKey: null,
            attachmentIndicator: null,
            dateAdded: getDateInMMDDYYYYFormatWithApendZero(
              new Date(
                new Date().toLocaleString("en-US", {
                  timeZone: process.env.REACT_APP_TIMEZONE
                })
              )
            ),
            fileUrl: null,
            showDeleteForSearched: false,
            rowIndex: 0,
            addedBy: logInUserID,
            description: value && value ? value.description : null,
            edmsStoredDate: null,
            anEDMSPageId: null,
            fileName: attachmentsTableData && value ? value.fileName : null,
            file1: null,
            edmsWrkUnitLevel: null,
            edmsDocType: null
          };
          attachmentVOList.push(attachmentVO);
        });
      }
      value.attachmentVO = null;
      value.attachmentsList = attachmentVOList;
      value.payeeStatus = values.payeeStatus !== DEFAULT_DD_VALUE
        ? values.payeeStatus
        : null;
      onLienLevyAdd(value);
    }
  };

  const editRow = data => () => {
    seterrorMessages([]);
    setAdd(false);
    setEditData(data);
    setRetainEdit(data);
    setOpen(true);
    setSelectedDueDate(data.dueDate);
    setPaymentScheduleVO(data);
    setRetainPaymentSchedule(data);
    setRetainSelectedDueDate(data.dueDate);
    setShowError(false);
    setShowErrorText(false);
  };

  const rowDeletePaymentSchedule = data => {
    setRowPaymentScheduleData({
      ...rowPaymentScheduleData,
      rowPaymentScheduleData: data
    });
  };

  useEffect(() => {
    if (
      onValidateLienHolderResponse &&
      !onValidateLienHolderResponse.systemFailure &&
      onValidateLienHolderResponse.respcode &&
      onValidateLienHolderResponse.respcode === "01" &&
      onValidateLienHolderResponse.enterpriseBaseVO
    ) {
      const onValidateResult = onValidateLienHolderResponse.enterpriseBaseVO;
      if (onValidateResult) {
        if (
          onValidateResult &&
          onValidateResult.multiplePayeeDetails &&
          onValidateResult.multiplePayeeDetails.length > 1
        ) {
          setOpenSysPayeeTable(true);
          setSystemPayeeTableData(onValidateResult.multiplePayeeDetails);
        } else {
          setValues({
            ...values,
            lienHolderName: onValidateResult.thirdPartyHolderName,
            lienHolderId: onValidateResult.thirdPartyID
          });
        }
      }
    } else if (
      onValidateLienHolderResponse &&
      !onValidateLienHolderResponse.systemFailure &&
      onValidateLienHolderResponse.respcode &&
      onValidateLienHolderResponse.respcode === "02"
    ) {
      errorMessagesArray.push(onValidateLienHolderResponse.respdesc);
      seterrorMessages(errorMessagesArray);
      setValues({
        ...values,
        lienHolderName: ""
      });
    } else if (
      onValidateLienHolderResponse &&
      onValidateLienHolderResponse.systemFailure
    ) {
      errorMessagesArray.push(ErrorConstants.GENERIC_SYSTEM_ERROR);
      seterrorMessages(errorMessagesArray);
      setValues({
        ...values,
        lienHolderName: ""
      });
    }
  }, [onValidateLienHolderResponse]);

  const validateLienHolderSystemDetails = () => {
    seterrorMessages([]);
    if (
      values.lienHolderType === "" ||
      values.lienHolderType === DEFAULT_DD_VALUE ||
      values.lienHolderType === undefined
    ) {
      showLienHolderTypeErrorTemp = true;
      showLienHolderTypeErrorTextTemp =
        ErrorConstants.LIEN_HOLDER_TYPE_REQUIRED;
      errorMessagesArray.push(showLienHolderTypeErrorTextTemp);
    }
    if (
      values.lienHolderIdType === "" ||
      values.lienHolderIdType === DEFAULT_DD_VALUE ||
      values.lienHolderIdType === undefined
    ) {
      showLienHolderTypeCodeErrorTemp = true;
      showLienHolderTypeCodeErrorTextTemp =
        ErrorConstants.LIEN_HOLDER_TYPE_CODE_REQUIRED;
      errorMessagesArray.push(showLienHolderTypeCodeErrorTextTemp);
    }
    if (values.lienHolderId === "" || values.lienHolderId === undefined) {
      showLienHolderIDErrorTemp = true;
      showLienHolderIDTextTemp = ErrorConstants.LIEN_HOLDER_ID_REQUIRED;
      errorMessagesArray.push(showLienHolderIDTextTemp);
    }

    setShowError({
      showLienHolderTypeError: showLienHolderTypeErrorTemp,
      showLienHolderTypeCodeError: showLienHolderTypeCodeErrorTemp,
      showLienHolderIDError: showLienHolderIDErrorTemp
    });

    setShowErrorText({
      showLienHolderTypeErrorText: showLienHolderTypeErrorTextTemp,
      showLienHolderTypeCodeErrorText: showLienHolderTypeCodeErrorTextTemp,
      showLienHolderIDText: showLienHolderIDTextTemp
    });
    const validateRequest = {
      thirdPartyIDType: values.lienHolderIdType,
      thirdPartyID: values.lienHolderId,
      thirdPartyType: values.lienHolderType,
      lob: AppConstants.DEFAULT_LOB_VALUE
    };
    seterrorMessages(errorMessagesArray);
    if (errorMessagesArray.length === 0) {
      onValidateLienHolderDetails(
        commonMethods.removeLeadingTrailingSpaces(validateRequest)
      );
    }
  };
  // Payee Popup Implementation Starts

  const handleCloseSysPayeeTable = () => {
    setOpenSysPayeeTable(false);
    setCancel(true);
    setValues({
      ...values,
      lienHolderId: ""
    });
  };
  useEffect(() => {
    if (inputRefCursor.current && canCel) {
      inputRefCursor.current.focus();
    }
  }, [canCel]);

  const onRadioButtonClick = row => {
    setCurrentSysID(row);
  };
  const onContinue = () => {
    if (currentSysID) {
      setValues({
        ...values,
        lienHolderName: currentSysID.payeeName,
        thirdPartyIDFromDB: currentSysID.sysID
      });
      setCurrentSysID(null);
      setOpenSysPayeeTable(false);
    }
  };
  // Payee popup Implementation ends

  const valuesFromARAdd = props.history && props.history.location && props.history.location.state && props.history.location.state.values;

  const cancelMinorSection = () => {
    setAllowNavigation(false);
    setDialogOpen(false);
    setFocusCheck(false);
    props.history.push({
      pathname: '/GrossLevelAddPayeeComponent',
      state: { valuesFromARAdd: valuesFromARAdd },
      addRender: true,
      focusCheck: true
    });
  };

  const majorCancel = () => {
    setAllowNavigation(false);
    seterrorMessages([]);
    setTimeout(() => {
      if (focusCheck) {
        setDialogOpen(true);
      } else {
        setDialogOpen(false);
        props.history.push({
          pathname: '/GrossLevelAddPayeeComponent',
          state: { valuesFromARAdd: valuesFromARAdd },
          addRender: true,
          focusCheck: true
        });
      }
    }, 100);
  };
  const inputRefCursor = useRef(null);
  return (
    <div>
      <Prompt
        when={allowNavigation}
        message={location =>
          `Are you sure you want to go to ${location.pathname}`
        }
      />

      <div className="tabs-container" ref={toPrintRef}>
        <ErrorMessages errorMessages={errorMessages} />
        {errorMessages.length === 0 ? (
          <SuccessMessages successMessages={systemSuccessMessages} />
        ) : null}
        <div className="tab-header">
          <h1 className="tab-heading float-left">Add Withholding/Lien/Levy</h1>
          <div className="float-right mt-2">
            <Button
              className="btn btn-success ml-1"
              data-testid="test_savebtn"
              onClick={() => saveLienLevyAdd()}
            >
              {" "}
              <i className="fa fa-check" aria-hidden="true"></i>
              Save
            </Button>
            <Button title="Cancel" onClick={majorCancel}>Cancel</Button>
          </div>
          <div className="clearfix"></div>
        </div>

        <div className="tab-body">
          <form autoComplete="off">
            <div className="form-wrapper">
              <NativeDropDown
                id="standard-payee-type"
                label="Payee Type"
                value={values.payeeType}
                onChange={handleChange("payeeType")}
                inputProps={{ maxlength: 3, "data-testid": "test_payee_type" }}
                disabled={true}
                dropdownList={payeeTypeData.map((option, index) => (
                  <option key={index} value={option.code}>
                    {option.description}
                  </option>
                ))}
              />
              <NativeDropDown
                id="standard-payeeid-type-code"
                label="Payee ID Type Code"
                value={values.payeeIdTypeCode}
                onChange={handleChange("payeeIdTypeCode")}
                inputProps={{ maxlength: 3 }}
                disabled={true}
                dropdownList={payeeTypeIdCodeData.map((option, index) => (
                  <option key={index} value={option.code}>
                    {option.description}
                  </option>
                ))}
              />
              <div className="mui-custom-form input-md">
                <TextField
                  id="standard-payee-id"
                  fullWidth
                  label="Payee ID"
                  disabled={true}
                  value={values.payeeId}
                  onChange={handleChange("payeeId")}
                  inputProps={{ maxlength: 15 }}
                  placeholder=""
                  InputLabelProps={{
                    shrink: true
                  }}
                ></TextField>
              </div>
              <div className="mui-custom-form  input-md">
                <TextField
                  disabled={true}
                  fullWidth
                  id="standard-payee-name"
                  label="Payee Name "
                  placeholder=""
                  inputProps={{ maxlength: 60 }}
                  InputLabelProps={{
                    shrink: true
                  }}
                  value={values.payeePayerName}
                ></TextField>
              </div>
            </div>
            <div className="form-wrapper">
              <div className="mui-custom-form  input-md has-link">
                <TextField
                  disabeld={true}
                  fullWidth
                  id="standard-system-payee-id"
                  label="System Payee ID"
                  placeholder=""
                  inputProps={{ maxlength: 10 }}
                  InputLabelProps={{
                    shrink: true
                  }}
                  disabled={true}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Link to={redirectSystemID(values.systemPayeeId)} target='_blank'>
                          {values.systemPayeeId}
                        </Link>
                      </InputAdornment>
                    )
                  }}
                ></TextField>
              </div>
              <NativeDropDown
                disabled={true}
                id="standard-activity-id"
                label="Activity Type"
                value={values.activityCode}
                onChange={handleChange("activityCode")}
                inputProps={{ maxlength: 3 }}
                dropdownList={activityTypeData.map((option, index) => (
                  <option key={index} value={option.code}>
                    {option.description}
                  </option>
                ))}
              />
              <NativeDropDown
                disabled={true}
                id="standard-reason-code"
                label="Reason Code"
                value={values.reasonCode}
                onChange={handleChange("reasonCode")}
                inputProps={{ maxlength: 3 }}
                title={getLongDescription(reasonCodeData, values.reasonCode)}
                dropdownList={reasonCodeData
                  ? reasonCodeData.map((option, index) => (
                    <option key={index} value={option.code}>
                      {option.description}
                    </option>
                  ))
                  : null}
              />
            </div>
          </form>
          <div className="tab-panelbody">
            <div className="tab-holder mb-3 mt-2">
              <AppBar position="static">
                <Tabs
                  value={value}
                  onChange={handleChangeTabs}
                  aria-label="simple tabs example"
                >
                  <Tab
                    label="Withholding/Lien/Levy Detail"
                    className={"original-tab" + color}
                    data-testid="test_lien_levy_detail_tab"
                  />
                  <Tab label="Payment Schedule" className={" ml-4"} data-testid="test_payment_schedule_tab" />
                  <Tab label="History" className={" ml-3"} data-testid="test_history_tab"/>
                  <Tab label="Notes" data-testid="test_notes_tab"/>
                  {<Tab label="Attachments" data-testid="test_attachments_tab"/>}
                </Tabs>
              </AppBar>
              <TabPanel value={value} index={0}>
                {value === 0 && (
                  <div className="tab-holder my-3 p-0">
                    <LienLevyDetailsComponent
                      payeePaymentStatusFrmState={payeePaymentStatusFrmState}
                      values={values}
                      dropDownCheckList={dropDownCheckList || []}
                      lienHolderTypeData={lienHolderTypeData}
                      lienHolderIdTypeData={lienHolderIdTypeData}
                      handleChange={handleChange}
                      handleChangeForDecimalValues={handleChangeForDecimalValues}
                      errors={[
                        showOriginalArAmmountError,
                        showFiscalYearError,
                        showCollocationCodeError,
                        showRecoupmentTypeCodeError,
                        showInvalidBeginDateError,
                        showInvalidEndDateError,
                        showEndDateLesserError,
                        showBeginDateRequiredError,
                        showEndDateRequiredError,
                        showPercentError,
                        showInstallementError,
                        showStartDateRequiredError,
                        showFrequencyError,
                        showLienHolderTypeError,
                        showLienHolderTypeCodeError,
                        showLienHolderIDError,
                        showATRError
                      ]}
                      errorText={[
                        showOriginalArAmountErrorText,
                        showFiscalYearErrorText,
                        showCollocationCodeErrorText,
                        showRecoupmentTypeCodeErrorText,
                        showInvalidBeginDateText,
                        showInvalidEndDateInvalidText,
                        showEndDateLesserErrorText,
                        showBeginDateRequiredErrorText,
                        showEndDateRequiredErrorText,
                        showPercentErrorText,
                        showInstallmentErrorText,
                        showStartDateRequiredErrorText,
                        showFrequencyErrorText,
                        showLienHolderTypeErrorText,
                        showLienHolderTypeCodeErrorText,
                        showLienHolderIDText,
                        showATRErrorText
                      ]}
                      selectedBeginDate={selectedBeginDate}
                      handleBeginDateChange={handleBeginDateChange}
                      selectedEndDate={selectedEndDate}
                      selectedRecoupmentDate={selectedRecoupmentDate}
                      handleRecoupmentDateChange={handleRecoupmentDateChange}
                      handleEndDateChange={handleEndDateChange}
                      defaultRecoupment={handleDefaultRecoupment}
                      selectedInitialPaymentDate={selectedInitialPaymentDate}
                      selectedSatisfiedDate={selectedSatisfiedDate}
                      handleInitialPaymentDate={handleInitialPaymentDate}
                      formatDecimalField={formatDecimalField}
                      formatPercent={formatPercent}
                      removeFormatDecimalFieldWHAmount={
                        removeFormatDecimalFieldWHAmount
                      }
                      removeFormatDecimalField={removeFormatDecimalField}
                      removeFormatDecimalFieldInstallment={
                        removeFormatDecimalFieldInstallment
                      }
                      handleSatisfiedDate={handleSatisfiedDate}
                      validateLienHolderSystemDetails={
                        validateLienHolderSystemDetails
                      }
                      frequencyDataDropdown={frequencyDataDropdown}
                      inputRefCursor={inputRefCursor}
                    ></LienLevyDetailsComponent>
                  </div>
                )}
              </TabPanel>

              <TabPanel value={value} index={1}>
                {value === 1 && (
                  <>
                    <div className="tab-holder">
                      <form autoComplete="off">
                        <div className="form-wrapper">
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <NativeDropDown
                              className='min-md'
                              id="standard-GrossDetailsCategoryofService"
                              label="Payment Schedule"
                              value={values.paymentScheduleType}
                              onChange={handleChange("paymentScheduleType")}
                              inputProps={{ maxlength: 3 }}
                              disabled={
                                !userInquiryPrivileges
                                  ? !userInquiryPrivileges
                                  : values.recoupmentTypeCode !== "S"
                              }
                              isRequired={values.recoupmentTypeCode === "S"}
                              errTxt={
                                paymentScheduleTypeError !== ""
                                  ? paymentScheduleTypeError
                                  : null
                              }
                              dropdownList={paymentScheduleTypeData.map(
                                (option, index) => (
                                  <option key={index} value={option.code}>
                                    {option.description}
                                  </option>
                                )
                              )}
                            />
                            <div className="mui-custom-form with-date">
                              <KeyboardDatePicker
                                fullWidth
                                maxDate={Date.parse("31 Dec 9999 00:00:00 GMT")}
                                id="GrossLevelDetailBegindate-picker-dialog"
                                label="Recoupment Start Date"
                                format="MM/dd/yyyy"
                                placeholder="mm/dd/yyyy"
                                minDate={"01/01/1964"}
                                value={selectedRecoupmentStartDate}
                                onChange={handleRecoupmentStartDateChange}
                                InputLabelProps={{
                                  shrink: true
                                }}
                                KeyboardButtonProps={{
                                  "aria-label": "change date"
                                }}
                                required={values.recoupmentTypeCode === "S"}
                                disabled={
                                  !userInquiryPrivileges
                                    ? !userInquiryPrivileges
                                    : values.recoupmentTypeCode !== "S"
                                }
                                helperText={
                                  recoupmentStartDateError !== ""
                                    ? recoupmentStartDateError
                                    : null
                                }
                                error={
                                  recoupmentStartDateError !== ""
                                    ? recoupmentStartDateError
                                    : null
                                }
                              />
                            </div>
                          </MuiPickersUtilsProvider>
                          <div className="mui-custom-form input-md override-m-2">
                            <TextField
                              fullWidth
                              id="standard-GrossDetailsOriginalA/RAmount"
                              label="Number of Installments"
                              placeholder=""
                              inputProps={{ maxlength: 10 }}
                              InputLabelProps={{
                                shrink: true
                              }}
                              value={values.numberofInstallments}
                              onChange={handleChange("numberofInstallments")}
                              disabled={
                                !userInquiryPrivileges
                                  ? !userInquiryPrivileges
                                  : values.recoupmentTypeCode !== "S" ||
                                  values.paymentScheduleType !== "A"
                              }
                              required={values.paymentScheduleType === "A"}
                              helperText={
                                numberofInstallmentError !== ""
                                  ? numberofInstallmentError
                                  : null
                              }
                              error={
                                numberofInstallmentError !== ""
                                  ? numberofInstallmentError
                                  : null
                              }
                              onInput={e => {
                                e.target.value = e.target.value.replace(
                                  /[a-zA-Z!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~ ]/,
                                  ""
                                );
                              }}
                            ></TextField>
                          </div>
                          <NativeDropDown
                            className='min-md override-m-2'
                            id="standard-GrossDetailsCategoryofService"
                            label="Frequency"
                            value={values.paymentScheduleFrequency}
                            onChange={handleChange(
                              "paymentScheduleFrequency"
                            )}
                            inputProps={{ maxlength: 3 }}
                            disabled={
                              !userInquiryPrivileges
                                ? !userInquiryPrivileges
                                : values.recoupmentTypeCode !== "S" ||
                                  values.paymentScheduleType !== "A"
                            }
                            isRequired={values.paymentScheduleType === "A"}
                            errTxt={
                              frequencyError !== "" ? frequencyError : null
                            }
                            dropdownList={frequencyRecoupData.map((option, index) => (
                              <option key={index} value={option.code}>
                                {option.description}
                              </option>
                            ))}
                          />
                        </div>
                        <div className="form-wrapper">
                          <div
                            className="mui-custom-form min-md"
                            style={{ lineHeight: "100%" }}
                          >
                            <label className="MuiFormLabel-root small-label">
                              Interest Calculation
                            </label>
                            <div className="sub-radio no-margin">
                              <Radio
                                value="Y"
                                id="GroosLevelDetailsapprovalIdYes"
                                checked={values.interestCalculation === "Y"}
                                onChange={handleChange("interestCalculation")}
                                disabled={
                                  !userInquiryPrivileges
                                    ? !userInquiryPrivileges
                                    : values.recoupmentTypeCode !== "S" ||
                                    values.paymentScheduleType !== "A"
                                }
                              />
                              <label
                                className="text-black"
                                htmlFor="GroosLevelDetailsapprovalIdYes"
                              >
                                Yes
                              </label>
                              <Radio
                                value="N"
                                id="GroosLevelDetailsapprovalIdNo"
                                checked={values.interestCalculation === "N"}
                                onChange={handleChange("interestCalculation")}
                                className="ml-2"
                                disabled={
                                  !userInquiryPrivileges
                                    ? !userInquiryPrivileges
                                    : values.recoupmentTypeCode !== "S" ||
                                    values.paymentScheduleType !== "A"
                                }
                              />
                              <label
                                className="text-black"
                                htmlFor="GroosLevelDetailsapprovalIdNo"
                              >
                                No
                              </label>
                            </div>
                          </div>
                          <div className="mui-custom-form input-md">
                            <TextField
                              fullWidth
                              id="standard-GrossDetailsOriginalA/RAmount"
                              label="Interest Percentage"
                              placeholder=""
                              inputProps={{ maxlength: 14 }}
                              InputLabelProps={{
                                shrink: true
                              }}
                              InputProps={{
                                endAdornment: <InputAdornment>%</InputAdornment>
                              }}
                              value={values.interestPercentage}
                              onBlur={formatDecimalField("interestPercentage")}
                              onFocus={removeFormatDecimalFieldPercent(
                                "interestPercentage"
                              )}
                              onChange={handleChange("interestPercentage")}
                              onKeyDown={evt =>
                                evt.key === "e" && evt.preventDefault()
                              }
                              disabled={
                                !userInquiryPrivileges
                                  ? !userInquiryPrivileges
                                  : values.recoupmentTypeCode !== "S" ||
                                  values.paymentScheduleType !== "A" ||
                                  values.interestCalculation === "N"
                              }
                              onInput={e => {
                                e.target.value = e.target.value.replace(
                                  /[a-zA-Z!@#$%^&*()_+=[\]{};':"\\|,<>/?~ ]/,
                                  ""
                                );
                              }}
                              required={values.interestCalculation === "Y"}
                              helperText={
                                interestCalError !== ""
                                  ? interestCalError
                                  : null
                              }
                              error={
                                interestCalError !== ""
                                  ? interestCalError
                                  : null
                              }
                            ></TextField>
                          </div>
                        </div>
                      </form>
                      <div className="float-right mt-3">
                        <Button
                          className="btn-text-main-add btn-success"
                          disabled={
                            !userInquiryPrivileges
                              ? !userInquiryPrivileges
                              : values.recoupmentTypeCode !== "S" ||
                              values.paymentScheduleType !== "A"
                          }
                          onClick={calculateSchedule}
                          title={"Calculate"}
                        >
                          Calculate
                        </Button>
                        <Button
                          className="btn-text-main-delete btn-transparent ml-1"
                          onClick={PaymentRowDeleteClick}
                          disabled={
                            values.recoupmentTypeCode !== "S" ||
                            values.paymentScheduleType === "A"
                          }
                          title={'Delete'}
                        >
                          <i className="fa fa-trash" aria-hidden="true"></i>Delete
                        </Button>
                        <Button
                          className="btn-text-main-add btn-success ml-1"
                          onClick={handleClickOpenPaymentSchedule}
                          disabled={
                            values.recoupmentTypeCode !== "S" ||
                            values.paymentScheduleType === "A"
                          }
                          title={"Add Payment Schedule"}
                        >
                          <i className="fa fa-plus" aria-hidden="true"></i> Add
                        </Button>
                      </div>
                      <div className="clearfix"></div>
                    </div>
                    <div className="tab-holder my-3 p-0">
                      {values.paymentScheduleType === "M" ? (
                        <PaymentScheduleTable
                          tableData={paymentScheduleData}
                          onRowClick={editRow}
                          rowDeletePaymentSchedule={rowDeletePaymentSchedule}
                          isSearch={false}
                        />
                      ) : (
                        <PaymentScheduleTable
                          tableData={paymentScheduleData}
                          onRowClick={editRow}
                          rowDeletePaymentSchedule={rowDeletePaymentSchedule}
                          isSearch={true}
                        />
                      )}
                    </div>
                    {open ? (
                      <div className="tabs-container" id="form_pop_up">
                        <div className="tab-header  mini-tab-header">
                          <div className="tab-heading float-left">
                            {add
                              ? "Add Payment Schedule"
                              : "Edit Payment Schedule"}
                          </div>
                          <div className="float-right mt-1">
                            <Button
                              variant="outlined"
                              color="primary"
                              className="btn btn-success ml-1"
                              onClick={savePaymentSchedule}
                              disabled={values.paymentScheduleType === "A"}
                              title={add ? "Add" : "Update"}
                            >
                              <i className="fa fa-check" aria-hidden="true"></i>
                              {add ? "Add" : "Update"}
                            </Button>
                            <Button
                              variant="outlined"
                              color="primary"
                              className="bt-reset btn-transparent ml-1"
                              onClick={resetPaymentSchedule}
                              disabled={values.paymentScheduleType === "A"}
                              title={'Reset'}
                            >
                              <i className="fa fa-undo" aria-hidden="true"></i>
                              Reset
                            </Button>
                            <Button
                              variant="outlined"
                              color="primary"
                              className="btn btn-primary btn btn-outlined ml-1"
                              onClick={handleClose}
                              title={'Cancel'}
                            >
                              Cancel
                            </Button>
                          </div>
                        </div>
                        <div className="form-border my-3">
                          <form autoComplete="off">
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <div className="form-wrapper">
                                <div className="mui-custom-form with-date override-width-20">
                                  <KeyboardDatePicker
                                    maxDate={Date.parse(
                                      "31 Dec 9999 00:00:00 GMT"
                                    )}
                                    required
                                    fullWidth
                                    id="dueDate"
                                    label="Due Date"
                                    minDate={
                                      new Date(
                                        new Date().toLocaleString("en-US", {
                                          timeZone:
                                            process.env.REACT_APP_TIMEZONE
                                        })
                                      )
                                    }
                                    initialFocusedDate={
                                      new Date(
                                        new Date().toLocaleString("en-US", {
                                          timeZone:
                                            process.env.REACT_APP_TIMEZONE
                                        })
                                      )
                                    }
                                    format="MM/dd/yyyy"
                                    placeholder="mm/dd/yyyy"
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                    value={selectedDueDate}
                                    onChange={handleDueDateChange}
                                    KeyboardButtonProps={{
                                      "aria-label": "change date"
                                    }}
                                    disabled={
                                      values.paymentScheduleType === "A"
                                    }
                                    helperText={
                                      showDueDateError
                                        ? showDueDateErrorText
                                        : null || showDueDateGreaterError
                                          ? showDueDateGreaterErrorText
                                          : null || showMinDueDateError
                                            ? showMinDueDateErrorText
                                            : null || showDueDateInvalidError
                                              ? showDueDateInvalidErrorText
                                              : null
                                    }
                                    error={
                                      showDueDateError
                                        ? showDueDateErrorText
                                        : null || showDueDateGreaterError
                                          ? showDueDateGreaterErrorText
                                          : null || showMinDueDateError
                                            ? showMinDueDateErrorText
                                            : null || showDueDateInvalidError
                                              ? showDueDateInvalidErrorText
                                              : null
                                    }
                                  />
                                </div>
                                <div className="mui-custom-form input-md override-width-20">
                                  <TextField
                                    id="amountdue"
                                    fullWidth
                                    label="Installment Amount"
                                    required
                                    inputProps={{ maxlength: 14 }}
                                    value={paymentScheduleVO.installmentAmount}
                                    onChange={handleChangePaymentSchedule(
                                      "installmentAmount"
                                    )}
                                    onBlur={formatDecimalFieldInstallment(
                                      "installmentAmount"
                                    )}
                                    onFocus={removeFormatDecimalFieldAmountDue(
                                      "installmentAmount"
                                    )}
                                    disabled={
                                      values.paymentScheduleType === "A"
                                    }
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          $
                                        </InputAdornment>
                                      )
                                    }}
                                    helperText={
                                      showAmountDueError
                                        ? showAmountDueErrorText
                                        : null || showNegativeAmountError
                                          ? showNegativeAmmountErrorText
                                          : null || showGreaterThanZeroAmountError
                                            ? showGreaterThanZeroAmountText
                                            : null || showOriginalArAmountPopError
                                              ? showOriginalArAmountPopErrorText
                                              : null
                                    }
                                    error={
                                      showAmountDueError
                                        ? showAmountDueErrorText
                                        : null || showNegativeAmountError
                                          ? showNegativeAmmountErrorText
                                          : null || showGreaterThanZeroAmountError
                                            ? showGreaterThanZeroAmountText
                                            : null || showOriginalArAmountPopError
                                              ? showOriginalArAmountPopErrorText
                                              : null
                                    }
                                    onInput={e => {
                                      e.target.value = e.target.value.replace(
                                        /[a-zA-Z!@#$%^&*()_+=[\]{};':"\\|,<>/?~ ]/,
                                        ""
                                      );
                                    }}
                                  ></TextField>
                                </div>
                                <div className="mui-custom-form input-md override-width-20">
                                  <TextField
                                    id="amountdue"
                                    fullWidth
                                    label="Recouped Amount"
                                    required
                                    disabled
                                    inputProps={{ maxlength: 14 }}
                                    value={paymentScheduleVO.recoupedAmount}
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          $
                                        </InputAdornment>
                                      )
                                    }}
                                  ></TextField>
                                </div>
                              </div>
                            </MuiPickersUtilsProvider>
                          </form>
                        </div>
                      </div>
                    ) : null}
                  </>
                )}
              </TabPanel>
              <TabPanel value={value} index={2}>
                {value === 2 && (
                  <>
                    <div className="tab-holder my-3 p-0">
                      <LienLevyHistoryTableComponent values={values} />
                    </div>
                  </>
                )}
              </TabPanel>
              <TabPanel value={value} index={3}>
                {value === 3 && (
                  <div className="tab-holder p-0">
                    <Notes
                      addNotes={addNotes}
                      notesTableData={notesTableData}
                      noteSetListInput={noteSetListInput}
                      setNoteSetListInput={setNoteSetListInput}
                      usageTypeCodeData={usageTypeCodeData}
                      editNoteData={editNoteData}
                      setEditNoteData={setEditNoteData}
                      setSuccessMessages={clearSuccessErrorMsgs}
                      setErrorMessages={seterrorMessages}
                      setFocusCheck={setFocusCheck}
                    />
                  </div>
                )}
              </TabPanel>
              {
                <TabPanel
                  value={value}
                  index={4}
                >
                  <div className="tab-holder p-0">
                    <Attachments
                      userInquiryPrivileges={userInquiryPrivileges}
                      attachmentFile={attachmentFile}
                      setAttachmentFile={setAttachmentFile}
                      attachmentsTableData={attachmentsTableData}
                      setAttachmentsTableData={setAttachmentsTableData}
                      parentErrorMesage={seterrorMessages}
                      setAllowNavigation={setAllowNavigation}
                      setSystemSuccesMessages={clearSuccessErrorMsgs}
                      setFocusCheck={setFocusCheck}
                    />
                  </div>
                </TabPanel>
              }
            </div>
          </div>
        </div>
        <Footer print />
        <Dialog
          open={dialogOpen}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="custom-alert-box"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <>
                Unsaved changes will be lost. <br />
                Are you sure you want to continue?
              </>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setDialogOpen(false);
              }}
              color="primary"
              className="btn btn-transparent"
            >
              STAY ON THIS PAGE!
            </Button>
            <Button
              onClick={cancelMinorSection}
              color="primary"
              className="btn btn-success"
              autoFocus
            >
              CONTINUE <i className="fa fa-arrow-right ml-1" />
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      {openSysPayeeTable && <SystemPayeeTable
        payeeType={payeeTypeDesc}
        payeeIdTypeCode={payeeTypeIdCodeDesc}
        payeeId={values.payeeId}
        openSysPayeeTable={openSysPayeeTable}
        handleCloseSysPayeeTable={handleCloseSysPayeeTable}
        uniqueKey={"sysID"}
        onTableCellButtonClick={onRadioButtonClick}
        tableData={systemPayeeTableData}
        currentSysID={currentSysID}
        onContinue={onContinue}
      />}
    </div>
  );
}
